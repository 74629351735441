<template>
  <div v-if="can_show">



    <!-- pro_dados_servico: {{pro_dados_servico.DESCRICAO}} <br/><br/> -->

    <!-- obj_recibo_unico : {{obj_recibo_unico}}  <br/> <br/> -->
    <!-- selecionou_varios : {{selecionou_varios}}  <br/> <br/> -->
    <!-- obj_clicado :{{ obj_clicado }} <br/> -->
    <!-- selected :{{ selected }} <br/> -->

    <!-- config_pix:{{ config_pix }} <br/><br/> -->

    <!-- config_emp:{{config_emp}} <br/><br/> -->

    <!-- souDSi:{{ souDSi }} <br/> <br/> -->
    <!-- podeFaturar:{{ podeFaturar }} <br/> <br/> -->

    <!-- item_menu: {{ item_menu }} <br/><br/> -->
    <!-- pro_dados_aluno:{{pro_dados_aluno}} <br/> <br/> -->
    <!-- pro_dados_servico:{{pro_dados_servico}} <br/> //aqui vem os dados de somatorias das parcelas tb -->

    <!-- {{items_tabela_formas}} -->
    <!-- {{edita_gerar_parcelas}} -->
    
    <!-- {{edita_parcelas}} -->

    <!-- <br/><br/>items_parcelas: {{items_parcelas}} -->
    <!-- {{ edita_copia }}
    <br>
    <br>-->
    <!-- <br/><br/> {{ obj_valores }}  -->

    <!-- {{edita_parcelas}} -->
    <!-- {{obj_user}} -->
    
    <!-- <BR/> -->

    <!-- {{edita_parcelas}} -->
    <!-- {{ edita_gerar_parcelas }} -->
    <!-- item_menu: {{ item_menu }}  -->

<!-- <print_recibo_Vue
  v-if="print_recibo"
  :pro_dados_aluno   = 'pro_dados_aluno'
  :pro_dados_parcela = 'item_menu'
  :abrir = 'print_recibo'
  >
</print_recibo_Vue> -->
 







<v-dialog
    v-model="dialog_opcoes"
    max-width="699px"
    scrollable
>
  <v-card
    class="mx-auto"
  >
    <v-card-title class=" grey lighten-5">
      <h5>Definições</h5>

      <v-spacer></v-spacer>
      
      <v-icon
        color="red"
        @click="dialog_opcoes=!dialog_opcoes"
      >
        mdi-close
      </v-icon>

    </v-card-title>

    <v-card-text>
      <v-container class="mx-0 px-0 mt-2">
        <v-row>
          
          <v-col
            cols="12"
            md="10"
            class="ml-2"
          >      
            <v-text-field
              dense
              v-model="def.motivo_processo"
              label="Motivo do Processo"
              hide-details
              type="text"
              
            ></v-text-field>
          </v-col> 
          

          <v-col
            cols="12"
            md="1"
            class="ml-2"
          >      
          <v-btn color="primary"
          @click="dialog_opcoes=!dialog_opcoes"
          >
            ok
          </v-btn>
          </v-col> 
          

          
          <v-spacer></v-spacer>


        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</v-dialog>


<v-row justify="center">
    <!-- IMPRIMIR RECIBO -->
    <v-dialog
      v-model="dialog_print"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <!-- PAINEL TOPO AZUL BOTOES -->
      <v-card
       elevation="0"
      >
        <v-toolbar
          color="primary"
          elevation="0"
          class="no-print"
        >


            <v-spacer></v-spacer>


            <v-btn
              @click="imprimir(item_menu)"
              color="primary text--white"
            >
              <v-icon color="white"> mdi-printer </v-icon>
              &nbsp imprimir
            </v-btn>


            <v-divider class="mx-2" vertical inset></v-divider>


            <v-btn
                
                color="primary"
                @click="close"
            >
                
                <v-icon>mdi-close</v-icon>
                &nbsp 
                Fechar
                
            </v-btn>

        </v-toolbar>


        <v-container fluid class="pa-2">
            <v-row dense>

                <!-- COLUNA ESQUERDA -->
                <v-card
                 class="no-print mt-1"
                 width="5%"
                 elevation="0"
                 height="100"
                >
                    <v-card class="mt-3 ml-2 grey lighten-4" v-if="0>1">
                        <v-row>
                            <v-col cols="3 offset-md-1">
 
                            </v-col>
                        </v-row>
                    </v-card>
                </v-card>   


                <!-- COLUNA CENTRAL -->
                <v-card
                 class="sem_borda"
                >
                <!-- COLUNA CENTRAL -->
                    <!-- INICIO -->
                    <v-card
                    height="6.6cm"
                    width="18.7cm"
                    class="grey lighten-4 ml-1 mb-3"
                    v-for="(item, i ) in objVias"
                    :key="i"
                    >
                    <!-- {{item}}  -->
                    <v-container fluid class="pa-2">
                        <v-row dense>

                            <v-card
                            width="13.6cm"
                            height="3.5cm"
                            class=" pt-0"
                            >
                                <center>
                                    <img
                                    ref="print_logo"
                                    :src="urlLogo"
                                    PATH_LOGO
                                    style="max-width: auto; height: 2.5cm"
                                    />
                                    <!-- src="http://localhost\vue_cfc\public\shalon.png" -->
                                </center>
                                <div 
                                class="ml-1"
                                style="margin-top: -0.3cm"
                                >
                                     <p class="inline h7">CNPJ: {{ emp.cnpj }}</p>
                                    &nbsp
                                    
                                    <p class="inline h7">FONES: {{ emp.fone1 }} | {{ emp.celular }} </p>
                                    &nbsp
                                    <p class="inline h7"> {{ emp.email }} </p>
                                </div>
                                
                                <div 
                                class="ml-1"
                                style="margin-top: -0.2cm"
                                >
                                    <p class="inline h7" > {{ emp.endereco }}</p>
                                </div>
                            </v-card>

                            <v-card
                            width="4.7cm"
                            height="3.5cm"
                            class=" px-2 ml-1"
                            elevation="2"
                            >

                            <h3 class="inline">RECIBO</h3> 
                            
                            <h6 class="inline" >
                                <!-- {{Object.keys(obj_recibo_unico).length}} -->
                                &nbsp {{  (Object.keys(obj_recibo_unico).length === 0) ? item_menu.CODIGO : 'ÚNICO'  }}
                            </h6> 

                            <div style="display: flex; justify-content: flex-end; margin-top:-25px; margin-bottom:2px">
                                <h6 class="inline">{{ i + 1}}ª Via</h6> 
                            </div>

                            <!-- <h6 class="none">SEQ: 29245</h6> -->

                            <h2 class="ml-1"> R$ 
                              {{  ((Object.keys(obj_recibo_unico).length === 0)) ? item_menu.double_TOTAL : obj_recibo_unico.TOTAL}} 
                            </h2>

                            <h5 class="none">Recebido em </h5>
                            <h4 class="inline ml-1">
                                 {{ ((Object.keys(obj_recibo_unico).length === 0)) ? getFormatDate(item_menu.DATA_EFETUADO) : obj_recibo_unico.DATA}} 
                            </h4>
                            
                            <!-- <h6 class="inline none">Venc. </h6>
                            <h5 class="inline"> 20/04/2022 </h5> -->
                            
                            </v-card>

                            <v-card
                            height="1cm"
                            width="15.4cm"
                            class=" mt-1 pa-1"
                            >
                            <!-- <p class="inline cap">Recebemos de  </p> -->
                            <h6 class="inline none">Recebemos de: </h6>
                            <h4 class="inline"> {{ pro_dados_aluno.NOME }} </h4>
                            <p class="inline cap"> &nbsp CPF:  </p>
                            <h5 class="inline"> {{ pro_dados_aluno.CPF }} </h5>
                            </v-card>

                            <v-card
                            height="1cm"
                            width="3cm"
                            class=" mt-1 pa-1"
                            >
                            <h6 class="none">PRO: {{ pro_dados_servico.PROCESSO }} </h6>
                            <h6 class="none">IDE: {{ pro_dados_aluno.CODIGO_ALUNO }}</h6>
                            </v-card>

                            <v-card
                            class=" mt-1 pa-1"
                            height="1.7cm"
                            width="12.1cm"
                            >
                            <h6 class=" none">Referente à: </h6>
                                <h6 class="inline none" v-if="(Object.keys(obj_recibo_unico).length === 0)">
                                    {{ item_menu.PLANO }}
                                    - V:{{ getFormatDate(item_menu.DATA_VENCIMENTO) }} 
                                    - R$ {{ (item_menu.double_TOTAL) }} 
                                    - {{ item_menu.FORMA_PAGAMENTO }}
                                    - {{ item_menu.SERVICO_REFERENTE }} 
                                </h6>
                                <h6 v-else class="none">
                                    <!-- recibo unico -->
                                    {{obj_recibo_unico.REFERENTE}}
                                </h6>

                            </v-card>

                            <v-card
                            height="1.7cm"
                            width="6.3cm"
                            class=" mt-1 pa-1"
                            >
                            <center>
                                <h6 class="none pt-2">____________________________________________ </h6>
                                <h6 class="none"> 
                                    {{  ((Object.keys(obj_recibo_unico).length === 0)) ? item_menu.QUEM_EFETUOU : obj_recibo_unico.QUEM_EFETUOU}} 
                                </h6>

                                <h6 class="none pt-1"> {{ emp.cidade }}, {{ hoje }}</h6>
                            </center>
                            </v-card>

                        </v-row>
                    </v-container>
                    </v-card>
                </v-card>   

                <v-spacer></v-spacer>
                <!-- COLUNA DIREITA -->
                <v-card
                 class="no-print mt-1"
                 width="300"
                 elevation="0"
                 height="100"
                >

                    <v-card class="mt-3 ml-2 grey lighten-4">
                        <v-row>
                            <v-col cols="4 offset-md-1">
                                <!-- <v-card> -->
                                    <!-- <v-text-field
                                    v-model="nVias"
                                    label="Nº de Vias"
                                    type="number"
                                    @input="mudaVias(nVias)"
                                    ></v-text-field> -->

                                    <v-select
                                    v-model="nVias"
                                    :items="['1','2']"
                                    label="Nº de Vias"
                                    @input="mudaVias(nVias)"
                                    ></v-select>
                                <!-- </v-card> -->
                            </v-col>
                        </v-row>
                    </v-card>
                </v-card> 

            </v-row>
        </v-container>
      </v-card>
    </v-dialog>
</v-row>



<v-dialog
    v-model="dialog_menu"
    max-width="300px"
    scrollable
    class="no-print"
>
    <v-card class="mx-auto" v-if="(!selecionou_varios)">
        <v-list>

        <v-subheader class="grey lighten-5">
            <h3> {{ item_menu.CODIGO }} - R$ {{ item_menu.double_TOTAL }}</h3>
        </v-subheader>



        <v-divider
            class="mx-0"
        ></v-divider>

        <!-- IMPRIMIR RECIBO -->
        <v-list-item
        link
        @click="imprimir_recibo(item_menu) ,dialog_menu=false "
        v-if="(item_menu.PAGO=='S')&&(pode_gerar()) &&(item_menu.TIPO=='E')"
        >
            <v-list-item-icon>
            <v-icon color="primary"> mdi-printer </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Recibo</v-list-item-title>
        </v-list-item>


        <v-divider
            class="mx-0"
        ></v-divider>



        <v-list-item
        link
        @click="editar_tabela(item_menu, 'edita_parcelas', 'dialog_alterar_parcela'), dialog_menu=false"
        v-if="((item_menu.PAGO=='N') &&(pode_alterar()))"
        >
        
            <v-list-item-icon>
            <v-icon color="primary">mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Alterar</v-list-item-title>
        </v-list-item>


        <v-divider
            class="mx-0"
        ></v-divider>

        <v-list-item
        link
        @click="atualiza_juros(item_menu, true, true), dialog_menu=false"
        v-if="(0>1)"
        >
        <!-- v-if="((showJuros)&&(item_menu.PAGO=='N')&&(item_menu.TIPO=='E')&&(pode_alterar()))" -->
        <!-- v-if="item_menu.PAGO=='N'" -->
            <v-list-item-icon>
            <v-icon color="primary"> mdi-percent  </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Calcular Juros</v-list-item-title>
        </v-list-item>


        <v-divider
            class="mx-0"
        ></v-divider>

        <v-list-item
        link
        @click="pre_gerar_pix(item_menu, true), dialog_menu=false"
        v-if="item_menu.PAGO !== 'S' && podeFaturar &&(item_menu.TIPO=='E') && (!item_menu.PIX_ID)"
        >
            <v-list-item-icon>
            <v-icon color="black">
                mdi-barcode
                <!-- mdi-qrcode-scan  -->
            </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Gerar Fatura</v-list-item-title>
        </v-list-item>

        <v-divider
            class="mx-0"
        ></v-divider>

        <v-list-item
        link
        @click="ver_cobranca_pix(item_menu), dialog_menu=false"
        v-if="item_menu.PIX_ID"
        >
            <v-list-item-icon>
            <v-icon color="primary">mdi-checkbook  </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Ver Fatura</v-list-item-title>
        </v-list-item>


        <v-divider
            class="mx-0"
        ></v-divider>

        <v-list-item
        link
        @click="estornar(item_menu) ,dialog_menu=false "
        v-if="((item_menu.PAGO=='S') && (pode_estornar()))"
        :disabled="(item_menu.VISTO=='S')"
        >
            <v-list-item-icon>
            <v-icon color="red">mdi-currency-usd-off</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Estornar</v-list-item-title>
        </v-list-item>


        <!-- BOTAO EXCLUIR PARCELAS -->
        <v-divider
            class="mx-0"
        ></v-divider>

        <v-list-item
        link
        @click="remover_tabela('conta',item_menu, ''),dialog_menu=false"
        v-if="((item_menu.PAGO=='N')&&(pode_excluir()))"
        >
            <v-list-item-icon>
            <v-icon color="red">
                mdi-delete
            </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Excluir</v-list-item-title>
        </v-list-item>
        
        
        <!-- BOTAO EXCLUIR PARCELAS -->
        <v-divider
            class="mx-0"
        ></v-divider>

        <v-list-item
        link
        @click="excluir_todas_parcelas(item_menu) ,dialog_menu=false "
        v-if="(pode_excluir())"
        :disabled="(item_menu.VISTO=='S')"
        >
            <v-list-item-icon>
            <v-icon color="red">mdi-delete-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Excluir Todas Parcelas</v-list-item-title>
        </v-list-item>



        </v-list>
    </v-card>

    
    <v-card v-if="(selecionou_varios)">
        <v-list dense>
            <span v-for="(item, index) in menu_parcelas" :key="item.title+index" v-if="(item.type=='group')&&(vif_here(item.title))">

            <v-list-group
                v-model="(!selecionou_varios)  ? false : item.active"
                no-action
                @click.stop="" 
            >

            
            <!-- v-model="(item.title=='ÍTENS SELECIONADOS') ? fuba : item.active" -->
            <!-- {{['grupo'+'_'+index]}} -->

                <template v-slot:activator>

                <v-list-item-icon class="mr-1">
                    <v-icon :color="item.icon_color" small> {{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content >
                    <!-- <v-list-item-title v-text="item.title"></v-list-item-title> -->
                    <v-list-item-title>
                        <span v-if="item.title=='ESTE'">

                        <div class="white lighten-5 mx-0 mb-1">
                            <h4 v-if="item_menu.X_NOME_CLIENTE"> {{ item_menu.X_NOME_CLIENTE }} </h4>
                            <h4 v-if="item_menu.X_NOME_ALUNO"> {{ item_menu.X_NOME_ALUNO }} </h4>
                            <h4 v-if="(item_menu.CATEGORIA)&&(item_menu.TIPO == 'S')"> {{ item_menu.CATEGORIA }} </h4>
                            <h5> ({{ item_menu.CODIGO }}) - R$ {{ item_menu.double_TOTAL }}</h5>
                        </div>
                        </span>
                        <span v-else>
                        <!-- ÍTENS SELECIONADOS -->
                        {{ item.title }} &nbsp ({{ selected.length }})
                        </span>
                    </v-list-item-title>
                </v-list-item-content>
                </template>


                <v-divider class="blue mx-2"></v-divider>

                <span v-for="sub in item.items" :key="sub.title"   v-if="vif_here(sub.click)">

                <v-list-item
                link
                @click="clicou_menu_parcelas(sub.click)"
                class="px-12"
                >

                    <v-icon :color="sub.icon_color" small>
                    {{ sub.icon }}
                    </v-icon>

                    <v-list-item-content class="ml-3">
                        <v-list-item-title v-text="sub.title"></v-list-item-title>
                    </v-list-item-content>

                </v-list-item>
                <v-divider class="mx-5"></v-divider>

            
                </span>

            </v-list-group>


            <v-divider class="blue mx-2 mt-2"></v-divider>
            </span>
        </v-list>
    </v-card>
    
</v-dialog>



<v-snackbar
    v-model="snackbar"
    :timeout="2000"
    top
    :color="snack_color"    
    style="margin-top: 30px"
>
<center>
    {{ snack_text }}
</center>
    <!-- <template v-slot:action="{ attrs }">
    <v-btn
        color="white"
        text
        v-bind="attrs"
        @click="snackbar = false"
    >
        Fechar
    </v-btn>
    </template> -->
</v-snackbar>





<v-row justify="center" class="no-print">
    <v-dialog
        v-model="dialog_gerar_parcela"
        persistent
        max-width="990px"
        scrollable
        
    >


    <v-overlay :value="overlay">
    <v-progress-circular
        indeterminate
        size="64"
    ></v-progress-circular>
</v-overlay>

        <v-form
            ref="form"
            v-model="valido2"
        >
            <v-card
            max-height="555px"
            scrollable
            >


            <!-- class="overflow-y-auto px-0 py-0" -->
            <v-card-title 
              :class="[(edita_gerar_parcelas.TIPO=='E') ? 'green lighten-4' : 'red lighten-4', 'mb-1']"
            >
                <span class="text-h5">Novo Lançamento </span>
                <span class="ml-2 text-h6">({{(edita_gerar_parcelas.TIPO=='E') ? 'Entrada' : 'Saida'}}) </span>

            </v-card-title>

            
            <v-card-subtitle class="grey lighten-4 pa-1 pl-3 pt-2">
                <v-container>


                    <v-row>
                        <v-col
                        cols="12"
                        class=""
                        
                        v-if="primeira_parcela==false"
                        >
                        <!-- style="margin-top:-30px; margin-bottom:-10px" -->
                        <!-- <v-text-field
                            v-model="edita_gerar_parcelas.REFERENTE"
                            type=""
                            label="Referente"
                            dense
                            :rules="notnullRules"
                            required
                            @keyup="allUpper($event,'edita_gerar_parcelas', 'REFERENTE')"
                            @change="multiplica"
                        >
                        </v-text-field> -->
                            <!-- {{ combobox_servico }} -->
                            <v-combobox
                                v-model="combobox_servico"
                                label="Referente (digitação livre)"
                                :items="items_tabela_servico"
                                item-value='DESCRICAO'
                                item-text='DESCRICAO'
                                dense
                                :return-object ="true"
                                clearable
                                :rules="notnullRules"
                                @input="seleciona_servico(combobox_servico)" 
                                @keyup="digitou_servico"
                                @keyup.esc="combobox_servico='', digitou_servico"
                                
                            >
                            <!-- @keyup="allUpper($event,'edita_gerar_parcelas', 'REFERENTE')" -->

                            
                                <!-- <template slot='selection' slot-scope='{ item }'>
                                {{ item.DESCRICAO }}
                                </template> -->
            
                                <template slot='item' slot-scope='{ item }'>
                                    <h5>{{ item.DESCRICAO }}</h5>
                                    &nbsp | &nbsp
                                    <div 
                                    class="caption"
                                    >
                                    {{ item.double_TOTAL }}
                                    </div>    
                                    &nbsp | &nbsp
                                    <div 
                                    class="caption"
                                    >
                                    {{ item.NIVEL }}
                                    </div>                   
                                </template>
                            </v-combobox>

                        </v-col>
                    </v-row>
                    
                    <v-row class="">

                        <v-col
                            class=""
                            cols="12"
                            md="2"
                        >   
                        <!-- v-model="servico_selecionado" -->
                            <v-select
                            v-model="edita_gerar_parcelas.CODIGO_CONTA"
                            label="Conta Referente"
                            :items="items_tabela_contas"
                            item-value='CODIGO'
                            item-text='DESCRICAO'
                            return-object
                            dense
                            :clearable = "false"
                            >
                            
                            <template slot='selection' slot-scope='{ item }'>
                            {{ item.DESCRICAO }}
                            </template>
                            </v-select>
                        </v-col>

                        <v-col
                        cols="6"
                        md="3"
                        >

                            <!-- <v-select
                            v-model="edita_gerar_parcelas.FORMA"
                            label="Forma"
                            :items = "formas_pagamentos"
                            dense
                            @change="multiplica"
                            ></v-select> -->

                            <v-combobox
                            v-model="edita_gerar_parcelas.FORMA"
                            label="Forma"
                            :items="items_tabela_formas"
                            item-value='DESCRICAO'
                            item-text='DESCRICAO'
                            dense
                            :clearable = "false"
                            :return-object="false"
                            :search-input.sync="search_input_forma"
                            :rules="notnullRules"

                            @change="multiplica"
                            @input="apertou_enter($event), allUpper($event,'edita_gerar_parcelas', 'FORMA')" 
                            >
                        
                            
                                <template slot='selection' slot-scope='{ item }'>
                                    <span class="fs-12"> {{ item }}</span>
                                </template>

                                <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        Para adicionar na lista pressione <kbd>enter</kbd>
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>

                            </v-combobox>

                        </v-col>


                        <v-col
                        cols="6"
                        md="2"
                        >
                        <v-text-field
                            v-model="edita_gerar_parcelas.DATA_VENCIMENTO"
                            type="date"
                            label="Vencimento"
                            dense
                            @change="multiplica"
                        >
                        </v-text-field>
                        </v-col>

                        <v-col
                        cols="6"
                        md="2"
                        >
                        <v-select
                            v-model="edita_gerar_parcelas.EFETUAR"
                            type="number"
                            label="Efetuado"
                            :items="lista_efetuar"
                            dense
                            @change="multiplica"
                        >
                        </v-select>
                        </v-col>


                        <v-col
                        cols="6"
                        md="2"
                        >
                        <v-text-field
                            v-model="edita_gerar_parcelas.TOTAL"
                            type=""
                            label="Total"
                            prefix="R$"
                            dense
                            @keyup="edita_gerar_parcelas.TOTAL = moeda($event)"
                            @change="multiplica"
                            :rules="notnullRules"
                        >
                        <!-- @keyup="edita_gerar_parcelas.TOTAL = this.DSibasico.moeda($event)" -->
                        <!-- @keyup="edita_servico.double_TOTAL = moeda($event)" -->
                        </v-text-field>
                        </v-col>



                        <v-col
                        cols="6"
                        md="1"
                        >

                        <v-select
                            v-model="edita_gerar_parcelas.PARCELAS"
                            type="number"
                            label="Parcelas"
                            :items="lista_parcelas"
                            dense
                        >
                        </v-select>
                        </v-col>
                    </v-row>


                    <v-row class="">

                            <v-col
                            cols="6"
                            md="auto offset-md-9"
                            class="pa-0"
                            style="margin-top:-30px; margin-bottom:-10px"
                            v-if="(primeira_parcela) && (edita_gerar_parcelas.TIPO=='E')"
                            >
                                <v-checkbox
                                    v-model="checkbox_entrada"
                                    label="com entrada"
                                    dense
                                    class=""
                                    style="margin-left: 8px"
                                    @change="multiplica"    
                                ></v-checkbox>
                                
                            </v-col>

                            <v-col
                            cols="6"
                            md="auto offset-md-11"
                            class="pa-0"
                            style="margin-top:-38px; margin-bottom:-15px"
                            >
                            <!-- v-if="souDSi" -->
                                <v-checkbox
                                    v-model="multi"
                                    label="x"
                                    dense
                                    class=""
                                    style="margin-left: 8px"
                                    @change="multiplica"    
                                    hint="Multiplica"
                                ></v-checkbox>
                                
                            </v-col>


                    </v-row>

                </v-container>
            </v-card-subtitle>

<!-- {{ def_parcelas }} -->
            <v-card-text class="pa-0 pl-0">
                    <v-card-text>
                        <v-container>
                            <v-row class="" dense
                            v-for="(item, i) in def_parcelas"
                            :key="i"
                            >
                            <!-- {{ def_parcelas }} -->
                            <!-- {{ item }}                      -->
                            <!-- {{edita_parcelas}} -->

                                <span 
                                    class="blue--text mt-3"
                                    v-if="checkbox_entrada && i==0"
                                >
                                    en 
                                </span>   
                                <span 
                                    class="mt-3"
                                    v-else
                                >
                                    {{ 1-inicio + parseInt(i)}}ª 
                                </span>   

                                <v-col
                                cols="6"
                                md="2"
                                >
                                    <v-text-field
                                        v-model="item.TOTAL"
                                        type=""
                                        :label="(checkbox_entrada && i==0) ? 'Entrada' : 'Valor'"
                                        prefix="R$"
                                        dense
                                        @keyup="item.TOTAL = moeda($event), digitou_entrada(item)"
                                        @change="multiplica()"
                                        :disabled="def_parcelas.length==i+ parseInt(1)"    
                                    >
                                    <!-- :disabled="def_parcelas.length==i+ parseInt(1)" desabilita quando ultimo campo -->

                                    </v-text-field>
                                </v-col>

                                &nbsp
                                &nbsp


                                <v-col
                                cols="6"
                                md="2"
                                >

                                    <!-- <v-select
                                    v-model="item.FORMA"
                                    label="Formax"
                                    :items = "formas_pagamentos"
                                    dense
                                    ></v-select> -->

                                    <v-select
                                    v-model="item.FORMA"
                                    label="Forma..."
                                    :items="items_tabela_formas"
                                    item-value='DESCRICAO'
                                    item-text='DESCRICAO'
                                    :return-object="false"
                                    dense
                                    :clearable = "false"
                                    @change="seleciona_forma_pagamento()"
                                    >
                                    
                                    <template slot='selection' slot-scope='{ item }'>
                                        <span class="fs-12"> {{ (item.DESCRICAO)? item.DESCRICAO : item }}</span>
                                    </template>
                                    </v-select>

                                </v-col>

                                &nbsp
                                &nbsp

                                <v-col
                                cols="6"
                                md="2"
                                >
                                <v-text-field
                                    v-model="item.DATA_VENCIMENTO"
                                    type="date"
                                    label="Vencimento"
                                    dense
                                >
                                </v-text-field>
                                </v-col>

                                &nbsp
                                &nbsp

                                <v-col
                                cols="6"
                                md="2"
                                >
                                <v-text-field
                                    v-model="item.DATA_EFETUADO"
                                    type="date"
                                    label="Efetuado"
                                    dense
                                >
                                </v-text-field>
                                </v-col>

                                &nbsp
                                &nbsp


                                <v-col
                                cols="3"
                                >
                                <v-text-field
                                    v-model="item.SERVICO_REFERENTE"
                                    type=""
                                    label="Referente."
                                    dense
                                    class="fs-12"
                                    :rules="notnullRules"
                                    @keyup="item.SERVICO_REFERENTE=item.SERVICO_REFERENTE.toUpperCase()"
                                >
                                </v-text-field>
                                </v-col>

                            </v-row>
                        </v-container>
                    </v-card-text>
            </v-card-text>
            <v-card-actions>

                <v-spacer></v-spacer>

                <v-btn
                    color="red"
                    text
                    @click="dialog_gerar_parcela=false"
                >
                    Cancelar
                </v-btn>
                
                
                &nbsp

                <v-btn
                    :disabled="!valido2"
                    color="primary"
                    @click="gerar_parcelas()"
                >
                    Gerar.
                </v-btn>
            </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</v-row>


<v-row justify="center" class="no-print">
    <v-dialog
        v-model="dialog_alterar_parcela"
        persistent
        max-width="990px"
        scrollable
        
    >

        <v-card
        max-height="555px"
        scrollable
        >
        <!-- class="overflow-y-auto px-0 py-0" -->
            <v-card-title 
            :class="[(edita_parcelas.TIPO=='E') ? 'green lighten-4' : 'red lighten-4', 'mb-1']"
            >
                <span class="text-h5">Alterar Parcela</span> 
                &nbsp <span class="text-caption"> {{ edita_parcelas.CODIGO  }} </span>
            </v-card-title>

            <v-form
            v-model="valido"
            >
            
            
                <v-card-text class="">
                    <v-container>
                        <v-row >

                            <v-col
                                class=""
                                cols="12"
                                :md="(showJuros) ? 3: 3"
                            >   
                            <!-- v-model="servico_selecionado" -->
                                <v-select
                                v-model="edita_parcelas.CODIGO_CONTA"
                                label="Conta Referente"
                                :items="items_tabela_contas"
                                item-value='CODIGO'
                                item-text='DESCRICAO'
                                return-object
                                dense
                                :clearable = "false"
                                @change="seleciona_conta_referente()"
                                >
                                <!-- :label="edita_parcelas.CODIGO_CONTA.DESCRICAO" -->
                                
                                <template slot='selection' slot-scope='{ item }'>
                                {{ item.DESCRICAO }}
                                </template>
                                </v-select>
                            </v-col>

                            

                            <v-col
                            cols="6"
                            md="2"
                            >
                                <v-text-field
                                    v-model="edita_parcelas.double_VALOR"
                                    label="Valor"
                                    prefix="R$"
                                    dense
                                    :rules="notnullRules"    
                                    @keyup="edita_parcelas.double_VALOR = moeda($event), calcula()"
                                >
                                
                                </v-text-field>
                            </v-col>


                            <v-col
                            cols="6"
                            md="2"
                            v-if="(showJuros)"
                            >
                                <v-text-field
                                    v-model="edita_parcelas.double_JUROS"
                                    label="+ Juros"
                                    prefix="R$"
                                    dense
                                    @keyup="edita_parcelas.double_JUROS = moeda($event), calcula()"
                                >
                                
                                </v-text-field>
                            </v-col>

                            <v-col
                            cols="6"
                            md="2"
                            v-if="(showJuros)"
                            >
                                <v-text-field
                                    v-model="edita_parcelas.double_TAXA"
                                    label="- Taxa"
                                    prefix="R$"
                                    dense   
                                    @keyup="edita_parcelas.double_TAXA = moeda($event), calcula()"
                                >
                                
                                </v-text-field>
                            </v-col>

                            <v-col
                            cols="6"
                            md="2"
                            v-if="(showJuros)"
                            >
                                <v-text-field
                                    v-model="edita_parcelas.double_TOTAL"
                                    label="Total"
                                    prefix="R$"
                                    dense
                                    :rules="notnullRules"    
                                    @keyup="edita_parcelas.double_TOTAL = moeda($event)"
                                >
                                
                                </v-text-field>
                            </v-col>


                            <v-col
                            cols="6"
                            md="3"
                            >

                                <!-- <v-select
                                v-model="edita_parcelas.FORMA_PAGAMENTO"
                                label="Forma"
                                :items = "formas_pagamentos"
                                dense
                                ></v-select> -->

                                <v-combobox
                                    v-model="edita_parcelas.FORMA_PAGAMENTO"
                                    label="Forma."
                                    :items="items_tabela_formas"
                                    item-value='DESCRICAO'
                                    item-text='DESCRICAO'
                                    :return-object="false"
                                    dense
                                    
                                    :search-input.sync="search_input_forma"
                                    @input="apertou_enter($event), allUpper($event,'edita_parcelas', 'FORMA_PAGAMENTO')" 
                                    :rules="notnullRules"
                                    :clearable = "false"

                                    @change="seleciona_forma_pagamento()"
                                    >
                                    
                                    <template slot='selection' slot-scope='{ item }'>
                                        <span class="fs-12"> {{ (item.DESCRICAO)? item.DESCRICAO : item }}</span>
                                    </template>

                                    <template v-slot:no-data>
                                    <v-list-item>
                                        <v-list-item-content>
                                        <v-list-item-title>
                                            Para adicionar na lista pressione <kbd>enter</kbd>
                                        </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    </template>

                                </v-combobox>

                            </v-col>


                            <v-col
                            cols="6"
                            md="2"
                            >
                            <v-text-field
                                v-model="edita_parcelas.DATA_VENCIMENTO"
                                type="date"
                                label="Vencimento"
                                dense
                            >
                            </v-text-field>
                            </v-col>


                            <v-col
                            cols="6"
                            md="2"
                            >
                            <v-text-field
                                v-model="edita_parcelas.DATA_EFETUADO"
                                type="date"
                                label="Efetuado"
                                dense
                                v-if="edita_parcelas.PAGO=='S'"
                            >
                            </v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col
                            cols="12"
                            :md="(showJuros) ? 11: 10"
                            
                            >
                            <v-text-field
                                v-model="edita_parcelas.SERVICO_REFERENTE"
                                type=""
                                label="Referente"
                                dense
                                :rules="notnullRules"
                                @input="allUpper($event,'edita_parcelas', 'SERVICO_REFERENTE')"
                            >
                            </v-text-field>

                            </v-col>
                            
                            <v-col
                            dense
                            cols="12"
                            :md="(showJuros) ? 11: 10"
                            class=""
                            v-if="(2>1)"
                            >
                                <v-text-field
                                    v-model="edita_parcelas.OBSERVACAO"
                                    type=""
                                    label="Anotação"
                                    dense
                                    @keydown="firstUpper($event, edita_parcelas.OBSERVACAO)"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

            </v-form>

            <v-card-actions>

                <v-spacer></v-spacer>

                <v-btn
                    color="red"
                    text
                    @click="dialog_alterar_parcela=false"
                >
                    Cancelar
                </v-btn>
                
                
                &nbsp

                <v-btn
                    :disabled="!valido"
                    color="primary"
                    type="submit"
                    @click="salvar_calculando()"
                >
                <!-- @click="salvar_tabela('conta', edita_parcelas,'dialog_alterar_parcela')" -->
                    Salvar
                    <!-- SALVAR PARCELA -->
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-row>


<v-row justify="center" class="no-print">
    <v-dialog
        v-model="dialog_efetuar"
        persistent
        max-width="1024px"
        scrollable
        
    >

        <v-card
        max-height="555px"
        scrollable
        >
        <!-- class="overflow-y-auto px-0 py-0" -->
            <v-card-title class="grey lighten-4">
                <span class="text-h5">Receber Parcela</span> 
                &nbsp <span class="text-caption"> {{ edita_parcelas.CODIGO  }} ({{ edita_parcelas.PLANO }})</span>
                <!-- {{ total_recebido }} -->
            </v-card-title>

            <!-- {{ edita_parcelas }}
            <br/>
            <br/>
            <br/>
            {{ total_recebido }} -->
            <v-form
            v-model="valido"
            >
            
            
                <v-card-text class="">
                    <v-container>
                        <v-row dense>

                            <v-col
                                class=""
                                cols="12"
                                md="2"
                            >   
                            <!-- v-model="servico_selecionado" -->
                                <v-select
                                v-model="edita_parcelas.CODIGO_CONTA"
                                label="Conta Referente"
                                :items="items_tabela_contas"
                                item-value='CODIGO'
                                item-text='DESCRICAO'
                                return-object
                                class="fs-12"
                                dense
                                :clearable = "false"
                                @change="seleciona_conta_referente()"
                                >
                                <!-- :label="edita_parcelas.CODIGO_CONTA.DESCRICAO" -->
                                
                                <template slot='selection' slot-scope='{ item }'>
                                {{ item.DESCRICAO }}
                                </template>
                                </v-select>
                            </v-col>



                            <v-col
                            cols="12"
                            md="2"
                            >
                            <!-- :md="(checkbox_parcial) ? '2' : '3' " -->

                                <!-- <v-select
                                v-model="edita_parcelas.FORMA_PAGAMENTO"
                                label="Forma"
                                :items = "formas_pagamentos"
                                dense
                                ></v-select> -->

                                <!-- se precisar..mudar o componente para combobox -->
                                <v-combobox
                                    v-model="edita_parcelas.FORMA_PAGAMENTO"
                                    label="Forma.."
                                    :items="items_tabela_formas"
                                    item-value='DESCRICAO'
                                    item-text='DESCRICAO'
                                    :return-object="false"
                                    dense

                                    :search-input.sync="search_input_forma"
                                    @input="apertou_enter($event), allUpper($event,'edita_parcelas', 'FORMA_PAGAMENTO')" 
                                    :rules="notnullRules"
                                    :clearable = "false"
                                    @change="seleciona_forma_pagamento()"
                                    >
                                    
                                    <template slot='selection' slot-scope='{ item }'>
                                        <span class="fs-11"> {{ (item.DESCRICAO)? item.DESCRICAO : item }}</span>
                                    </template>

                                    <template v-slot:no-data>
                                    <v-list-item>
                                        <v-list-item-content>
                                        <v-list-item-title>
                                            Para adicionar na lista pressione <kbd>enter</kbd>
                                        </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    </template>

                                </v-combobox>
                                    
                            </v-col>


                            &nbsp
                            &nbsp

                            <v-col
                            cols="12"
                            md="2"
                            >
                            <v-text-field
                                v-model="edita_parcelas.DATA_EFETUADO"
                                type="date"
                                label="Efetuado"
                                dense
                                :rules="notnullRules"    
                            >

                            <!-- <template slot='item' slot-scope='{ item }'>
                                <span class="fs-8"> {{ item }}</span>
                            </template> -->

                            </v-text-field>
                            </v-col>

                            &nbsp
                            &nbsp
                            
                            <v-col
                            cols="6"
                            md="2"
                            class=""
                            style="margin-top: -12px"
                            >
                                <h3>
                                Total
                                </h3>    
                                <h3>
                                    {{ edita_parcelas.double_TOTAL }}    
                                </h3>  
                            </v-col>


                            <v-col
                            cols="auto"
                            class=""
                            style="margin-top: 2px"
                            >

                                <v-checkbox
                                    v-model="checkbox_juros"
                                    label="Juros"
                                    dense
                                    style="margin-left: -70px"
                                    class="mt-n6"   
                                    v-if="(strtofloat(edita_parcelas.double_JUROS)>0) && (pode_alterar())"
                                    @change="cobrar_juros()"
                                ></v-checkbox>


                                <v-checkbox
                                    v-model="checkbox_parcial"
                                    label="Parcial"
                                    dense
                                    style="margin-left: -70px"
                                    @click="mesmo_valor"
                                    :class="[(strtofloat(edita_parcelas.double_JUROS)>0) && (pode_alterar()) ? 'mt-n4':'']"
                                >
                                    <template v-slot:label>
                                    <span class="orange--text">Parcial</span>
                                    </template>
                                </v-checkbox>

                            </v-col>


                            &nbsp
                            &nbsp
                            



                            <v-col
                            cols="12"
                            md="2"
                            class=""
                            v-if="checkbox_parcial"
                            >
                                <v-text-field
                                    v-model="total_recebido"
                                    label="Valor Recebido"
                                    prefix="R$"
                                    dense
                                    :rules="acimaZero"    
                                    @keyup="total_recebido = moeda($event)"
                                >
                                
                                </v-text-field>
                            </v-col>

    &nbsp
    &nbsp

                            <v-col
                            cols="12"
                            md="1"
                            class=""
                            v-if="checkbox_parcial"
                            >
                                <v-text-field
                                    v-model="prorrogar_parcial"
                                    type="number"
                                    label="Restante em"
                                    suffix="dias"
                                    dense
                                    :rules="ate45"    
                                >
                                
                                </v-text-field>
                            </v-col>

                        </v-row>



                    <v-row
                    class=""
                    >

                            <!-- <v-col
                            cols="auto"
                            md="auto offset-md-7"
                            class="pa-0"
                            style="margin-top:-35px; margin-bottom:-10px"
                            >
                                <v-checkbox
                                    v-model="checkbox_entrada"
                                    label="parcial"
                                    dense
                                    class=""
                                    style="margin-left: 20px"  
                                ></v-checkbox>
                                
                            </v-col> -->


                    </v-row>

                        <v-row dense>
                            <v-col
                            md="12"
                            class="pr-6"
                            >
                                <v-text-field
                                    v-model="edita_parcelas.SERVICO_REFERENTE"
                                    type=""
                                    label="Referentee"
                                    dense
                                    :rules="notnullRules"
                                    @input="allUpper($event,'edita_parcelas', 'SERVICO_REFERENTE')"
                                >
                                </v-text-field>
                            </v-col>

                            <v-col
                            dense
                            cols="12"
                            md="12"
                            class="pr-6"
                            v-if="(2>1)"
                            >
                                <v-text-field
                                    v-model="edita_parcelas.OBSERVACAO"
                                    type=""
                                    label="Anotação"
                                    dense
                                    @keydown="firstUpper($event, edita_parcelas.OBSERVACAO)"
                                >
                                </v-text-field>
                            </v-col>

                        </v-row>





                    </v-container>
                </v-card-text>

            </v-form>

            <v-card-actions>
                <v-spacer></v-spacer>


                <v-checkbox
                    v-model="checkbox_recibo"
                    label=""
                    dense
                    class="px-0 mr-2 pb-2"
                    style="margin-left: 8px" 
                    prepend-icon="mdi-printer"
                    hide-details=""
                ></v-checkbox>
                
                <v-divider vertical class="px-0 mr-2"/>

                 <v-btn
                    color="red"
                    text
                    @click="dialog_efetuar=false"
                >
                    Cancelar
                </v-btn>
                




                &nbsp
                &nbsp

                <v-btn
                    :disabled="!valido"
                    color="primary"
                    type="submit"
                    @click="efetuar()"
                >
                    Efetuar.
                </v-btn>

            </v-card-actions>
        </v-card>
    </v-dialog>
</v-row>

    <!-- PAINEL PARCELAS PROCURAR -->
    <v-container class="pl-0 pt-2 pb-0 pr-0 no-print">
        <v-card
        class="pl-0"
        >

        <!-- DSI -->
        <!-- pro_dados_aluno: {{pro_dados_aluno}}
        <br/>
        pro_dados_servico : {{ pro_dados_servico }} -->

        <v-card-title class="pa-0 pb-2 grey lighten-5">



            <span 
             v-if="((permissoes.gerarparcelas) && (pode_gerar()))"
            >
                <v-tooltip 
                bottom
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    v-bind="attrs"
                    v-on="on"
                    fab
                    class="ml-2 mt-1"
                    @click="mais_parcelas('E')"
                    x-small
                    color="success"
                    >
                    <!-- v-show="!hidden" -->
                    <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <span> Nova Entrada </span>
                </v-tooltip>
            </span>

            <span 
             v-if="((permissoes.gerarparcelas) && (pode_gerar()))"
            >
                <v-tooltip 
                bottom
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    v-bind="attrs"
                    v-on="on"
                    fab
                    class="ml-4 mt-1"
                    @click="mais_parcelas('S')"
                    x-small
                    color="error"
                    >
                    <!-- v-show="!hidden" -->
                    <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <span> Nova Saida </span>
                </v-tooltip>
            </span>

            <span>
                <v-tooltip 
                bottom
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    v-bind="attrs"
                    v-on="on"
                    fab
                    class="ml-4 mt-1"
                    @click="tabela('conta','update')"
                    x-small
                    color="primary"
                    >
                    <!-- v-show="!hidden" -->
                    <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                </template>
                <span> Atualizar </span>
                </v-tooltip>
            </span>



            &nbsp


            <v-menu
                bottom
                right
                min-width="180"
            >
                <template v-slot:activator="{ on: menu }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn
                                class="ml-2 mt-1"
                                color="primary"
                                small

                                fab
                                v-on="{ ...tooltip, ...menu }"
                            >
                                <v-icon class="">
                                mdi-printer 
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Imprimir ...</span>
                    </v-tooltip>
                </template>


              <v-card
                class="mx-auto"
              >

                <v-list dense>

                    <!-- <v-subheader class="grey lighten-3">
                      <h4 class="ml-1"> Menu </h4>
                    </v-subheader> -->


                    <v-list-item
                         v-for="item in menu_um"
                         :key="item.title"
                         link
                         @click="clicou_menu_um(item.click)"
                         v-if="item.type=='menu'"
                        >

                        <v-list-item-icon>
                            <v-icon :color="item.icon_color"> {{ item.icon }}</v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                              <v-list-item-title v-text="item.title"></v-list-item-title>
                          </v-list-item-content>

                    </v-list-item>



                    <v-list-group
                        v-for="item in menu_um"
                        :key="item.title"
                        v-model="item.active"
                        
                        no-action
                        @click.stop="" 
                        v-if="(item.type=='group')"
                    >

                        <template v-slot:activator>

                          <v-list-item-icon>
                            <v-icon :color="item.icon_color"> {{ item.icon }}</v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                              <v-list-item-title v-text="item.title"></v-list-item-title>
                          </v-list-item-content>
                        </template>


                        <v-list-item
                         v-for="sub in item.items"
                         :key="sub.title"
                         link
                         @click="clicou_menu_um(sub.click)"
                        >

                          <v-list-item-content>
                              <v-list-item-title v-text="sub.title"></v-list-item-title>
                          </v-list-item-content>
                          
                          
                          <v-list-item-icon>
                            <v-icon :color="sub.icon_color"> {{ sub.icon }}</v-icon>
                          </v-list-item-icon>

                        </v-list-item>
                    </v-list-group>



                </v-list>
              </v-card>
            </v-menu>


            <v-col cols="auto">

            <v-tooltip bottom eager>
                <template v-slot:activator="{ on, attrs }">
                    <v-checkbox
                    v-model="checkbox_so_entradas"
                    dense
                    class="ma-0 pa-0"
                    hide-details=""
                    @change="clicou_so_entradas()"
                    
                    >
                    <template v-slot:label>
                        <span :class="['fs-12']" v-bind="attrs" v-on="on">S.E.⠀</span>
                    </template>
                    </v-checkbox>
                </template>
                <span>Mostrar Somente Entradas</span>
                </v-tooltip>

            </v-col>




            



        <div v-if="0>1">
            <!-- REMOVER CASO NAO USE MAIS ESSES BOTOES DENTRO DESSA DIV-->
            <v-btn
                :color="pro_dados_servico.JA_ASSINADO == 'S' ? 'grey': 'warning'"
                class="ml-2 mt-2"
                dark
                x-small
                @click="imprimir_contrato('CONTRATO',pro_dados_servico,pro_dados_aluno)"
                
            >
                <v-icon
                    small
                    class="mr-2"
                >
                    mdi-text-box 
                    <!-- mdi-printer   -->
                </v-icon>
                <!-- <span v-if="item.JA_ASSINADO!=='S'"> -->
                    contrato
                <!-- </span> -->
                <!-- IMPRIMIR CONTRATO -->
            </v-btn>
            


            &nbsp

            <v-btn
                color="grey"
                dark
                class="ml-2 mt-2"
                x-small
                @click="imprimir_modelo('ficha', items_parcelas, pro_dados_aluno, pro_dados_servico)"
                
            >
                <v-icon small class="mr-2">
                mdi-account-box
                    <!-- mdi-printer   -->
                </v-icon>
                ficha
            </v-btn>

            &nbsp


           

            <v-btn
                color="grey"
                class="ml-2 mt-2"
                dark
                x-small
                @click=" imprimir_modelo('pre-solicitacao', items_parcelas, pro_dados_aluno, pro_dados_servico)"
                
            >
                <v-icon small class="mr-2" color="white">
                    mdi-book-open-outline
                    <!-- mdi-printer   -->
                </v-icon>
                pré-solicitação
            </v-btn>




            &nbsp

            <v-btn
                color="grey"
                class="ml-2 mt-2"
                dark
                x-small
                @click="imprimir_modelo('carne', items_parcelas, pro_dados_aluno, pro_dados_servico)"
                
            >
                <v-icon small class="mr-2" color="white">
                    mdi-book-open-outline
                    <!-- mdi-printer   -->
                </v-icon>
                carnê
            </v-btn>


            &nbsp

            <v-btn
                color="grey"
                class="ml-2 mt-2"
                x-small
                dark
                @click="imprimir_contrato('IMPRESSÃO',pro_dados_servico,pro_dados_aluno)"
                
            >
                <v-icon
                    small
                    class="mr-2"
                >
                    mdi-text-box
                </v-icon>
                    declaração
            </v-btn>
        </div>


           <v-spacer></v-spacer>

            &nbsp 
            <v-col
            cols="5"
            md="2"
            >
                <v-text-field
                dense
                class="my-0"
                v-model="search"
                v-if="items_parcelas.length>0"
                clearable
                append-icon="mdi-magnify"
                label="Procurar"                
                hide-details
                @keyup.esc="keyUpp('esc')"
                >
                </v-text-field>
            </v-col>


        </v-card-title>

        <!-- DSI -->
        <!-- {{ emp }} -->

        <v-data-table
            v-model="selected"
            item-key="CODIGO"

            :show-select="true"
            :single-select="false"

            @contextmenu:row="rightClick"
            @click:row="seleciona_linha"

            v-if="(items_parcelas.length>0)"
            :headers="items_header"
            :items="items_parcelas"
            
            class="elevation-4"
            :item-class= "rowClass" 

            :items-per-page="-1"

            dense
            :search="search"
            :mobile-breakpoint="55"

            :height="retorna_altura( (isMobile ? 300 : 350))"
            :hide-default-footer = "true"

            fixed-header

            
        >
        <!-- :item-class= "rowClass"  -->
        

            <template v-slot:item.SERVICO_REFERENTE="{ item, index }">
                
                <v-chip 
                  color="grey lighten-0"
                  small dark class="ma-1" 
                >
                  {{ item.FORMA_PAGAMENTO }}
                </v-chip>

                <v-chip 
                  :color="(item.TIPO=='E') ? 'green lighten-3' : 'red lighten-3'"
                  small class="font-weight-medium ma-1" 
                  v-if="(item.CATEGORIA!='RECEBIMENTO')&&(item.CATEGORIA!='RECEBIMENTOS')"
                >
                  {{ item.CATEGORIA }}
                </v-chip>
                
                <v-chip 
                  :color="(item.TIPO=='E') ? 'blue lighten-3' : 'red lighten-3'"
                  small class="font-weight-medium ma-1" 
                  v-if="(item.SERVICO_REFERENTE!=item.CATEGORIA) && (item.SERVICO_REFERENTE)"
                >
                  {{ item.SERVICO_REFERENTE }}
                </v-chip>
                
                <!-- <v-chip 
                  color="grey lighten-1"
                  small class="ma-1" 
                  v-if="(item.OBSERVACAO)"
                >
                  {{ item.OBSERVACAO }}
                </v-chip> -->


                <span class="ml-0 py-1 bold orange--text" v-if="item.OBSERVACAO">
                    <v-icon
                        v-if="item.OBSERVACAO !== ''"
                        class="ml-1"
                        size="14"
                        color="orange"
                    >
                        mdi-comment-alert 
                    </v-icon>
                    ({{ item.OBSERVACAO }})
                </span>

                
            </template>


            <template v-slot:item.CODIGO="{ item, index }">
                <!-- <span style=" text-align: right; font-size: 10px;" class="grey--text pa-0 mt-2 ml-0"> {{ index + 1 }}º &nbsp </span>   -->
                <!-- <span style=" text-align: right; font-size: 10px;" class="grey--text pa-0 mt-2 ml-0" v-if="(!souDSi)"> {{ index + 1 }}º &nbsp </span>   -->

                <!-- <span style=" text-align: left; font-size: 12px;" class="grey--text pa-0 mt-2 ml-0" > {{ item.CODIGO }} </span>   -->
                <span> {{ item.CODIGO }} </span>  
                <!-- <span>{{ (item.CODIGO) }}</span> -->
            </template>


            <template v-slot:item.VISTO="{ item }">
              <div @click="muda_visto(item)">
                <v-icon small color="green" v-if="(item.VISTO=='S')">
                  mdi-check-bold 
                </v-icon>
                <v-icon small  v-else>
                  mdi-radiobox-blank 
                </v-icon>
            </div>
            </template>


            <template v-slot:item...="{ item }">
                <v-icon             
                    class="pa-0 "
                    color="blue"
                    size="20"
                    @click="abre_menu(item)"
                >
                    <!-- mdi-dots-vertical -->
                    mdi-menu
                </v-icon> 
                <!-- @click="editar_tabela(item, 'edita_parcelas', 'dialog_alterar_parcela')" -->
                
                &nbsp

            </template>

            <!-- <template v-slot:item..="{ item }">
                <v-icon             
                    class=""
                    color="red"
                    size="22"
                    @click="remover_tabela('conta',item, '')"
                    v-if="(item.PAGO=='N')"
                >
                    mdi-delete-circle-outline 
                </v-icon> 
            </template> -->

            <template v-slot:item.TIPO="{ item, index }">
                <span style=" text-align: right; font-size: 10px;" class="grey--text pa-0 mt-2 ml-0"> {{ index + 1 }}º &nbsp </span>
                <v-icon v-if="(item.TIPO=='E')" color="success" size="18"> mdi-alpha-e-circle </v-icon> 
                <v-icon v-if="(item.TIPO=='S')" color="error" size="18"> mdi-alpha-s-circle </v-icon> 


                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="px-1" size="18" color="black" v-if="item.PIX_URL" v-bind="attrs" v-on="on">
                            mdi-barcode 
                        </v-icon>

                    </template>
                    <span> Fatura Gerada </span>
                  </v-tooltip>

            </template>
            
            
            <template v-slot:item.double_VALOR="{ item }">
                <strong>
                  R$ {{ item.double_VALOR }}
                </strong>
            </template>
            
            <template v-slot:item.double_TOTAL="{ item }">
                <v-chip
                    :color="(item.TIPO=='S')? 'error' : (item.PAGO=='S' ) ? 'green' :  'green'" 
                    small class="ma-1" dark
                >
                    R$ {{ item.double_TOTAL }}
                </v-chip>
            </template>
            
            
            <template v-slot:item.double_JUROS="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                            <span
                             v-bind="attrs"
                             v-on="on"
                             :class="[strtofloat(item.double_JUROS)>0 ? 'red--text bold' : '']"
                            >
                               R$ {{ item.double_JUROS }}
                            </span>

                        </template>                    
                        <span v-html="item.JUROS_MSG" v-if="strtofloat(item.double_JUROS) > 0"></span>
                  </v-tooltip>
            </template>


            
            <template v-slot:item.DATA_VENCIMENTO="{ item }">
                <span
                   v-if="item.PAGO !=='S'"
                   
                   class="bold"
                >
                <!-- :style= "{ color: getVencido(today,item.DATA_VENCIMENTO)}" -->
                  {{ getFormatDate(item.DATA_VENCIMENTO) }}
                </span>

                <span
                v-else
                class="font-weight-medium"
                >
                  {{ getFormatDate(item.DATA_VENCIMENTO) }}
                </span>
            </template>
            
            <template v-slot:item.DATA_EFETUADO="{ item }">


                 <!-- <span
                  v-if="(item.PAGO == 'S')"
                  :class="[(item.PAGO == 'S') ? 'green--text':'', 'font-weight-medium']"
                 >
                   <h3>
                    {{ getFormatDate(item.DATA_EFETUADO) }}
                   </h3>
                 </span> -->

                <v-chip  
                  v-if="(item.PAGO == 'S')"  small class="pa-2 fs-12 white--text font-weight-medium" color="grey"
                >        
                <v-icon left size="12" class="pl-1" color="">
                    mdi-check-bold
                </v-icon>            
                  {{ item.x_DATA_EFETUADO }}   
                </v-chip>


                 <!-- IMPRIMIR RECIBO -->
                 <!-- <v-icon
                    color="primary"
                    @click="imprimir_recibo(item)"
                    v-if="item.PAGO == 'S'"
                > 
                    mdi-printer 
                </v-icon> -->
            

                <v-chip
                    color="blue"
                    class="ml-1"
                    small dark
                    v-if="((item.PAGO !=='S')&&(pode_gerar()))"
                    @click="abre_efetuar(item)"
                >
                  
                <v-icon left size="12" class="" color="">
                    mdi-dots-circle
                </v-icon>

                <span class="">EFETUAR</span>

                <!-- <v-icon right size="12" class="pr-1" color="">
                    mdi-dots-circle
                  </v-icon> -->
                  

                </v-chip>
            </template>

        </v-data-table>
        </v-card>
    </v-container>

  </div>

</template>

<script>

import axios from "axios";

import DSibasico from '../assets/js/DSi_basico'//chamo funcao do arquivo
import DSisql from '../assets/js/DSi_sql'//chamo funcao do arquivo
import DSiAsaas from '../assets/js/DSi_asaas'//chamo funcao do arquivo
import { negritoO } from '../assets/js/DSi_sql'
import { logado } from '../assets/js/DSi_rotinas'
import { registra_log} from '../assets/js/DSi_login'

var url_app =  sessionStorage['url']

export default {

    components:{
    //    print_recibo_Vue
    },

    props:{
        pro_ide: String,
        pro_cod_servico: String,
        pro_dados_aluno: Object,
        pro_dados_servico: Object
    },

    data: () => ({


        admins: [
        ['Management', 'mdi-account-multiple-outline'],
        ['Settings', 'mdi-cog-outline'],
      ],
      cruds: [
        ['Create', 'mdi-plus-outline'],
        ['Read', 'mdi-file-outline'],
        ['Update', 'mdi-update'],
        ['Delete', 'mdi-delete'],
      ],


        checkbox_so_entradas: false,

        podeFaturar: false,
        souDSi: false,
        can_show: false,
        urlLogo:'',  


        overlay: false,

        search_input_forma:'',

        obj_user: {},

        def:{motivo_processo:''},  
        dialog_opcoes: false,

        showJuros: false,

        selected: [],
        menu_parcelas: [],
        obj_clicado: {},
        tabela_selecionada: '',
        selecionou_varios: false,

        obj_recibo_unico: {},

        menu_um: [],

        export_parcelas:[],

        emp:{},
        config_pix: null,
        config_emp:{},

        ide:69,
        cod_servico : 65,

        timer_digitou: null,
        timer_digitou_servico: null,

        dialog_print: false,

        nVias: '2',
        objVias:[],
        // objVias:[
        //     {n:0},{n:1}
        // ],
        

        valido:false,
        valido2:false,

        search:'',

        total_recebido:0,
        prorrogar_parcial:0,

        permissoes:{
           gerarparcelas: true,
        },

        combobox_servico:{},
        items_tabela_servico: [],

                items: [
          'Programming',
          'Design',
          'Vue',
          'Vuetify',
        ],

        hoje :'',
        hoje_week:'',
        hora :'',
        data_hora :'',
        today :'',
        quem_alterou:'',

        snackbar: false,
        snack_text: 'My timeout is set to 2000.',
        snack_color: "success",

        refresh_entrada: false,

        valores_digitou:[],

        inicio: 0,
        multi:false,
        primeira_parcela: true,
        checkbox_entrada: true,
        checkbox_parcial: true,
        checkbox_juros: true,
        checkbox_recibo: true,

        notnullRules: [
            v => !!v || 'necessário'
        ],

       acimaZero: [
        v => !!v || 'necessário',
        v =>( (parseInt(v) > 0) ) || 'necessário'
       ],

       ate45: [
        v => !!v || 'necessário',
        v =>( (parseInt(v) > 0) && (parseInt(v) <= 45)) || 'necessário'
       ],

        items_parcelas:[],
        items_header: [],

        items_tabela_contas: [],
        items_tabela_formas: [],

        obj_dados_aluno:{},
        obj_dados_servico:{},

        dialog_gerar_parcela: false,
        dialog_alterar_parcela:false,
        dialog_efetuar: false,
        
        dialog_menu: false,
        item_menu:{},

        edita_gerar_parcelas:{
         TOTAL: 0,
         FORMA:'',
         TIPO:'',
         DATA_VENCIMENTO:'',
         EFETUAR:'',
         PARCELAS:1,
         SERVICO_REFERENTE:'',
         CODIGO_SERVICO: 0,
         CODIGO_CLIENTE: 0,
         CODIGO_CONTA: 0,	
        },

        def_parcelas:[
            {CODIGO:10, TOTAL:0, FORMA:'', DATA_VENCIMENTO:'', DATA_EFETUADO:'', SERVICO_REFERENTE:''},
        ],

        edita_parcelas:{},
        edita_copia:{},
        obj_valores:{},
        obj_imprimir:{},

        formas_pagamentos:[
            'DINHEIRO',
            'PIX',
            'TRANSFERENCIA',
            'CARTAO DE CREDITO',
            'CARTAO DE DEBITO',
            'BOLETO',
            'CHEQUE'
        ],

        lista_efetuar:['HOJE','NO VENCIMENTO','PENDENTE'],
        lista_parcelas:['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15'],
    }),


    watch:{

        selected(val){
          this.selecionou_varios = (this.selected?.length > 1)  

          //limpa recibo unico
          if (!this.selecionou_varios){
            this.obj_recibo_unico = {}
          }
        },

        'edita_gerar_parcelas.PARCELAS'(a){
            this.multiplica()
        },

        'edita_gerar_parcelas.TOTAL'(a){
            this.multiplica()
        },
        
        'edita_gerar_parcelas.DATA_VENCIMENTO'(a){
            this.multiplica()
        },
        

        pro_ide(){//CARREGA TODA VEZ QUE MUDA DE CLIENTE LA NO CADASTRO
            this.refresh()
        }
    },

    created(){
        this.onCreated()
    },


    
    methods:{


        clicou_so_entradas(){
            this.tabela('conta','update')
            this.manda_emit('update_somas', this.checkbox_so_entradas)//manda update na janela Cadastro.vue
        },

        async atualizaSelecao(item_name){

            let percorrer = this.selected.map((x) => x);//clonar array selecao para pegar os codigos ja selecionados
            this.selected = []//limpo a selecao
            for (let i = 0; i < percorrer?.length; i++){//percorro o clone dos codigos selecionados

                // console.log('percorrer[i]:'+ i);
                // console.log(percorrer[i]);

                if (percorrer[i]){
                let achar = this[item_name].filter(x => (x.CODIGO === percorrer[i].CODIGO));//clono somente os codigos da lista atualizada
                achar = achar[0] //trasnformo array em objeto
                // console.log('achar:', achar);
                this.selected.push(achar) //preenchendo array com o objeto clonado
                }
            }

        },

        imprimir_recibo_unico(){

            let qt = this.selected.length
            let linha = {}
            let soma  = 0

            this.obj_recibo_unico = {}

            for (let i = 0; i < qt; i++){

                linha = this.selected[i]

                if ((linha.PAGO=='S') && (linha.TIPO == 'E')){
                    try{
                    soma += parseFloat(this.strtofloat(linha.double_TOTAL))
                    
                    this.$set(this.obj_recibo_unico   , 'TOTAL', soma )
                    this.$set(this.obj_recibo_unico   , 'NUMEROS', `${linha.CODIGO}, ${(this.obj_recibo_unico.NUMEROS) || ''}` )
                    

                    }catch(e){
                    alert('Erro na somatório do Recibo Único')  
                    return false
                    }
                }
                else{
                    alert(`Lançamento Nº${linha.CODIGO} não foi Efetuada!`)
                }
                
                // console.log('linha:', linha);
            }

            this.dialog_menu = false

            // caso nao tenha nenhuma parela efetuada selecionada
            if(Object.keys(this.obj_recibo_unico).length === 0){
                return false
            }

            this.$set(this.obj_recibo_unico   , 'REFERENTE'     , this.pro_dados_servico.DESCRICAO + ` - PARCELAS Nº: ${this.obj_recibo_unico.NUMEROS}`)
            this.$set(this.obj_recibo_unico   , 'TOTAL'         , this.floatTostring(soma) )
            this.$set(this.obj_recibo_unico   , 'DATA'          , this.hoje) 
            this.$set(this.obj_recibo_unico   , 'QUEM_EFETUOU'  , DSibasico.get_quem_alterou() )

            this.imprimir_recibo(false)
            


        },

        clicou_menu_parcelas(acao){
            // alert(acao)
            this.acao_atual = acao



            if (acao == 'all_recibo_unico'){
               this.imprimir_recibo_unico()
            }
       },

       vif_here(acao){

          if (acao == 'all_recibo_unico'){
            // return  (this.souDSi)&&(this.item_menu.PAGO=='N')&&(!this.item_menu.PIX_ID) &&(this.item_menu.TIPO=='E')
            return true
          }
          
          else if (acao == 'ÍTENS SELECIONADOS'){
            return this.selecionou_varios
            return true//somente para testar
         }
       },

        monta_menu_parcelas(){
            this.menu_parcelas.push(           

                {
                    type:'group',
                    icon:'mdi-square',
                    icon_color: 'grey',
                    title: 'ÍTENS SELECIONADOS',
                    click: '', 
                    active: true, 
                    items: [
                        {type:'', title: 'Recibo Único',  icon:'mdi-printer', icon_color: 'primary',  click:'all_recibo_unico' },
                    ],
                },
            )
        },

       async pre_gerar_pix(dados_parcela, alerta){


            let link
            let token

            if (this.config_emp.PIX_SITUACAO == 'TESTE'){
                token = this.config_pix.PIX_TOKEN_TESTE
                link  = this.config_pix.PIX_LINK_TESTE
            }
            else if (this.config_emp.PIX_SITUACAO == 'PRODUÇÃO'){
                token = this.config_pix.PIX_TOKEN
                link  = this.config_pix.PIX_LINK
            }
            else{
                alert('Plataforma de Cobrança não definida!')
                return false
            }


            if (alerta){
                if (!confirm(`Gerar Fatura ${this.config_emp.PIX_PLATAFORMA} ?`)){
                    return false
                }
            }



             // ALGUMAS VERIFICACOES
             if (!this.pro_dados_aluno.CPF){
                alert('Preencha o campo CPF')
                return false
             }


            let retorno = await this.gerar_pix(dados_parcela, this.pro_dados_aluno, link, token)

            if (retorno){

                if (alerta){

                    let pagina_retorno = retorno.invoiceUrl

                    if (confirm(`Visualizar a Fatura?`)){
                        window.open(pagina_retorno, '_blank');
                    }
                }

                setTimeout(function(){
                    this.tabela('conta','update')//refresh
                }.bind(this), 555);//1100
            }
            else{

            }

          
       },

       ver_cobranca_pix(item){
          window.open(item.PIX_URL, '_blank');
       } ,

       async pix_id_aluno(link, token, dados_aluno){


            // ALGUMAS VERIFICACOES
            if (!dados_aluno.CPF){
                alert('Preencha o campo CPF')
                return false
            }

            let tipo_id_aluno = 'CODIGO_ALUNO' 
            
            let cpf           = await DSibasico.somenteNumeros(dados_aluno.CPF)
            let url           = ''
            let ref           = ''

            if (tipo_id_aluno == 'CODIGO_ALUNO'){
                
                ref = dados_aluno.CODIGO_ALUNO+'_'+cpf
                
                url = link + 'customers?externalReference=' + ref
                // url = link + 'customers?externalReference=' + dados_aluno.CODIGO_ALUNO
            }
            else if (tipo_id_aluno == 'CPF'){
                url  = link + 'customers?cpfCnpj=' + cpf
            }

            let retorno = await DSiAsaas.sendGetAxios('GET', url, token, null)

            // tem aluno..retorna id dele
            if (retorno.totalCount>0){
                // console.log('id do cliente:', retorno.data[0].id);
                return  retorno.data[0].id 
            }
            else{
                // caso nao tenha..cadastra novo aluno

                url  = link + 'customers'

                // ENVIO OS CAMPOS NECESSARIOS
                let body  =
                    {
                        name                    : dados_aluno.NOME,
                        // email                   : dados_aluno.EMAIL, //se enviar email .. sera enviado alerta pro aluno tb    
                        cpfCnpj                 : dados_aluno.CPF,
                        postalCode              : dados_aluno.CEP,
                        externalReference       : ref,
                        // addressNumber           : '10',
                        notificationDisabled    : true, //alerta pra o cfc
                    }

                let retorno_2 = await DSiAsaas.sendGetAxios('POST', url, token, body)

                if (retorno_2.id){
                //    console.log('id do cliente cadastrado:', retorno_2.id);
                   return  retorno_2.id
                }
                else{
                  return false 
                }

            }

       }, 

       retorna_altura(menos){
          // let r = '100vh'  
          // let r = '720'
          // let r = (window.innerHeight - 80 - 140)
          let r = (window.innerHeight - menos)
          let height = window.innerHeight;

          return r
       },

       rowClass(item){
        // console.log(item);
        let r = this.getColorLinha(item)

        // console.log(r);
        return r
        // return 'border-bottom';
      },

      getColorLinha (item) {
        
        let r = ''

               
        
        if (item.TIPO == 'S') r =  'red lighten-4'
        else if (item.VISTO == 'S') r =  'green lighten-5'
        else if (item.PAGO == 'S') r =  'green lighten-5'
        else if ( (item.PAGO !='S') && (this.today > item.DATA_VENCIMENTO) ) r = 'orange lighten-4'
        else r = ''

        return r
      },

       meu_keyup2(event,campo,upper){
      
        //identifica se o campo é double e muda o display
        if ((campo.includes('double_') || campo.includes('VALOR')) ){
            this.item_edita[campo] =  this.moeda(event)
            // @keyup="editedItem.double_credito = moeda($event)"
        }
        else if (campo.includes('CEP')) {

            this.consulta_cep(this.item_edita[campo])    
        }
        else{
            if ((campo.includes('EMAIL')==false) &&
            (campo.includes('SENHA')==false) &&
            (campo.includes('SENHA_WEB')==false)){

                if (upper !=='N'){
                this.allUpper(event, campo)
                }
                
            }
        }
      },  

      apertou_enter(event){

        if (!event){ return 'exit' }
        this.items_tabela_formas.unshift(event.toUpperCase())
      },

      cobrar_juros(){
        if (!this.checkbox_juros){

            
            this.$set(this.edita_parcelas   , 'double_TOTAL', this.obj_valores.double_VALOR )
            this.$set(this.edita_copia      , 'double_TOTAL', this.obj_valores.double_VALOR )
            this.$set(this.edita_copia      , 'double_JUROS', '0' )
            this.total_recebido = this.obj_valores.double_VALOR
        }
        else{
            this.$set(this.edita_parcelas   , 'double_TOTAL', this.obj_valores.double_TOTAL )
            this.$set(this.edita_copia      , 'double_TOTAL', this.obj_valores.double_TOTAL )
            this.$set(this.edita_copia      , 'double_JUROS', this.obj_valores.double_JUROS )
            this.total_recebido = this.obj_valores.double_TOTAL
        }
      },


      muda_visto(item){
        console.log(item);


        if (this.obj_user.SUPER!='S'){
          alert('Somente Super pode alterar o Visto')
          return 'exit'
        }

        if (item.PAGO!='S'){
          alert('Parcela não esta Efetuada, não pode ser vistada!')
          return 'exit'
        }

        if (!confirm("Alterar Visto?")){
          return 'exit'
        }

        let visto = item.VISTO

        if (visto != 'S'){ visto = 'S'} else {visto = 'N'}
        let codigo = item.CODIGO

        let sql = `update conta set conta.VISTO = 'S' where CODIGO = ${codigo}`
        this.crud_sql(sql)


        this.atualiza_lista('items_parcelas', item.CODIGO, 'VISTO', visto)
        // ATUALIZA LISTA SEM DAR CLOSE OPEN NA TABELA
        // let index = this.items_conta.indexOf(item)
        // let index = this.items_conta.findIndex( x => x.CODIGO === item.CODIGO );
        // this.$set(item, 'VISTO',  visto)
        // Object.assign(this.items_conta[index], item)

      },

        async atualiza_lista(nome_item, codigo, campo, valor){

            // ATUALIZA LISTA SEM DAR CLOSE OPEN NA TABELA
            let index = this[nome_item].findIndex( x => x.CODIGO === codigo );
            let item = this[nome_item][index]
            this.$set(item, campo, valor)
            Object.assign(this[nome_item][index], item)
        },


        async achar_atrasadas(items){
            // console.log('achar_atrasadas:', items);

            for (var i = 0; i < items.length; i++) {
                let item = items[i]
                if (item.PAGO !='S'){
                    if (this.today > item.DATA_VENCIMENTO){
                        // console.log('ATRASADO', item.DATA_VENCIMENTO);
                        // this.atualiza_lista('items_parcelas', item.CODIGO, 'PAGO', 'S')
                        await this.atualiza_juros(items[i], true, false)//somente calculo.. sem salvar tipo calcfield
                    }
                }
   
            }
        },

        pode_gerar(){
            
            if ( (this.obj_user.SUPER!='S') && (this.obj_user.PARC_GERAR!='S')){
                return false
            }
            else{
                return true
            }   
        },
        
        pode_imprimir(){
            
            if ( (this.obj_user.SUPER!='S') && (this.obj_user.CADASTRO_U!='S')){
                return false
            }
            else{
                return true
            }   
        },
        
        pode_alterar(){
            
            if ( (this.obj_user.SUPER!='S') && (this.obj_user.PARC_ALTERA!='S')){
                return false
            }
            else{
                return true
            }   
        },

        pode_estornar(){
            
            if ( (this.obj_user.SUPER!='S') && (this.obj_user.PARC_ESTORNA!='S')){
                return false
            }
            else{
                return true
            }   
        },

        pode_excluir(){
            
            if ( (this.obj_user.SUPER!='S') && (this.obj_user.PARC_EXCLUI!='S')){
                return false
            }
            else{
                return true
            }   
        },


      async onCreated(){

        if (await logado(this)){
          
            await this.showDrawer(true)
            
            this.souDSi = (sessionStorage['souDSi'] == 'S') ? true : false 
            // this.souDSi = true //@rex

            this.showJuros = (sessionStorage['conf_mostrar_juros'] != 'SIM') ? false : true
            this.refresh()
            this.carrega_dados()
            this.monta_menu_um()
            this.monta_menu_parcelas()

            //busco objeto no sessionStorage
            this.obj_user = JSON.parse(sessionStorage['obj_user'])
        }
      }, 

      async showDrawer(value){
        this.$emit('executar', value)//mostrar ou nao paineis
        this.can_show = true
        this.isMobile = window.innerWidth < 600
      },



      monta_menu_um(){
        // this.menu_um.push( { menu: 'Imprimir', icon: 'mdi-printer ', icon_color: "blue", click: 'financeiro' } )
        this.menu_um.push(           

            {type:'menu', title: 'Contrato',        icon:'mdi-text-box',            icon_color: '',    click:'contrato' },
            {type:'menu', title: 'Impressão',      icon:'mdi-text-box',             icon_color: '',    click:'declaracao' },
            {type:'menu', title: 'Ficha',           icon:'mdi-account-box',         icon_color: '',    click:'ficha' },
            {type:'menu', title: 'Pré-Solicitação', icon:'mdi-book-check-outline ', icon_color: '',    click:'pre-solicitacao' },
            {type:'menu', title: 'Carnê',           icon:'mdi-book-open',           icon_color: '',    click:'carne' },

            {
                type:'group',
                icon:'mdi-list-status ',
                icon_color: 'blue',
                title: 'Avaliação',
                click: '', 
                items: [
                    {type:'', title: '20 aulas',        icon:'', icon_color: '',  click:'avaliacao_20' },
                    // {type:'', title: '24 aulas',        icon:'', icon_color: '',  click:'avaliacao_24' },
                ],
            },
            
        )
      },


    //   [ { "active": true, "icon": "mdi-printer", "icon_color": "blue", "title": "Imprimir", "click": "financeiro", "items": [ { "title": "Contrato", "icon": "mdi-text-box", "click": "contrato" }, { "title": "Ficha", "icon": "mdi-account-box", "click": "ficha" }, { "title": "Pré-Solicitação", "icon": "mdi-book-open", "click": "pre-solicitacao" }, { "title": "Carnê", "icon": "mdi-book-open", "click": "carne" }, { "title": "Declaração", "icon": "mdi-text-box", "click": "declaracao" } ] }, 
    //     { "title": "Esse", "icon": "mdi-text-box", "click": "declaracao" } 
    //   ]

      clicou_menu_um(acao){
        
        if (acao == 'contrato'){
           this.imprimir_contrato('CONTRATO', this.pro_dados_servico, this.pro_dados_aluno)
        }
        else if (acao == 'declaracao'){
            this.imprimir_contrato('IMPRESSÃO', this.pro_dados_servico, this.pro_dados_aluno)
        }
        else{
            this.imprimir_modelo(acao, this.items_parcelas, this.pro_dados_aluno, this.pro_dados_servico)
        }
      },


      async salvar_calculando(){

        await this.atualiza_juros(this.edita_parcelas, false, false)//nao uso da tabela-financeiras... uso do proprio campo salvo
        await this.salvar_tabela('conta', this.edita_parcelas,'dialog_alterar_parcela', this.item_menu)
        
      },

      async calcular_juros(item, usar_tabela){
        
        let multa = sessionStorage['emp_multa']
        let juros = sessionStorage['emp_juros']

        let m     = multa
        let j     = juros
        let j2    = 0
        let valor     = this.strtofloat(item.double_VALOR)
        let taxa      = this.strtofloat(item.double_TAXA)
        let juros_ja  = this.strtofloat(item.double_JUROS)
        let total = valor
        let total_juros = 0
        let string_juros = ''

        let dias = await DSibasico.diferenca_datas(item.DATA_VENCIMENTO, this.today)
        

        if (usar_tabela){  
          // se definiu as taxas em Tabelas-Financeiras
          m = ((m * valor) / 100)
          m = m.toFixed(2);

          j = ((j * valor) / 100)
          j = j.toFixed(2);
          j2 = (j * dias)

          if (dias >0){
            total_juros = parseFloat(m) + parseFloat(j2)
          }
        }
        else{
          // senao ver se tem juros salvo no proprio campo da parcela
        //   if (juros_ja > 0){
          if (juros_ja != ''){
            total_juros = juros_ja
          }
        }

        // alert(total_juros)

        // 1º somas os juros
        total = parseFloat(valor) + parseFloat(total_juros) 


        // 2º depois subtrai as taxas
        if (taxa>0){
          total = parseFloat(total) - parseFloat(taxa)
        }

        
        total = this.floatTostring(total)
        total_juros = this.floatTostring2(total_juros)
        

        string_juros = `Após vencimento, cobrar multa de ${multa}% = R$ ${m} \n` +
                       `Após vencimento, cobrar juros de ${juros}% ao dia = R$ ${j}`


        let retorno = {
          dias_atrasado : dias,
          valor : valor,
          total : total,
          multa : m,
          juros : j2,
          total_juros: total_juros,
          string_juros: string_juros
        }

        return retorno

      },


      calcula(){
        //if (this.showJuros){
          // console.log('calcula');
          this.atualiza_juros(this.edita_parcelas, false, false)
        //}
      },

      async atualiza_juros(item, usar_tabela, ja_salva){
        let r = await this.calcular_juros(item, usar_tabela)

        // alert('atu juros')
        // console.log('atualiza_juros parcelas');
        // console.log(r);
        // console.log(item);

          if (this.showJuros){
            let textoJuros = `${r.dias_atrasado} dias atrasados\n${r.string_juros}  (R$ ${this.floatTostring2(r.juros)}) \nTotal de Juros: R$ ${r.total_juros}`
            textoJuros     = textoJuros.replaceAll('\n', '<br>') //quebra de linha

            this.$set(item, 'double_TOTAL'  , r.total)
            this.$set(item, 'double_JUROS'  , r.total_juros)
            this.$set(item, 'JUROS_MSG'     , textoJuros)
          }
          else{
            this.$set(item, 'double_TOTAL', this.edita_parcelas.double_VALOR)
          }


          // caso queria calcular vindo de um menu ou botao
          if (ja_salva){
            await this.salvar_tabela('conta', item ,'')
          }
          
      },



        async imprimir_recibo_up(tipo, dados_parcela, dados_cadastro, dados_servico){


            sessionStorage['recibo_tipo'] = tipo

            // Transformar o objeto em string e salvar em sessionStorage
            sessionStorage['recibo_dados_parcela']  =  JSON.stringify(dados_parcela)
            sessionStorage['recibo_dados_cadastro'] =  JSON.stringify(dados_cadastro)
            sessionStorage['recibo_dados_servico']  =  JSON.stringify(dados_servico)

            let route = this.$router.resolve({path: '/print_c'});
            window.open(route.href, '_blank');

        },

        imprimir_modelo(tipo, dados_parcela, dados_cadastro, dados_servico){

            sessionStorage['recibo_tipo'] = tipo


            // FILTRO PEGANDO SOMENTE AS PENDENTES
            let h = dados_parcela
            if (tipo == 'carne'){
                // h = dados_parcela.filter( x => x.PAGO == 'N' );
                h = dados_parcela.filter( x => x.PAGO == 'N' && x.TIPO == 'E'); //somente entradas e nao saidas
                // console.log(h);
            }



            // Transformar o objeto em string e salvar em sessionStorage
            // sessionStorage['recibo_dados_parcela']  =  JSON.stringify(dados_parcela)
            sessionStorage['recibo_dados_parcela']  =  JSON.stringify(h)
            sessionStorage['recibo_dados_cadastro'] =  JSON.stringify(dados_cadastro)
            sessionStorage['recibo_dados_servico']  =  JSON.stringify(dados_servico)

            let route = this.$router.resolve({path: '/print_c'});
            window.open(route.href, '_blank');

        },

        imprimir_contrato(tipo, servico, cadastro){
 
            //salvo no sessionStorage pq na session ele nao le quando abre em nova guia
 
            
           // Transformar o objeto em string e salvar em sessionStorage
           sessionStorage['print:dados_cadastro']  =  JSON.stringify(cadastro)
           sessionStorage['print:dados_parcelas']  =  JSON.stringify(this.items_parcelas)
           sessionStorage['print:dados_servicos']  =  JSON.stringify(servico)
           sessionStorage['print:dados_impressao']  =  ''


          // console.log('servico:', servico);
          // VEJO SE PEGOU O CONTRATO ASSOCIADO AO SERVIÇO..CASO CONTRARIO ABRO A JANELA DE MODELOS DE IMPRESSAO
           if (!servico.CONTRATO){
            console.log('NÃO ASSOCIOU CONTRATO AO SERVIÇO..ABRINDO MODELOS DE IMPRESSÃO');
            tipo = 'IMPRESSÃO'
           }

           let retorno =  this.replace_texto(tipo, servico, cadastro)

        //    sessionStorage['refresh_cadastro'] =  (tipo=='CONTRATO') ? 'S' : ''
 
           
           sessionStorage['print:get_tipo']       = tipo
           sessionStorage['print:get_codigo']     = servico.CODIGO
           sessionStorage['print:get_descricao']  = servico.DESCRICAO +' - '+servico.PROCESSO 
           sessionStorage['print:get_nome_aluno'] = cadastro.NOME
           
        
           sessionStorage['printConteudo']       = retorno
           sessionStorage['editar_impressao']    = 'N'
            
           sessionStorage['servico_ja_assinou']  = servico.JA_ASSINADO 
           sessionStorage['data_hora']           = this.data_hora
           
 
           let route = this.$router.resolve({path: '/print'});
           window.open(route.href, '_blank');
 
       },


       replace_texto(tipo, servico, cadastro){
 

        // console.log('replace_texto:', );
            // console.log('servico:');
            // console.log(servico);

            // console.log('cadastro:');
            // console.log(cadastro);
                // let texto = this.editor.getHTML()

                let texto = ''
                if (tipo=='CONTRATO'){
                    texto = servico.CONTRATO //PEGO DA TABELA SERVIÇO
                }
                

                //substituindo os campos coringas
                texto = texto.replaceAll('@empresa',        sessionStorage['emp_nome'])
                texto = texto.replaceAll('@razao_social',   sessionStorage['emp_razao'])
                texto = texto.replaceAll('@cnpj',           sessionStorage['emp_cnpj'])
                texto = texto.replaceAll('@endereco_emp',   sessionStorage['emp_endereco'])
                texto = texto.replaceAll('@fones',          sessionStorage['emp_fone1'] + '  '+sessionStorage['emp_celular'])
                texto = texto.replaceAll('@cidade',         sessionStorage['emp_cidade'])
                texto = texto.replaceAll('@data',           this.hoje_week )

                texto = texto.replaceAll('@ide',             'IDE: '+cadastro.CODIGO_ALUNO)
                texto = texto.replaceAll('@nome',            cadastro.NOME)
                texto = texto.replaceAll('@aluno',           cadastro.NOME)
                texto = texto.replaceAll('@rg',              cadastro.RG )
                texto = texto.replaceAll('@cpf',             cadastro.CPF)
                texto = texto.replaceAll('@nascimento',      cadastro.x_DATA_NASCIMENTO)
                texto = texto.replaceAll('@processo',        cadastro.PROCESSO)
                texto = texto.replaceAll('@endereco_al',     cadastro.ENDERECO+' '+cadastro.BAIRRO+' CEP:'+cadastro.CEP+' '+cadastro.CIDADE+' - '+cadastro.UF)

                texto = texto.replaceAll('@servico',         servico.DESCRICAO)
                texto = texto.replaceAll('@categoria',       servico.CATEGORIA)
                texto = texto.replaceAll('@valor_servico',   servico.x_double_TOTAL)

                if (texto.includes('@estrutura pagamento')){
                
                    // console.log(this.items_parcelas);
                    let parcelas = this.items_parcelas

                    let TITULO = ''
                    let DESC   = ''

                    TITULO = '<br>IDE:'+cadastro.CODIGO_ALUNO +'   -   PROCESSO: '+ servico.PROCESSO + '\n'+
                             'PLANO DE PAGAMENTO:'+ '<br>'

                    for (var i = 0; i < parcelas.length; i++) {

                        // console.log(parcelas);
                        if ((i == 0) && (parcelas[i].PAGO == 'S')){

                            let parcelado = Number(this.strtofloat(servico.double_TOTAL))   - Number(this.strtofloat(parcelas[i].double_TOTAL))

                            TITULO += 'VALOR TOTAL: '+ servico.x_double_TOTAL + ' | '+
                                      'ENTRADA: '+ parcelas[i].x_double_TOTAL + ' | '+
                                      'PARCELADO: R$ '+ this.getFormatCurrency(parcelado)+'<br>'

                        }else{
                            DESC += i +'ª parcela: '+ this.getFormatDate(parcelas[i].DATA_VENCIMENTO) + '   ' + parcelas[i].x_double_TOTAL + ' = '+parcelas[i].SERVICO_REFERENTE +'<br>'
                            
                        }

                    }

                    // console.log(TITULO);
                    TITULO += DESC

                    texto = texto.replaceAll('@estrutura pagamento', TITULO)
                }
                

                

                return texto

                // this.editor.commands.setContent(texto)

        },


        retorna_taxa(taxa, tipo_taxa, valor){

            taxa      = this.strtofloat(taxa)

            if (taxa > 0){

                let novo_valor = this.strtofloat(valor)
                let taxa_salvar = 0

                if (tipo_taxa == '%'){
                    taxa_salvar = ((novo_valor * taxa) / 100 )
                    novo_valor = novo_valor - taxa_salvar
                    // console.log('%:'+novo_valor);
                }else if (tipo_taxa == '$'){
                    taxa_salvar = taxa
                    novo_valor = novo_valor - taxa
                    // console.log('$:'+novo_valor);
                }

                novo_valor  = Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(novo_valor); //funciona para numeros
                taxa_salvar = Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(taxa_salvar); //funciona para numeros

                let retorno = {
                    'total' : novo_valor,
                    'taxa'  : taxa_salvar
                }

                return retorno

                }

        },

        async consultar_pix(item, id_pix, link, token){

            if (this.config_emp.PIX_SITUACAO == 'TESTE'){
                token = this.config_emp.PIX_TOKEN_TESTE
            }
            else if (this.config_emp.PIX_SITUACAO == 'PRODUÇÃO'){
                token = this.config_emp.PIX_TOKEN
            }

            link = this.config_emp.PIX_LINK

            id_pix = item.PIX_ID

            link = link+'/'+id_pix

            const header =   {
                 headers: {
                    // 'Content-Type': 'multipart/form-data'
                    // 'Authorization': `Basic ${token}`
                    'Authorization': `Bearer ${token}`
                }
            }

            const res = await axios.get(link, header)
            console.log(res.data);

            if (res.data.status == 'approved'){

                console.log('today:'+ this.today);

                let data_pago       = (res.data.date_last_updated) ? res.data.date_last_updated : this.today
                let valor_pago      = (res.data.transaction_amount) ? res.data.transaction_amount : 0
                let valor_taxas     = (res.data.fee_details.length>0) ? res.data.fee_details[0].amount : 0
                let valor_recebido  = (res.data.transaction_details.net_received_amount) ? res.data.transaction_details.net_received_amount : 0
                
                data_pago =  data_pago.substring(0,10)//copia somente a data.. sem a hora 
                
                console.log('vl pago:'+valor_pago);
                console.log('vl taxa:'+valor_taxas);
                console.log('vl recebido:'+valor_recebido);
                console.log('data pago:'+data_pago);
                // pagamento ok

                let sql = `update conta set 
                       pago = 'S', 
                       data_efetuado = '${data_pago}',
                       double_valor  = '${valor_pago}',
                       double_taxa   = '${valor_taxas}',
                       double_total  = '${valor_recebido}'
                       where codigo  = ${item.CODIGO}`
                
                       console.log(sql);


                await this.crud_sql(sql)
                await this.tabela('conta','update')//refresh
            }
            else {
                if (res.data.status == 'pending'){
                    alert('Pendente')
                }
            }
            

            // console.log(res.data);
            // console.log(res.data.status);
            // alert(res.data.status)
            
        },

        async gerar_varios_pix(){

        },

        async gerar_pix(dados_parcela, dados_aluno, link, token){


            //pega o id do aluno no asaas
            let id_aluno = await this.pix_id_aluno(link, token, dados_aluno)
            if(!id_aluno){
                alert('Não foi possível Buscar ou Cadastrar id_aluno!')
                return false
            }
            else{
                // alert('id_aluno: ' + id_aluno)
            }

            
    
            // return false

            //FAÇO ALGUMAS VERIFICAÇÕES ANTES
            let url         = link + 'payments'
            let juros_mes   = this.strtofloat(this.config_emp.JUROS)
            let multa       = this.config_emp.MULTA || 0

            if (juros_mes>0){ juros_mes =  juros_mes * 30}
            else{ juros_mes = 0 }


            if (this.today > dados_parcela.DATA_VENCIMENTO){
                alert('Esta parcela já esta Vencida.\nAltere a Data do Vencimento!')
                return false
            }

            // return false

            // ENVIO OS CAMPOS NECESSARIOS
            let body  =
                {
                    billingType         : 'UNDEFINED',
                    customer            : id_aluno,
                    value               : dados_parcela.double_TOTAL,
                    dueDate             : dados_parcela.DATA_VENCIMENTO,
                    description         : dados_parcela.PLANO+` (${dados_parcela.CODIGO}) ` +  dados_parcela.SERVICO_REFERENTE,
                    externalReference   : dados_aluno.CODIGO_ALUNO,
                    fine                : { value: multa },
                    interest            : { value: juros_mes },
                    postalService       : false
                }

                // this.config_emp
            let retorno = await DSiAsaas.sendGetAxios('POST', url, token, body)
            console.log('retorno::', retorno);

            if (retorno.id){
                // alert('Cobrança Gerada com Sucesso!'+retorno.id)


                // let pagina_retorno = retorno.point_of_interaction.transaction_data.ticket_url
                let pagina_retorno = retorno.invoiceUrl
                let id_pagamento   = retorno.id

                let sql = `update conta set 
                        pix_id       = '${id_pagamento}', 
                        pix_gerado   = '${this.today}',
                        pix_url      = '${pagina_retorno}'
                        where codigo =  ${dados_parcela.CODIGO}`
                console.log(sql);

                this.crud_sql(sql)

                return retorno

            }
            else{
                alert('Não foi possível Gerar a Fatura!')
            }


            
        },

        mudaVias(vias){
            
            this.objVias = []//limpa

            let obj = {}
            for (let i = 1; i <=vias; i++){
                this.$set(obj, 'n',i)
                this.objVias.push(obj)
            }
        },

        async carrega_dados(){

          this.urlLogo =  sessionStorage['emp_url_logo']

          this.$set(this.emp, 'nome',        sessionStorage.getItem('emp_nome'))
          this.$set(this.emp, 'razao',       sessionStorage.getItem('emp_razao'))
          this.$set(this.emp, 'fone1',       sessionStorage.getItem('emp_fone1'))
          this.$set(this.emp, 'fone2',       sessionStorage.getItem('emp_fone2'))
          this.$set(this.emp, 'celular',     sessionStorage.getItem('emp_celular'))
          this.$set(this.emp, 'cnpj',        sessionStorage.getItem('emp_cnpj'))
          this.$set(this.emp, 'cep',         sessionStorage.getItem('emp_cep'))
          this.$set(this.emp, 'endereco',    sessionStorage.getItem('emp_endereco'))
          this.$set(this.emp, 'cidade',      sessionStorage.getItem('emp_cidade'))
          this.$set(this.emp, 'uf',          sessionStorage.getItem('emp_uf'))
          this.$set(this.emp, 'email',       sessionStorage.getItem('emp_email'))

        

          //   ARRAY TO OBJECT
        //   let aa = []
        //   aa = await this.crud_empresa()
        //   this.config_emp = await aa[0]


          try { this.config_emp = JSON.parse(sessionStorage['config_emp']) }
          catch (e) {}

          try { this.config_pix = JSON.parse(sessionStorage['config_pix']) }
          catch (e) {}
          
          
        //   @rex
          this.podeFaturar = (this.config_pix) ? true : false

          
          
        },

        seleciona_linha(row) {
        //   console.log(row);
        //    this.selected= [{"CODIGO" : row.CODIGO}] //via v-modal no data-table

        //    this.item_menu = row

            if (this.selected?.length <= 1){

                this.selected  = [ Object.assign({}, row)] 

                this.obj_clicado = Object.assign({}, row)
            }
        },

        rightClick(event, item) {
          // console.log(event)
          // console.log(item)

          this.abre_menu(item.item)
          event.preventDefault()
        },

        async excluir_todas_parcelas(item){

            // console.log(item);console.log(this.pro_dados_servico.X_SOMAS[0].SUM_TOTAL);return false


            let total = this.pro_dados_servico.X_SOMAS[0].SUM_TOTAL
            let sql = 'delete from conta  where CODIGO_SERVICO = '+ item.CODIGO_SERVICO
            // console.log(sql);

            if (confirm("Deseja realmente Excluir todas as Parcelas ??")){
                
                await this.crud_sql(sql)
        
                await this.tabela('conta','update')//refresh

                await registra_log('FINANCEIRO', negritoO('EXCLUIU TODAS','red') + ` as PARCELAS (R$ ${total}) de ${this.pro_dados_aluno.NOME}:${this.pro_dados_aluno.CODIGO_ALUNO} de
                                     ${this.pro_dados_servico.DESCRICAO} Nº${this.pro_dados_servico.CODIGO}`, this.pro_dados_aluno.CODIGO_ALUNO)
            }

        },

        async imprimir(item){

            // console.log('item::::', item);
            await registra_log('FINANCEIRO', negritoO('IMPRIMIU RECIBO','blue') + ` Nº${item?.CODIGO} `+ negritoO(`(${item.x_double_TOTAL})`,false) +` de ${this.pro_dados_aluno.NOME}:${this.pro_dados_aluno.CODIGO_ALUNO}`, this.pro_dados_aluno.CODIGO_ALUNO)

            window.scrollTo(0, 0);
            window.print() //subir para nao dar erro de impressao
        },

        close(){
            this.dialog_print = false
        },

        async imprimir_recibo(item){


            // this.item_menu = item
            if (item){
              this.item_menu = Object.assign({}, item)
            }

            this.dialog_print = true

            this.mudaVias(this.nVias)

            // console.log('imprimir_recibo:');
            // console.log(item); 
            // console.log('pro_dados_aluno:');
            // console.log(this.pro_dados_aluno);
        },

        abre_menu(item){
            // console.log('5 abre_menu:', item);

            this.dialog_menu = true
            // this.item_menu = item
            this.item_menu = Object.assign({}, item)

            this.$set(this.item_menu   , 'X_NOME', this.pro_dados_aluno.NOME )//ADICONO NOME NO CAMPO CALCULADO


            this.seleciona_linha(item)
        },

        mesmo_valor(){
            if (this.checkbox_parcial){
                this.total_recebido     = null //this.edita_parcelas.double_TOTAL
                this.prorrogar_parcial  = 30 //dias 
            }
            else{
                this.total_recebido = this.edita_parcelas.double_TOTAL
            }
        },

        async efetuar(){
            
            this.data_hora_agora()

            let T = this.strtofloat(this.edita_copia.double_TOTAL)            
            let P = this.strtofloat(this.total_recebido)
            let R = 0
            R = T - P

            
            let dias = parseInt( this.prorrogar_parcial)//converte string to integer
            let plano = this.edita_parcelas.PLANO

            // alert(this.total_recebido)
            // return 'exit'

            if (R>0){//parcial entao zera juros e multa e coloca o valor = valor recebido..
              this.$set(this.edita_parcelas , 'double_VALOR', this.total_recebido )
              this.$set(this.edita_parcelas, 'double_JUROS', 0 )
              this.$set(this.edita_parcelas, 'double_TAXA', 0 )
            }
            else{
                this.$set(this.edita_parcelas, 'double_JUROS', this.edita_copia.double_JUROS )
                this.$set(this.edita_parcelas, 'double_TAXA', this.edita_copia.double_TAXA )
            }
            this.$set(this.edita_parcelas , 'double_TOTAL', this.total_recebido )
            

            this.$set(this.edita_parcelas , 'PAGO', 'S' )
            this.$set(this.edita_parcelas , 'QUEM_EFETUOU', this.quem_alterou )
            this.$set(this.edita_parcelas , 'QUEM_ALTEROU', this.quem_alterou )

            if (R>0){//parcial
              this.$set(this.edita_parcelas, 'PLANO',  plano+'¹')
            }


            //copia para imprimir, imprimo aqui no final
            this.obj_imprimir = Object.assign({}, this.edita_parcelas)
            

            this.salvar_tabela('conta', this.edita_parcelas,'dialog_efetuar', this.item_menu)

            //gera parcela parcial
            if ( (this.checkbox_parcial) && (R>0) ) {

                this.edita_parcelas = this.edita_copia//copio os dados originais

                //altero alguns dados
                R = Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(R); //funciona para numeros
                this.$set(this.edita_parcelas, 'double_VALOR', R )
                this.$set(this.edita_parcelas, 'double_TOTAL', R )
                this.$set(this.edita_parcelas, 'double_JUROS', 0 )
                this.$set(this.edita_parcelas, 'double_TAXA', 0 )

                this.$set(this.edita_parcelas, 'PLANO', plano+'²')

                this.$set(this.edita_parcelas, 'CODIGO', 0 )
                this.$set(this.edita_parcelas, 'DATA_VENCIMENTO', this.incDay(this.today, dias,'us') )



                await this.tabela_create('conta', this.edita_parcelas)
            }

            // if (confirm(`Imprimir Recibo?`)){
            if (this.checkbox_recibo){
                await this.imprimir_recibo(this.obj_imprimir)
            }

            //atualiza o selected atualiza a selecao
            if (Object.keys(this.obj_recibo_unico).length === 0){
                setTimeout(async () => {
                   await this.atualizaSelecao('items_parcelas');
                }, 999);
            }

        },

        estornar(item){

            if (confirm("Deseja realmente Estornar o código  "+item.CODIGO+' - R$ '+item.double_TOTAL+' ?')){

                this.data_hora_agora()

                let item_menu_here   = Object.assign({}, item)//copio para nao alterar o original

                item_menu_here.PAGO            = 'N'
                item_menu_here.DATA_EFETUADO   = '0000-00-00'
                item_menu_here.QUEM_EFETUOU    = this.quem_alterou
                item_menu_here.QUEM_ALTEROU    = this.quem_alterou

                this.salvar_tabela('conta', item_menu_here,'', this.item_menu)


                //atualiza o selected atualiza a selecao
                if (Object.keys(this.obj_recibo_unico).length === 0){
                    setTimeout(async () => {
                    await this.atualizaSelecao('items_parcelas');
                    }, 999);
                }
            }
        },

        abre_efetuar(item){

        //    this.item_menu = item
           this.item_menu = Object.assign({}, item)

           this.checkbox_parcial = false 
           this.checkbox_juros = (this.strtofloat(item.double_JUROS)>0)

           this.editar_tabela(item, 'edita_parcelas', 'dialog_efetuar')

           //definindo alguns valores 
           this.total_recebido          = item.double_TOTAL
           this.edita_parcelas.DATA_EFETUADO   = this.today

           //faço uma copia em caso de parcial
        //    this.edita_copia = item

           //copiando objetos 
           this.edita_copia = Object.assign({}, item)
           this.obj_valores = Object.assign({}, item)


        },

        manda_emit(acao,a,b,c){
            this.$emit('emit_executa',acao,a,b,c)//mando atualizar as somas 
            this.dialog_ad_exame = false
        }, 



        digitou_servico(event){

            // console.log(event);
            // console.log('combobox_servico:', this.combobox_servico);
            let valor_digitou = event.target._value

            // console.log('event.target._value:', event.target._value);
            // console.log('valor_digitou:', valor_digitou);



            // limpa o timer, reseta
            clearTimeout(this.timer_digitou_servico);
            
            this.timer_digitou_servico = 
                setTimeout(function(){
                
                    console.log('timer_digitou_servico:');
                    this.seleciona_servico(valor_digitou)
                
                }.bind(this), 333);//1100
        },
        

        seleciona_servico(item){
            // console.log('seleciona_servico:', item);
            // console.log(this.edita_servico);
            // console.log('item:', item);


            if(item){
                //verifica se é um objeto e tem uma determinada propriedade    
                if (item.hasOwnProperty('double_TOTAL')){
                    //É UM OBJETO
                    this.$set(this.edita_gerar_parcelas, 'TOTAL', item.double_TOTAL)
                    this.$set(this.edita_gerar_parcelas, 'SERVICO_REFERENTE', item.DESCRICAO)
                }else{
                    //NÃO É UM OBJETO
                    //default
                    this.$set(this.edita_gerar_parcelas, 'TOTAL', '')
                    this.$set(this.edita_gerar_parcelas, 'SERVICO_REFERENTE', item.toUpperCase())
                    // a = a.toUpperCase();
                }
            }
            else{
                this.$set(this.edita_gerar_parcelas, 'TOTAL', '')
                this.$set(this.edita_gerar_parcelas, 'SERVICO_REFERENTE', '')
            }
        },


        //---------------importando funcoes basicas ----------------------- INICIO
        
        firstUpper(event, field){
            // console.log(event);
            let a = event.target.value;//captura o valor do event

            if (a!=='' && a.length==1){  
            a = a.toUpperCase();
            // this.item_edita[field] = a;

            event.target.value = a
            } 
        },

        moeda(event){
            return DSibasico.moeda(event)
        },

        allUpper(event, obj_name, field){
            this[obj_name][field] = DSibasico.allUpper(event, obj_name, field)
        },

        async data_hora_agora(){
        // define_data_hora(){
            DSibasico.define_data_hora(this)//passo o this para que la ele defina as variaveis daqui
        },

        incMonth(data,meses,pt){
            return DSibasico.incMonth(data,meses,pt)
        },

        incDay(data,dias,pt){
            return DSibasico.incDay(data,dias,pt)
        },

        strtofloat(vem){
            return DSibasico.strtofloat(vem)
        },

        floatTostring(vem){
            return DSibasico.floatTostring(vem)
        },

        floatTostring2(vem){
            return DSibasico.floatTostring2(vem)
        },

        getFormatDate(data){
            return DSibasico.getFormatDate(data)
        },

        getFormatCurrency(v,string){
          return DSibasico.getFormatCurrency(v, string)
        },

        alerta(text,color){
            DSibasico.alerta(this, text, color)
        },

        //---------------importando funcoes basicas ----------------------- FIM



        //---------------importando funcoes sql ----------------------- INICIO

        //FICA NO BOTAO SALVAR.. VERIFICA SE UPDATE OU CREATE
        async salvar_tabela(tabela, obj_edita, dialog_name, obj_before){

            let before  = Object.assign({}, obj_before);//faço uma copia do original
            return await DSisql.salvar_tabela(this,tabela, obj_edita, dialog_name, before)
        },

        async tabela_update(tabela, obj_edita, obj_before){
            let r =   await DSisql.tabela_update(this, tabela, obj_edita, obj_before)
            this.manda_emit('update_somas', this.checkbox_so_entradas)

            return r
        },

        async tabela_create_no_refresh(tabela, obj_edita){
            // console.log('tabela_creat:',obj_edita);
            await DSisql.tabela_create_no_refresh(this, tabela, obj_edita)
            this.manda_emit('update_somas', this.checkbox_so_entradas)
        },

        async tabela_create(tabela, obj_edita){
            // console.log('tabela_creat:',obj_edita);
            let r = await DSisql.tabela_create(this, tabela, obj_edita)
            this.manda_emit('update_somas', this.checkbox_so_entradas)
            
            return r
        },

        //EXECUTAR UM SQL TIPO UPDATE
        async crud_sql (sql) {
            let r = await DSisql.crud_sql(this, sql)
            this.manda_emit('update_somas', this.checkbox_so_entradas)

            return r
        },

        //ABRE OS DADOS DA TABELA
        async crud_abrir_tabela(sql, items_name){
            await DSisql.crud_abrir_tabela(this, sql, items_name)
        }, 

        editar_tabela(item, obj_edita_name, dialog_name) {
            DSisql.editar_tabela(this, item, obj_edita_name, dialog_name)
        },
        
        async remover_tabela(tabela, obj_edita, titulo){
           await DSisql.remover_tabela(this, tabela, obj_edita, titulo)
        },


        //---------------importando funcoes sql ----------------------- FIM



        open_tabela_contas(){
            let sql = 'select * from tabela_contas'
            this.crud_abrir_tabela(sql, 'items_tabela_contas' )
        },

        open_tabela_formas(){
            let sql = 'select DESCRICAO from tabela_formas order by DESCRICAO'
            this.crud_abrir_tabela(sql, 'items_tabela_formas' )
        },


        async tabela(tabela,acao){
            this.tabela_selecionada = tabela
            // this.card_titulo = item.text

            if (tabela=='conta'){
                if ( (acao == 'create') || (acao == 'delete') || (acao == 'update') ){
                   await this.abrir_parcelas(this.pro_ide,this.pro_cod_servico)
                }
            }
        },


        refresh(){

            this.data_hora_agora()
            // this.define_data_hora()
            this.open_tabela_contas()
            this.open_tabela_formas()
            this.abrir_parcelas(this.pro_ide,this.pro_cod_servico)

            // this.abrir_parcelas(this.ide,this.cod_servico)

            // if (this.pro_dados_servico.SITUACAO !== 'ATIVO'){
            if (!['ATIVO','APROPRIADO','PRE-CADASTRO'].includes(this.pro_dados_servico.SITUACAO)){
                this.permissoes.gerarparcelas = false
            }
            // console.log('pro_dados_aluno');
            // console.log(this.pro_dados_aluno);

            // console.log('pro_dados_servico');
            // console.log(this.pro_dados_servico);
        },

        seleciona_conta_referente(){

            let CODIGO =  this.edita_parcelas.CODIGO_CONTA.CODIGO
            let DESCRICAO =  this.edita_parcelas.CODIGO_CONTA.DESCRICAO
            
            this.$set(this.edita_parcelas, 'CODIGO_CONTA',     CODIGO )
            this.$set(this.edita_parcelas, 'CONTA_REFERENTE', DESCRICAO)
        },

        seleciona_forma_pagamento(){

            // console.log('this.edita_parcelas.FORMA:');
            // console.log(this.edita_parcelas.FORMA);

            // if (0>1){
                // TERMINAR ISSO DAQUI
        
                    // let TAXA    =  this.edita_parcelas.FORMA_PAGAMENTO.double_TAXA
                    // let FORMA   =  this.edita_parcelas.FORMA_PAGAMENTO.DESCRICAO
                    let FORMA   =  this.edita_parcelas.FORMA_PAGAMENTO

                    
                    // this.$set(this.edita_parcelas, 'double_TAXA',     TAXA )
                    this.$set(this.edita_parcelas, 'FORMA_PAGAMENTO', FORMA)

                    // console.log(this.edita_parcelas);
            // }
        },

        digitou_entrada(item) {
            // console.log('item:');
            // console.log(item);

            //procura o valor dentro de um array de objetos
            let tem = this.valores_digitou.find( quero => quero.CODIGO === item.CODIGO )
            if (tem){
            //   console.log('tem:');
            //   console.log(tem);
              //EDITA
              tem.TOTAL = item.TOTAL
            }
            else{
                // console.log('vazio');
                //INSERE    
                //DEFINE OS VALORES DO OBJETO 
                let obj = {
                    "CODIGO" : 0,
                    "TOTAL" : 0,
                }
                this.$set(obj, 'CODIGO',  item.CODIGO)
                this.$set(obj, 'TOTAL',   item.TOTAL)

                this.valores_digitou.push(obj)
            }

            // console.log('valores_digitou:');
            // console.log(this.valores_digitou);
    

            // clear timeout variable
            clearTimeout(this.timer_digitou);
            
            this.timer_digitou = 
                setTimeout(function(){
                
                this.multiplica()
                
                }.bind(this), 1500);//usar esse .bind(this) para chamar funcoes com o setTimeout
        },


        getVencido(hoje, data){

            if (hoje > data ){
                // console.log(hoje +' >venceu '+data);
                return 'orange' //red
            }
            else if (hoje == data ){
                // console.log(hoje +' = '+data);
                return 'orange'
            }
            else{
                // console.log(hoje +' < '+data);
                return 'black'
            }
        },


        async gerar_parcelas(){

            this.data_hora_agora()

            // console.log('gerar_parcelas - def_parcelas:');
            // console.log(this.def_parcelas); 

            if (this.$refs.form.validate()){

                if ((this.edita_gerar_parcelas.TOTAL<=0)){
                    this.alerta('Defina um Valor Válido!','red')

                    //LIMPO O LOG APOS O EXIT
                    setTimeout(function(){
                        console.clear()
                    }.bind(this), 555)

                    exit
                }


                this.overlay = true

                let qt = this.def_parcelas.length
                for (let i = 0; i < qt; i++){
                    
        
                    let dados_servico = this.pro_dados_servico
                    // let dados_parcela = this.def_parcelas[i]
                    let copia = this.def_parcelas.map((x) => x); //clonando array copiar array
                    let dados_parcela = copia[i]
                    // this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array
                    // let plano = i+1+'/'+qt
                    let plano = i+1+'/'+qt

                    if (this.checkbox_entrada){
                        if (i == 0){
                            plano = '0'
                        }
                        else{
                            plano = i+'/'+(qt-1)
                        }
                    }

                    this.$set(this.edita_parcelas, 'CODIGO_ALUNO',    dados_servico.CODIGO_ALUNO )
                    this.$set(this.edita_parcelas, 'CODIGO_SERVICO',  dados_servico.CODIGO )

                    this.$set(this.edita_parcelas, 'DATA_VENDA',          this.today )
                    this.$set(this.edita_parcelas, 'DATA_VENCIMENTO',     dados_parcela.DATA_VENCIMENTO )
                    this.$set(this.edita_parcelas, 'DATA_EFETUADO',       dados_parcela.DATA_EFETUADO )
                    if( (dados_parcela.DATA_EFETUADO == '') || (dados_parcela.DATA_EFETUADO == null)){
                        this.$set(this.edita_parcelas, 'PAGO',      'N' )
                        this.$set(this.edita_parcelas , 'QUEM_EFETUOU', '' )
                    }else{
                        this.$set(this.edita_parcelas, 'PAGO',      'S' )
                        this.$set(this.edita_parcelas ,'QUEM_EFETUOU', this.quem_alterou )
                    }

                    this.$set(this.edita_parcelas, 'double_VALOR',       dados_parcela.TOTAL )
                    this.$set(this.edita_parcelas, 'double_JUROS',       0 )
                    this.$set(this.edita_parcelas, 'double_TOTAL',       dados_parcela.TOTAL )
                    this.$set(this.edita_parcelas, 'SERVICO_REFERENTE',  dados_parcela.SERVICO_REFERENTE )
                    this.$set(this.edita_parcelas, 'OBSERVACAO',         '' )
                    // this.$set(this.edita_parcelas, 'OBSERVACAO',         this.pro_dados_aluno.NOME )
                    // this.$set(this.edita_parcelas, 'FORMA_PAGAMENTO',    dados_parcela.FORMA.DESCRICAO )
                    this.$set(this.edita_parcelas, 'FORMA_PAGAMENTO',    dados_parcela.FORMA )


                    this.$set(this.edita_parcelas, 'CODIGO_CONTA',       this.edita_gerar_parcelas.CODIGO_CONTA.CODIGO)
                    this.$set(this.edita_parcelas, 'CONTA_REFERENTE',   this.edita_gerar_parcelas.CODIGO_CONTA.DESCRICAO)

                    //DEFAULT
                    this.$set(this.edita_parcelas, 'VISTO',          'N' )
                    
                    this.$set(this.edita_parcelas, 'TIPO',           this.edita_gerar_parcelas.TIPO )
                    if (this.edita_gerar_parcelas.TIPO == 'E'){
                        this.$set(this.edita_parcelas, 'CATEGORIA'  ,    'RECEBIMENTO' )
                    }else{
                        this.$set(this.edita_parcelas, 'CATEGORIA'  ,    dados_parcela.SERVICO_REFERENTE  )
                    }

                    this.$set(this.edita_parcelas, 'PLANO'  ,         plano)
                    this.$set(this.edita_parcelas, 'QUEM_ALTEROU'  , this.quem_alterou )

                    
                    // console.log('edita_parcelas:', this.edita_parcelas);
                    // alert(this.edita_parcelas.double_TOTAL)

                   await this.tabela_create_no_refresh('conta', this.edita_parcelas)

                //    await this.tabela_create('conta', this.edita_parcelas)//metodo antigo..ficava atualiznado...
                }


                await this.tabela('conta','create')//close open
                // await this.tabela('conta','update')//refresh

                // alert(qt)
                let ss = ``
                if (this.edita_gerar_parcelas.TIPO == 'E'){ 
                    if (qt == 1){
                        ss = negritoO('ADICIONOU PARCELA', 'blue') + ' de '
                    }
                    else{
                        ss = negritoO('GEROU PARCELAS', 'blue') + ' de '
                    }
                }
                else { 
                    ss = negritoO('PAGOU', 'red')
                 }

                

                await registra_log('FINANCEIRO', `${ss}  (R$ ${negritoO(this.edita_gerar_parcelas.TOTAL)} em ${qt}x) de ${this.pro_dados_aluno.NOME}:${this.pro_dados_aluno.CODIGO_ALUNO} - ${negritoO(this.edita_gerar_parcelas.SERVICO_REFERENTE)} Sº${this.pro_dados_servico.CODIGO}`, this.pro_dados_aluno.CODIGO_ALUNO)
                // await registra_log('FINANCEIRO', `${ss}  (R$ ${negritoO(this.edita_gerar_parcelas.TOTAL)} em ${qt}x) de ${this.pro_dados_aluno.NOME}:${this.pro_dados_aluno.CODIGO_ALUNO} - ${this.pro_dados_servico.DESCRICAO} Nº${this.pro_dados_servico.CODIGO}`, this.pro_dados_aluno.CODIGO_ALUNO)
                // await registra_log('FINANCEIRO', `${(qt == 1 ? negritoO('ADICIONOU PARCELA','blue') : negritoO('GEROU PARCELAS','blue'))}  de  (R$ ${this.edita_gerar_parcelas.TOTAL} em ${qt}x) de ${this.pro_dados_aluno.NOME}:${this.pro_dados_aluno.CODIGO_ALUNO} - ${this.pro_dados_servico.DESCRICAO} Nº${this.pro_dados_servico.CODIGO}`, this.pro_dados_aluno.CODIGO_ALUNO)

                this.overlay = false
                this.dialog_gerar_parcela=false
            }else{
                this.alerta('Preencha os campos necessários!','red')
            }
        },

        multiplica(){

            let qt   = this.edita_gerar_parcelas.PARCELAS
            let item = this.edita_gerar_parcelas
            
            // console.log('edita_gerar_parcelas:');
            // console.log(item); 
            
            // console.log('this.def_parcelas:');
            // console.log(this.def_parcelas);
            
            this.def_parcelas = []//limpa obj
            for (var member in this.def_parcelas)  this.def_parcelas[member]= ''; //limpando os campos do objeto 
            
            // console.log(this.def_parcelas);

            let B = this.strtofloat(item.TOTAL)//PEGANDO VALOR TOTAL
            let TOTAL = this.strtofloat(item.TOTAL)//PEGANDO VALOR TOTAL
            // console.log('TOTAL:'+TOTAL);
            // console.log(item.TOTAL);


            let entrada = 0
            this.inicio = 0
            let inicio2 = 1
            if (this.checkbox_entrada){
                this.inicio = 1 
                inicio2 = 0
            }


            //CALCULA O VALOR DIVIDIDO
            let entrada_string = Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(entrada); //funciona para numeros
            
            let V = parseInt(qt)+ parseInt(this.inicio)   
            let vezes = V
            // console.log('vezes:'+vezes);
            // console.log('V:'+V);

            let SOMA = 0
            let ULTIMO = 0
            let erro = false

            let obj = null
            for (let i = (1-this.inicio); i <= qt; i++) { //PERCORRE E PREENCHE ARRAY DE OBJETO

            // console.log('vezes:'+vezes);
            
            if (this.multi){
                B = TOTAL
            }else{
                B = (TOTAL - SOMA) / vezes
            }
            
            ULTIMO = B //ultmo valor digitado..
            // console.log('...............');
            // console.log('TOTAL - SOMA / VEZES:');
            // console.log(TOTAL +' - '+SOMA +'/'+ vezes + ' = '+B);
            // console.log('ULTIMO:'+ULTIMO);
            
            
            //procura o valor dentro de um array de objetos
            let tem = this.valores_digitou.find( quero => quero.CODIGO === i )
            if (tem){
                //   B = (tem.TOTAL)//pega valor digitado
                  B = this.strtofloat(tem.TOTAL)//pega valor digitado
                  if (B==''){
                      B = 0
                  }
                //   console.log('ACHOU B:'+B)
            }

            // console.log('B:'+B);
            if  ( parseFloat(B) > (vezes * ULTIMO)) {
                erro = true
            }
            if  ( parseFloat(B) == (vezes * ULTIMO)) {
                if (vezes>1){
                   erro = true
                }
            }
            if ( ( parseFloat(B) == 0 ) || (TOTAL == SOMA) ) {
                erro =  true
            }
            if ( (vezes==1) && (B<ULTIMO) ){
                erro = true
            }
            if (erro){
                // console.log('volta ao ultimo:'+vezes);
                this.alerta('Ajustando Valores!','orange')
                B = ULTIMO
            }


            if ((tem) && (erro)){            
                  tem.CODIGO = null //pega valor digitado
                  console.log('ERRO ENTAO LIMPANDO')
            }
            
            
            SOMA += parseFloat(B) //soma o valor que ja foi definido
            vezes -= 1


            //formato 
            // B = B.toLocaleString('pt-br',{ minimumFractionDigits: 2, maximumFractionDigits: 2 });//funciona pra string
            let B = Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(B); //funciona para numeros

            
            

                //DEFINE OS VALORES DO OBJETO
                obj = {
                    "CODIGO" : i,
                    "TOTAL"  : B,
                    "FORMA"  : item.FORMA,
                    "TIPO"   : item.TIPO,
                    // "FORMA":{
                    //     "CODIGO"      :item.FORMA.CODIGO,
                    //     "DESCRICAO"   :item.FORMA.DESCRICAO,
                    //     "double_TAXA" :item.FORMA.double_TAXA,
                    //     "TIPO_TAXA"   :item.FORMA.TIPO_TAXA,
                    // },
                    "DATA_VENCIMENTO"  : this.incMonth(item.DATA_VENCIMENTO,i - inicio2,'us'),
                    "DATA_EFETUADO"   : '',
                    "SERVICO_REFERENTE"   : item.SERVICO_REFERENTE
                }

                //ALGUMAS VERIFICACOES 
                if (item.EFETUAR == 'HOJE'){
                     this.$set(obj, 'DATA_EFETUADO',  this.today)
                }
                else if (item.EFETUAR == 'NO VENCIMENTO'){     
                     this.$set(obj, 'DATA_EFETUADO',  this.incMonth(item.DATA_VENCIMENTO,i,'us'))
                }

                //entrada
                if ( (i== (1-this.inicio)) && (V>0) ){
                     if (this.checkbox_entrada){
                        this.$set(obj, 'DATA_EFETUADO',  item.DATA_VENCIMENTO)
                     }

                     if (entrada>0){
                        this.$set(obj, 'TOTAL',  entrada_string)
                     }
                }

                //INSERE NO ARRAY OS OBJETOS
                this.def_parcelas.push(obj)
            }

            // console.log('def_parcelas:');
            // console.log(this.def_parcelas);
        },

        async mais_parcelas(tipo){
        // console.log('mais_parcelas:');
        // console.log(this.pro_dados_servico);

        this.multi = false 

        this.valores_digitou = []//limpa array 


        for (var member in this.edita_gerar_parcelas)  this.edita_gerar_parcelas[member]= ''; //limpando os campos do objeto 
        
        // this.$set(this.edita_gerar_parcelas, 'FORMA',              'DINHEIRO'  )
        // this.$set(this.edita_gerar_parcelas, 'FORMA',              'DINHEIRO  )
        this.$set(this.edita_gerar_parcelas, 'DATA_VENCIMENTO',    this.today  )
        this.$set(this.edita_gerar_parcelas, 'TIPO',               tipo  )
        this.$set(this.edita_gerar_parcelas, 'PARCELAS',           '1'  )
        this.$set(this.edita_gerar_parcelas, 'CODIGO_SERVICO',     this.pro_dados_servico.CODIGO  )
        this.$set(this.edita_gerar_parcelas, 'CODIGO_CLIENTE',     this.pro_dados_servico.CODIGO_ALUNO )
        this.$set(this.edita_gerar_parcelas, 'CODIGO_CONTA',       {CODIGO:'1', DESCRICAO:'CFC'}  )



        if (tipo == 'S'){
            this.$set(this.edita_gerar_parcelas, 'TOTAL',     '' )
            this.$set(this.edita_gerar_parcelas, 'EFETUAR',   'HOJE')
            this.checkbox_entrada = false
        }
        else if (this.primeira_parcela){

            this.checkbox_entrada = true
            this.$set(this.edita_gerar_parcelas, 'EFETUAR',              'PENDENTE'  )
            this.$set(this.edita_gerar_parcelas, 'TOTAL',                this.pro_dados_servico.double_TOTAL  )
            this.$set(this.edita_gerar_parcelas, 'SERVICO_REFERENTE',    this.pro_dados_servico.DESCRICAO  )

        }else{
            this.$set(this.edita_gerar_parcelas, 'EFETUAR',   'HOJE'  )
            // this.$set(this.edita_gerar_parcelas, 'TOTAL',     '0'  )
        }
        

        // CARREGO DA LISTA DE SERVIÇOS
        this.combobox_servico = null//deixo como null pois estou usando ele tanto commo objeto quanto texto..
        // let sql = "select * from tabela_servicos order by nivel"
        let sql = "select * from tabela_servicos where nivel <> 'PRIMARIO' order by nivel"
        this.crud_abrir_tabela(sql, 'items_tabela_servico' )

        this.dialog_gerar_parcela = true

        this.multiplica()
        },


        async abrir_parcelas(ide,servico){

            // console.log(servico);
            // console.log('abrir_parcelas:');
            let tipo       = ''    
            let sqlservico = ''    
            if (servico>0){
                sqlservico = ' and CODIGO_SERVICO = '+servico
            }

            if (this.checkbox_so_entradas){
                tipo = ` tipo = 'E' and `
            }

            // let sql = 'select * from conta where  codigo_aluno = '+ide+ sqlservico+' order by TIPO, DATA_VENCIMENTO, codigo'
            let sql = `select * from conta where  ${tipo} codigo_aluno = ${ide} ${sqlservico} order by TIPO, DATA_VENCIMENTO, codigo`
            // console.log(sql);
            await this.crud_abrir_tabela(sql, 'items_parcelas' )
            

            if (this.showJuros){
                await this.achar_atrasadas(this.items_parcelas)
            }


            //verifica se ja tem parcelas geradas..para mostrar ou nao alguns campos em gerar parcelas
            setTimeout(function(){
                this.primeira_parcela = this.items_parcelas.length<=0
                this.checkbox_entrada = this.primeira_parcela
                // this.primeira_parcela = true
                // console.log(this.primeira_parcela);
            }.bind(this), 555);//usar esse .bind(this) para chamar funcoes com o setTimeout 
            


            this.items_header = []//limpa
            this.items_header = [
                { text: 'CODIGO', value: 'CODIGO' },
                { text: 'Visto', value: 'VISTO',},
                { text: 'Tipo', value: 'TIPO', width:'100px'},
                { text: 'Conta Referente', value: 'CONTA_REFERENTE'},
                { text: 'SEQ', value: 'PLANO',},
                { text: '(=) Valor', value: 'double_VALOR', width:'120px'},
                { text: '(+) Juros', value: 'double_JUROS', width:'100px'},
                { text: '(-) Taxa', value: 'double_TAXA'},
                { text: '(=) Total', value: 'double_TOTAL',width:'120px'},

                { text: 'Vencimento', value: 'DATA_VENCIMENTO'},
                { text: 'Recebido', value: 'DATA_EFETUADO',width:'90px'},
                
                { text: '..', value: '..',width:'60px' },

                // { text: 'Forma', value: 'FORMA_PAGAMENTO', width:'130px'},
                // { text: 'Juros Texto', value: 'JUROS_MSG', width:'190px'},
                
                
                // { text: 'Pago', value: 'PAGO',width:'60px'},
                
                // { text: 'Categoria', value: 'CATEGORIA', width:'260px'},
                { text: 'Referente', value: 'SERVICO_REFERENTE', width:'590px'},//width:'260px'
                // { text: 'Anotação', value: 'OBSERVACAO', width:'260px'},
                // { text: 'Anotações', value: 'OBSERVACAO'},
                // { text: 'C.A', value: 'CODIGO_ALUNO' },
                // { text: 'C.S', value: 'CODIGO_SERVICO' },
                { text: 'Alterou', value: 'QUEM_ALTEROU',width:'230px' },
                { text: 'Efetuou', value: 'QUEM_EFETUOU',width:'230px' },

                { text: 'PIX ID', value: 'PIX_ID' },
                { text: 'PIX_GERADO', value: 'PIX_GERADO' },
                { text: 'PIX_URL', value: 'PIX_URL' },
                // { text: 'double_TAXA', value: 'double_TAXA' },
                { text: '.', value: '.' },
            ]

                // removo os campos que nao quero visivel somente para edicao
                // if (this.souDSi == false){
                if (this.podeFaturar == false){

                    this.items_header.splice(this.items_header.findIndex(x => x.value === "PIX_ID") , 1);//removo a coluna senha
                    this.items_header.splice(this.items_header.findIndex(x => x.value === "PIX_GERADO") , 1);//removo a coluna senha
                    this.items_header.splice(this.items_header.findIndex(x => x.value === "PIX_URL") , 1);//removo a coluna senha
                }

                // arrumar isso daqui em breve
                if (sessionStorage['conf_mostrar_juros'] != 'SIM'){
                    this.items_header.splice(this.items_header.findIndex(x => x.value === "double_VALOR") , 1);
                    this.items_header.splice(this.items_header.findIndex(x => x.value === "double_JUROS") , 1);
                    this.items_header.splice(this.items_header.findIndex(x => x.value === "double_TAXA") , 1);
                }

                // console.log('items_parcelas:')
                // console.log(this.items_parcelas);
        },


        async crud_empresa(){
           let r =  await DSisql.crud_empresa(this)
           return r
        },

    }

}
</script>

<style scoped>


@page:first {
   margin-top: 1cm !important;           
} 


  .container{
    max-width: 100%;
  }


.inline{
  display: inline  !important;
}

.cap{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
}

.none{
  font-weight: normal;
  /* font-weight (bold, bolder, lighter, normal e 100-900 */
}

.h7{
  font-size: 9px !important;
}

.sem_borda{
     /* sem borda */
    border: none !important;
    box-shadow: none !important;
}



@media print {
  .no-print {
    display: none !important;
  }

  @page {
    margin-left: mm !important; /* Margem esquerda de 20mm */
    margin-right: 5mm !important; /* Margem direita de 10mm */
  }

}


/* @media print {
  body * {
    visibility: hidden;
    display: none; 
  }
  .no-print {
    display: none !important;
  }
  .print {
    visibility: visible;
    display: block; 
  }
} */

.theme--light.v-data-table tbody tr.v-data-table__selected {
    /* seleciona_linha cor */
    background: #eee9e9 !important;
}

.count_item {
  position: relative;
  top: 0px;
  font-size: 90%;
  vertical-align: super;
  color: red;
}


.fs-8{
    font-size: 8px !important;
  }
  .fs-10{
    font-size: 10px !important;
  }
  .fs-11{
    font-size: 11px !important;
  }
  .fs-12{
    font-size: 12px !important;
  }
  .fs-14{
    font-size: 14px !important;
  }
  .fs-16{
    font-size: 16px !important;
  }
  .fs-18{
    font-size: 18px !important;
  }



  .v-data-table >>> tr>td{
    font-size:12px !important;
    height: 25px !important;

  }
  
 
 
  /* padding das colunas data-table  */ 
 .v-data-table >>> td.text-start{
  padding-left: 5px !important;
  /* padding-right: 2px !important; */
  /* padding: 0px 2px !important; */
  /* background-color: red !important; */
}
  
  /* padding dos titulos das colunas data-table */
  .v-data-table >>> th.text-start{
    padding: 0px 4px !important;
    /* background-color: red !important; */
    
  }



</style>

<template>
  <!-- <div @mousedown="ver_se_logado()"> -->
    <div v-if="can_show">


      <!-- items_editaveis: {{items_editaveis}} <br/> -->
      <!-- checkbox_so_entradas: {{checkbox_so_entradas}} <br/> -->
      <!-- zapzap_mostrar: {{zapzap_mostrar}} <br/> -->
      <!-- zapzap_conectado: {{zapzap_conectado}}<br/> -->

      <!-- carregou_cliente {{carregou_cliente}} <br/> -->
      <!-- {{obj_dados_cliente}} <br/> -->
      <!-- niver_model: {{niver_model}} <br/><br/> -->
      <!-- items_niver: {{items_niver}} <br/><br/> -->
      <!-- {{config_pix}} -->
      <!-- {{tabela_selecionada}} -->
      <!-- value_expansion_2: {{value_expansion_2}} <br/><br/> -->
      <!-- expansion_selecionao:{{expansion_selecionao}}<br/><br/> -->
      <!-- {{qual_impressao}} -->
      <!-- {{items_alunos_imprimir}} -->
      <!-- {{combobox_servico}} -->
      <!-- {{obj_dados_empresa}} -->
      <!-- {{tab_2}} -->
      <!-- {{isMobile}} -->
      <!-- in_today: {{ in_today }} -->
      <!-- tab: {{tab}} -->
      <!-- value_expansion: {{value_expansion}} -->
      <!-- {{tem_pendencias}} -->
      <!-- items_sum_parcelas: {{items_sum_parcelas}} <br/><br/> -->
      <!-- items_servicos_alunos:{{items_servicos_alunos}} <br/><br/> -->
      <!-- obj_user:{{obj_user}} <br/><br/> -->

      <!-- edita_cadastro:{{edita_cadastro}}<br/> -->
      <!-- item_edita_2:{{item_edita_2}}<br/> -->
      <!-- items_cadastro:{{items_cadastro}}<br/> -->

  <!-- DSI
{{ items_empresa }} -->
<!-- {{ pegou }} -->


<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog_img_2"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h6 grey lighten-3 justify-center">
          <!-- {{item_clicou.NOME}}  -->
          {{dialog_img_titulo}} 
        </v-card-title>

        <v-card-text>          
          <v-img
            alt="user"
            :src="dialog_img_url"
            height="30%"
            v-if="(dialog_img_url)"
          >
         </v-img>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>


          <v-btn
            color="primary"
            text
            x-large
            @click="dialog_img_2 = false"
          >
            FECHAR x
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<template>
      <div class="text-center">
        <v-dialog
          v-model="dialog_ok"
          width="500"
          persistent
        >


          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              {{  (dialog_ok_tipo == 'conectar_zap') ? 'Concluído' : 'Concluído'  }}
            </v-card-title>

            <v-card-text>
              
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialog_ok_clicou(dialog_ok_tipo), dialog_ok=false"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>




<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog_share"
      width="500"
    >

      
      
      <v-card>

        <br/>
        <v-divider class="mx-5 mb-2"></v-divider>
        
        <!-- <v-card-title class="text-h6 white lighten-2 justify-center">
          Compartilhar Link 
        </v-card-title> -->

        <v-card-subtitle class="black--text">
          Compartilhar Link 
        </v-card-subtitle>

        <v-card-text class="">

            <v-row class="">
              <v-col class="text-center" cols="auto">
                 <v-icon class="ml-2" color="primary" x-large 
                   @click="gerar_link('abrir')"
                   >
                   <!-- mdi-web -->
                   mdi-cellphone-link
                 </v-icon>
                 <p>Abrir Link</p>
              </v-col>
              
              <v-col class="text-center" cols="auto">
                 <v-icon class="ml-2" color="success" x-large
                  @click="gerar_link('whats')"
                 >
                   mdi-whatsapp
                 </v-icon>
                 <p>WhatsApp</p>
              </v-col>
            </v-row>


            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="link_share"
                  label=""
                  type="text"
                  outlined 
                  hide-details
                >
              
                <template v-slot:append>
                  <v-chip
                    color="primary"
                    class="mt-n1"
                    @click="gerar_link('copiar')"
                  >
                    Copiar
                  </v-chip>
                </template>

              </v-text-field>
              </v-col>
            </v-row>
        </v-card-text>

        <v-card-actions class="">
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="dialog_share = false"
          >
            FECHAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog_img"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h6 white lighten-2 justify-center">
          {{edita_cadastro.NOME}} 
        </v-card-title>

        <v-card-text>
          <v-img
            alt="user"
            :src="edita_cadastro.FOTO_URL"
            height="30%"
            v-if="(edita_cadastro.FOTO_URL)"
          >
         </v-img>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog_img = false"
          >
            FECHAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>



<v-dialog
          v-model="dialog_tabela"
          persistent
          max-width="990"
          scrollable
          :fullscreen="vai_imprimir"
      >
      

      <v-card
      class=""
      width="15cm"    
      :elevation="(vai_imprimir) ? 0 : 2"
      >
      <!-- height="80%" -->



      <!-- <v-card-title class="mb-1 text-h5 d-flex align-center justify-center" >{{obj_dados_empresa.NOME}}</v-card-title>
      <v-divider class="mx-7"></v-divider> -->

      
      <!-- <v-card-title v-if="(vai_imprimir)" class="mb-1 text-h6 d-flex align-center justify-center">{{getNomePaciente}}</v-card-title>
      <v-card-title v-else class="mb-1 text-h6">{{getNomePaciente}}</v-card-title> -->

      <v-card-subtitle class="pt-2">

          <!-- <span class="no-print mr-1 blue--text" v-text="(item_edita.CODIGO>0) ? 'Alterar ' : 'Novo '"/>   -->

          <span class="text-h5 no-print blue--text mr-2">
             {{ ((item_edita.CODIGO>0) ? 'Alterar ': 'Novo ') }}  
          </span>

          <span class="text-h6 ml-n2">
              {{ (card_titulo !='Registros') ? card_titulo : '' }}  
          </span>

          <span class="no-print" v-if="(item_edita.CODIGO>0)">
            {{item_edita.CODIGO}}
          </span>
          

      </v-card-subtitle>

      <!-- <v-divider class="mx-5"></v-divider> -->

      



          <!-- passo 4 monto os campos editaveis -->
          <!-- :readonly = "readonly" -->
              <v-card-text class="pa-1">
                <v-form
                  ref="form"
                  v-model="valido"
                  >
                  <v-container>


                      <v-row>
                          <v-col
                              class=""
                              v-for="(item, i) in items_editaveis"
                              :key="i"
                              :cols="(isMobile) ? 12 : item.cols"
                              v-if="parseInt(item.cols)>0"   
                          >
                          <!-- :cols="item.cols" -->

                          <v-card class="grey lighten-5"  elevation="4"
                             v-if="item.tag == 'v-editor'"
                            >
                              <v-card-text>
                                  <editor-content
                                    :editor="editor"
                                    class="white lighten-5"
                                    :style="[{ minHeight: altura_meio + 'cm'}]"
                                    v-if="item.tag == 'v-editor'"
                                  />
                              </v-card-text>
                            </v-card>


                            <p v-if="item.tag == 'p'" :class="[item.class]"
                             v-html="item_edita[item.value]">
                            </p>

                            <span v-if="(item.tag == 'v-img')&&(item_edita[item.value].length >10)" style="display: block; text-align: center;">
                              <!-- caso esteja preenchido mostra..caso contrario ele mostra o botao para upload -->
                              <v-divider class="mx-10 my-4"></v-divider>
                              <img
                                v-if="(item.tag == 'v-img')&&(item_edita[item.value].length >10)"
                                alt="user"
                                :src="item_edita[item.value]"
                                :width="[item.width]"
                              >
                              </img>
                            </span>


                              <!-- <v-chip
                               v-if="item.tag == 'v-chip'" :color="item.color" @click="clicou_em(item.value)"
                              >
                                {{ item.text }}
                              </v-chip> -->


                              <span v-if="(item.tag == 'v-chip')">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                    v-if="(item.tag == 'v-chip')" :color="item.color" @click="clicou_em(item.click)"
                                    :class=[item.class]
                                    v-bind="attrs" v-on="on"
                                    >
                                      {{ item.text }}
                                    </v-chip>
                                  </template>
                                  <span> {{item.hint}} </span>
                                </v-tooltip>
                              </span>


                              <span  v-if="(item.tag == 'v-checkbox')">
                                <v-checkbox 
                                      v-model="item_edita[item.value]"
                                      :label="(item_edita[item.value] ? item.text : item.text_2)"
                                      dense
                                      :class="[ (item_edita[item.class_if]) ? item.class_tag :  item.class_tag2]"
                                      :style= "[item.style_tag]"
                                      @click="clicou_em(item.click)"
                                      hide-details="true"
                                      v-if="(!item.if) || (item_edita[item.if])"
                                  >
                                  
                                  <!-- style="margin-left: -10px;display: none " -->
                                  <!-- :style= "[item.style_tag]" -->
                                  
                                  <!-- v-if="( (item_edita.hasOwnProperty(item.if) && item_edita[item.if]) || (item.else && !item_edita[item.else]) )" -->
                                      <!-- <template v-slot:label>
                                        <span class="fs-8">{{ item.text }}??</span>
                                      </template> -->
                                  </v-checkbox>
                                </span>


                              <v-text-field
                                  dense
                                  v-model="item_edita[item.value]"
                                  :label="item.rule == 'S' ? item.text+' *': item.text"
                                  :type="item.type"
                                  v-if="item.tag == 'v-text'"
                                  @keyup="meu_keyup($event, item.value, item.upper)"
                                  :rules="item.rule == 'S' ? notnullRules: noRules"
                                  v-mask = "getVmask(item.vmask)"
                                  :class="[item.class]"
                              ></v-text-field>
                              <!-- v-if="item.tag == 'v-text'" -->
                              <!-- :autofocus="i==1" -->


                              <v-combobox
                                  dense
                                  v-model="item_edita[item.value]"
                                  :label="item.text"
                                  v-if="item.tag == 'v-autocomplete'"
                                  :items="item.tag_items"
                                  :rules="item.rule == 'S' ? notnullRules: noRules"
                              ></v-combobox>
                              
                              <v-select
                                  :class="[item.class]"
                                  dense
                                  v-model="item_edita[item.value]"
                                  :label="item.text"
                                  v-if="item.tag == 'v-select'"
                                  :items="item.tag_items"
                                  :rules="item.rule == 'S' ? notnullRules: noRules"

                                  item-value='CODIGO'
                                  item-text='NOME'
                                  :clearable = "item.tag_clear == 'S'"
                                  :persistent-hint="item.cols>2"
                                  :hint="item.hint"
                              >
                              
                              <!-- <template slot="selection" v-slot-scope="item"> -->
                                
                              <!-- <template slot="selection" v-slot-scope="item"> -->
                                <template v-slot:label>
                                  <v-tooltip bottom v-if="item.hint">
                                      <template v-slot:activator="{ on, attrs }">
                                        <span
                                            v-bind="attrs"
                                            v-on="on"
                                        > 
                                          <span :class="item.class">
                                            {{ item.text }} 
                                            <!-- {{ item_edita[item.value] }} -->
                                          </span>
                                        </span>

                                      </template>
                                      <span> {{ item.hint }} </span>
                                    </v-tooltip>
                                </template>
    
                            </v-select>


                              <!-- CAMPO ANOTAÇÕES OU OBSERVAÇÃO -->
                              <v-textarea
                                v-model="item_edita[item.value]"
                                :label="item.text"
                                v-if="item.tag == 'v-textarea'"
                                :hint="item.hint"
                                :persistent-hint="item.cols>2"
                                dense
                                :rows="(vai_imprimir) ? '35' : item.rows"
                                :autofocus="i==2"
                                :rules="item.rule == 'S' ? notnullRules: noRules"
                                :outlined="(vai_imprimir) ? false : true"
                              >

                                <!-- <template v-slot:label>
                                  <span class=""> {{(vai_imprimir) ? '' : item.text }} </span>
                                </template>   -->
                              </v-textarea>




                              <!-- INICIO DIV IMAGEM -->
                              <div class=""
                                v-if="item.tag == 'img'"
                              >
                              
                              <v-row dense class="grey lighten-5">
                                <v-col cols="8" class="">
                                  <span class="pb-2">Logo</span>
                                    <v-img
                                    v-if="urlLogo"
                                    max-height="90"
                                    max-width="170"
                                    :src="urlLogo"
                                    contain
                                  >
                                  </v-img>
                                </v-col>
                                
                                <v-col class="mt-5">
                                  <p class="primary--text"><label for="file_upload" style="cursor: pointer;">Alterar Logo</label></p>
                                  <!-- <p class="primary--text"><label for="file_upload" style="cursor: pointer;">Alterar Logo</label></p> -->
                                  <!-- <p>Remover</p> -->
                                  <v-btn text x-small color="red" for="file_upload"
                                    @click="item_edita[item.value] = '', urlLogo=''"
                                  >
                                    Remover
                                  </v-btn>
                                </v-col>


                                </v-row>
                                <!-- oculto -->
                                <v-row dense class="grey lighten-3">
                                  <v-col>
                                    <!-- necessario para fazer upload logo -->
                                    <v-file-input
                                            v-model="files_upload"
                                            accept="image/*"
                                            label="Logomarca"
                                            @change="upload_logo(files_upload,'DSi','logo',item.value)"
                                            style="display: none"
                                            id="file_upload"
                                          ></v-file-input>
                                    <!-- necessario para fazer upload logo -->
                                  </v-col>
                                </v-row>
                              </div>
                              <!-- FINAL DIV IMAGEM -->


                              
                              
                          </v-col>
                      </v-row>
                  </v-container>
                </v-form>


              </v-card-text>

              <v-divider class="mx-10" v-if="(!vai_imprimir)"></v-divider>

              <span v-if="(vai_imprimir)">
                  <span class="d-flex align-center justify-center black--text fs-10" > {{ LSendereco }} - {{obj_dados_empresa.FONE1}}</span>
              </span>



              <v-divider class="mx-10"></v-divider>

      <v-card-actions class="no-print">
          <v-btn
              x-small
              color="red"
              text
              @click="remover_tabela(tabela_selecionada, item_edita, card_titulo), dialog_tabela = false"
          >
            Excluir
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
              color="blue"
              text
              @click="imprimir_pagina()"
              class="mr-5"
              small
          >
            Imprimir.
          </v-btn>

          <v-btn
              color="red"
              text
              @click="dialog_tabela = false"
          >
            Cancelar
          </v-btn>
          
          &nbsp

          <v-btn
              :disabled="!valido"
              color="primary"
              @click="salvar_tabela(tabela_selecionada, item_edita, 'dialog_tabela')"
          >
            Salvar
          </v-btn>
      </v-card-actions>
      </v-card>
  </v-dialog>



<v-dialog
  v-model="dialog_editar"
  max-width="300px"
  scrollable
>
  <v-card
    class="mx-auto"
  >
    <v-list>

      <v-subheader>
        <h4>OPÇÕES</h4>
      </v-subheader>

      <v-divider
        class="mx-0"
      ></v-divider>

      <v-list-item
      link
      @click="(imprimir_ficha('1'), dialog_editar=false)"
      >
        <v-list-item-icon>
          <v-icon> mdi-account-box </v-icon>
        </v-list-item-icon>
        <v-list-item-title>Imprimir Ficha</v-list-item-title>
      </v-list-item>

      <v-divider
        class="mx-4"
      ></v-divider>


    </v-list>
  </v-card>
</v-dialog>

 
   <v-snackbar
     v-model="snackbar"
     :timeout="snack_timeout"
     top
     :color="snack_color"    
   >
   <center>
     {{ snack_text }}
   </center>
     <!-- <template v-slot:action="{ attrs }">
       <v-btn
         color="white"
         text
         v-bind="attrs"
         @click="snackbar = false"
       >
         Fechar
       </v-btn>
     </template> -->
   </v-snackbar>
 
 
   <!-- EDITAR SERVICO -->
   <v-dialog
       v-model="dialog_novo_servico"
       persistent
       max-width="999px"
       scrollable
   >
 
       <v-card>
       <v-card-title>
           <span class="text-h5">Serviço</span>    &nbsp
           <span class="grey--text mb-2">{{edita_servico.CODIGO}}</span>          
       </v-card-title>
           <v-form
             ref="form"
             v-model="form_servico"
           >
           <!-- :readonly = "readonly" -->
               <v-card-text class="pa-1 pl-3">
                   <v-container>
                       <v-row>
 
                           <v-col
                             cols="6"
                             md="2"
                           >
                             <v-text-field
                               v-model="edita_servico.DATA"
                               type="date"
                               label="Data"
                               dense
                             >
                             </v-text-field>
                           </v-col>


                           <v-col
                            cols="6"
                            md="2"
                            v-if="mostrar_tipo_cliente('CFC')"
                          >
                            <v-text-field
                              v-model="edita_servico.DATA_PROCESSO"
                              label="Data do Processo"
                              type="date"
                              :dense = "input_dense"
                            >
                            </v-text-field>
                          </v-col> 



                          <v-col
                             cols="6"
                             md="2"
                           >
 
                               <v-select
                               v-model="edita_servico.SITUACAO"
                               label="Situação"
                               :items = "items_situacao"
                               :dense = "input_dense"
                               @input="mudou_situacao(edita_servico.SITUACAO)"
                               ></v-select>

                           </v-col>

                          <v-col
                             cols="6"
                             md="3"
                             v-if="mostrar_tipo_cliente('GESTOR')"
                             class="d-flex justify-center"
                           >

                            
                            <v-checkbox
                              v-model="edita_servico.ALERTA_EXPIRA"
                              label="Label"
                              dense
                              hide-details
                              class="py-0 ma-0"
                              @click="atualiza_expira(edita_servico.ALERTA_EXPIRA)"
                            >
                              <template v-slot:label>
                                <span :class="['fs-12']">Lembrar em 90 dias</span>
                              </template>
                            </v-checkbox>

                           </v-col>


                           <v-col
                             cols="6"
                             md="2"
                             v-if="mostrar_tipo_cliente('GESTOR')"
                           >
                             <v-text-field
                               v-model="edita_servico.DATA_EXPIRA"
                               type="date"
                               label="Lembrar em"
                               dense
                             >
                             </v-text-field>
                           </v-col>

                           <v-col
                            cols="6"
                            md="2"
                          >
                            <v-text-field
                              v-model="edita_servico.DATA_CONCLUIDO"
                              :label="`Data  ${edita_servico.SITUACAO}`"
                              type="date"
                              :dense = "input_dense"
                              v-if="edita_servico.SITUACAO!='ATIVO'"
                            >
                            </v-text-field>
                          </v-col> 

                          </v-row>


                          <v-row>

                           <v-col
                             cols="6"
                             md="2"
                             v-if="mostrar_tipo_cliente('CFC')"
                           >
                             <v-text-field
                               v-model="edita_servico.PROCESSO"
                               type="text"
                               label="Processo"
                               dense
                             >
                             </v-text-field>
                           </v-col>
 
                           <v-col
                               class=""
                               cols="12"
                               md="7"
                           >   
                           
                           <!-- v-model="servico_selecionado" -->
                           <!-- {{ combobox_servico }} -->
                               <v-select
                                 v-model="combobox_servico"
                                 label="Serviço (Primario)"
                                 :items="items_tabela_servico"
                                 item-value='CODIGO'
                                 item-text='DESCRICAO'
                                 return-object
                                 dense
                                 clearable
                                 :rules="notnullRules"
                                 required
                                 @change="seleciona_servico(combobox_servico)"
                                 @keyup.esc="combobox_servico=''"
                               >
                               
                                 <template slot='selection' slot-scope='{ item }'>
                                 {{ item.DESCRICAO }}
                                 </template>
               
                                 <template slot='item' slot-scope='{ item }'>
                                     <h5>{{ item.DESCRICAO }}</h5>
                                     &nbsp | &nbsp
                                     <div 
                                     class="caption"
                                     >
                                     {{ item.double_TOTAL }}
                                     </div>                      
                                 </template>
                               </v-select>
                           </v-col>
 
 
                           <v-col
                             cols="6"
                             md="2"
                           >
                             <v-text-field
                               v-model="edita_servico.double_TOTAL"
                               type=""
                               label="Total"
                               prefix="R$"
                               dense
                               required
                               :rules="acimaZero"
                               @keyup="edita_servico.double_TOTAL = moeda($event)"
                             >
                             <!-- @keyup="edita_servico.double_TOTAL = moeda($event)" -->
                             </v-text-field>
                           </v-col>
 

                           <v-col
                            cols="6"
                            md="1"
                            v-if="mostrar_tipo_cliente('CFC')"
                          >
                            <v-select
                            v-model="edita_servico.CATEGORIA"
                            label="Categoria"
                            :dense = "input_dense"
                            :items="[
                                'A',        
                                'B',     
                                'C',     
                                'D',
                                'E',
                                'AB',     
                                'AC',     
                                'AD',     
                                'AE'  
                            ]"
                            
                            >
                              <template slot='item' slot-scope='{ item }'>
                                <span class="pa-0" style="font-size: 12px !important;"> {{ item }}</span>
                              </template>

                          </v-select>
                          </v-col>


                       </v-row>

                       <v-row>
                          <v-col 
                            cols="6"
                            md="auto offset-md-7"
                            class="pa-0 pl-1"
                            style="margin-top:-25px; margin-bottom:-10px;"
                            v-if="mostrar_tipo_cliente('CFC')"
                          >
                            <v-btn
                              x-small
                              color="primary"
                              text
                              @click="atualizar_contrato(combobox_servico)"
                              v-if="edita_servico.JA_ASSINADO!='S'"
                            >
                              atualizar contrato
                            </v-btn>  
                          </v-col>
                        </v-row>

                   </v-container>
               </v-card-text>
           </v-form>
 
       <v-card-actions>
 
           <v-btn
             x-small
             color="red"
             text
             @click="excluir_tudo_aluno('SERVICO', edita_servico), dialog_novo_servico=false"
             v-if = "edita_servico.CODIGO>0"
           >
           <!-- @click="remover_tabela('servicos',edita_servico, edita_servico.DESCRICAO), dialog_novo_servico=false" -->
             Excluir
           </v-btn>
         
 
           <v-spacer></v-spacer>
 
           <v-btn
               color="red"
               text
               @click="dialog_novo_servico = false"
           >
             Cancelar.
           </v-btn>
           
           &nbsp
 
           <v-btn
               :disabled="!form_servico"
               color="primary"
               @click="salvar_tabela('servicos', edita_servico, 'dialog_novo_servico','')"
           >
             Salvar
             <!-- SALVAR SERVICO -->
           </v-btn>
       </v-card-actions>
       </v-card>
   </v-dialog>
 
   <v-dialog
     v-model="dialog_cadastro"
     persistent
     max-width="1100px"  
     scrollable 
   >        
   
     
     <v-card>
       <v-card-title>

        <div v-if="!item_edita_2.CODIGO > 0">
         <span class="text-h5" >Novo Cadastro </span>
        </div>

        <div v-else>
          <!-- <span class="text-h5" > Editar  </span> -->
          <span class="text-h5 blue--text"> IDE: {{ item_edita_2.CODIGO_ALUNO }}</span>
        </div>

         <!-- return this.item_edita_2.CODIGO > 0 ? ('Editar '+this.item_edita_2.CODIGO_ALUNO) : ('Novo Cadastro ') //this.item_edita_2.id  -->
 



         <v-row dense class="pt-2  ml-5 mt-2 orange lighten-5"
           v-if="(!item_edita_2.NOME) && (mostrar_tipo_cliente('CFC'))"
         >
             <v-col
               cols="6"
               md="2"
             >
             
               <v-text-field
                 v-model="item_edita_2.PROCESSO"
                 label="Processo"
                 type="text"
                 :dense = "input_dense"
                 autofocus
                 hint=""
                 persistent-hint
               ></v-text-field>
             </v-col>

             <v-col
               cols="6"
               md="2"
             >
              <v-btn
                class="ml-3"
                color="primary lighten-1"
                small
                :href="detran_links('processo',item_edita_2)"   
                target="_blank"
                @click="aviso('Assim que entrar na janela de Processo do Aluno no Detran:\nAperte:\n1º- Ctrl+A (para selecionar tudo)\n2º- Ctrl+C (para copiar)\n3º- volte aqui e clique em COLAR (para colar os dados)')"
              >
              
              <!-- @click="get_site()" -->
                1º Consultar
              </v-btn>
              <!-- <span class="subtitle-1">
                Ctrl+A , Ctrl+C
              </span> -->

             </v-col>

             <v-col
               cols="6"
               md="2"
             >
             <v-btn
                @click="copiar_dados_aluno('WEB_PR')"
                color="primary lighten-1"
                small
              >
                2º Colar
              </v-btn>
              

              &nbsp
              
              ou
             </v-col>


             <v-col
               cols="6"
               md="3"
             >
             <v-btn
                class="ml-3"
                color="error lighten-1"
                small
                :href="detran_links('processo_pdf',item_edita_2)"   
                target="_blank"  
              >
              <!-- @click="aviso('Assim que entrar na janela de Processo do Aluno no Detran:\nAperte:\n1º- Ctrl+A (para selecionar tudo)\n2º- Ctrl+C (para copiar)\n3º- volte aqui e clique em COLAR (para colar os dados)')" -->
              
              <!-- @click="get_site()" -->
                1º Consultar pdf - SSH
              </v-btn>
             </v-col>


             <v-col
               cols="6"
               md="3"
             >
             <v-btn
                @click="copiar_dados_aluno('PDF_PR')"
                color="error lighten-1"
                small
              >
                2º Colar DO PDF
              </v-btn>

              <v-icon class="ml-2" color="primary" @click="dicas('copiar_mozilla')">
                mdi-information
              </v-icon>
             </v-col>
         </v-row>


         <!-- <v-subheader>Subheader</v-subheader> -->
       </v-card-title>
 
       <v-card-text>
         <v-form
         ref="form"
         :readonly = "readonly"
         v-model="valido"
         >
         <v-container>
 
 
 
           <v-row>
             <v-col
               cols="12"
               md="6"
               
             >
 
               <v-text-field
                 v-model="item_edita_2.NOME"
                 label="Nome*"
                 :rules="minimoRules"
                 type="text"
                 @input="allUpper($event, 'item_edita_2','NOME')"
                 :dense = "input_dense"
                 required
                 autofocus
               ></v-text-field>
             </v-col>
           

           
             
             <v-col
               cols="6"
               md="2"
             >                   
               <v-select
               v-model="item_edita_2.STATUS"
               label="Situação"
               :items = "items_situacao"
               :rules="notnullRules"
               :dense = "input_dense"
               ></v-select>
             </v-col>
 
             <!-- <v-col
               cols="6"
               md="1"
             >
               <v-select
               v-model="item_edita_2.CATEGORIA"
               label="Categoria"
               :dense = "input_dense"
               :items="[
                   'A',     
                   'AB',     
                   'AC',     
                   'AD',     
                   'AE',     
                   'B',     
                   'C',     
                   'D',
                   'E'
               ]"
               :rules="notnullRules"
               ></v-select>
             </v-col> -->
             
             <v-col
               cols="6"
               md="2"
               v-if="mostrar_tipo_cliente('CFC')"
             >
               <v-text-field
                 v-model="item_edita_2.PROCESSO"
                 label="Processo Atual"
                 type="text"
                 :dense = "input_dense"
               ></v-text-field>
             </v-col> 
             
 
 
             <v-col
               cols="12"
               md="2"
               v-if="mostrar_tipo_cliente('CFC')"
             >
               <v-text-field
                 v-model="item_edita_2.DATA_PROCESSO"
                 label="Data do Processo"
                 type="date"
                 :dense = "input_dense"
               >
               </v-text-field>
             </v-col> 
 
           </v-row>


 
 
           <!-- NOVALINHA -->
           <v-row>        
             <v-col
               cols="6"
               md="2"
             >
               <v-text-field
                 v-model="item_edita_2.CELULAR"
                 label="Celular Whats"
                 placeholder="(  )    -    "
                 :dense = "input_dense"
                 v-mask = "'(##) #####-####'"
               ></v-text-field>
               <!-- :rules="notnullRules" -->
             </v-col>
 
             <v-col
               cols="6"
               md="2"
             >
               <v-text-field
                 v-model="item_edita_2.CELULAR_2"
                 label="Celular 2"
                 placeholder="(  )    -    "
                 :dense = "input_dense"
                 v-mask = "'(##) #####-####'"
               ></v-text-field>
             </v-col>
 
             <v-col
               cols="12"
               md="2"
             >
               <v-text-field
                 v-model="item_edita_2.FONE"
                 label="Fone"
                 placeholder="(  )    -    "
                 :dense = "input_dense"
                 v-mask = "'(##) #####-####'"
               ></v-text-field>
             </v-col>
 
 
 
             <v-col
               cols="12"
               md="6"
             >
               <v-text-field
                 v-model="item_edita_2.EMAIL"
                 label="Email"
                 type="email"
                 :dense = "input_dense"
               ></v-text-field>
             </v-col>
             </v-row>
 
 
 
            <v-row>
             <v-col
               cols="12"
               md="2"
             >
               <v-text-field
                 v-model="item_edita_2.CEP"
                 label="CEP"
                 type="text"
                 placeholder="00000-000"
                 v-mask = "'#####-###'"
                 :dense = "input_dense"
                 @keyup="consulta_cep(item_edita_2.CEP)"
                 @click:append="consulta_cep(item_edita_2.CEP)"
                 append-icon="mdi-magnify"
               ></v-text-field>
             </v-col>
             
             
             <v-col
               cols="12"
               md="5"
             >
               <v-text-field
                 v-model="item_edita_2.ENDERECO"
                 @input="firstUpper($event, 'item_edita_2','ENDERECO')"
                 label="Endereço Nº Complemento"
                 type="text"
                 :dense = "input_dense"
               ></v-text-field>
             </v-col>
 
 
             <v-col
               cols="12"
               md="2"
             >
               <v-text-field
                 v-model="item_edita_2.BAIRRO"
                 @input="firstUpper($event, 'item_edita_2', 'BAIRRO')"
                 label="Bairro"
                 type="text"
                 :dense = "input_dense"
               ></v-text-field>
             </v-col>
             
             
             <v-col
               cols="10"
               md="2"
             >
               <v-text-field
                 v-model="item_edita_2.CIDADE"
                 @input="firstUpper($event, 'item_edita_2','CIDADE')"
                 label="Cidade"
                 type="text"
                 :dense = "input_dense"
               ></v-text-field>
             </v-col>
             
             <v-col
               cols="2"
               md="1"
             >
               <v-text-field
                 v-model="item_edita_2.UF"
                 label="UF"
                 type="text"
                 @input="allUpper($event,'item_edita_2', 'UF')"
                 :dense = "input_dense"
               ></v-text-field>
             </v-col>
 
           </v-row> 
             
           <v-row>
             <!-- NOVA LINHA -->
             <v-col
               cols="12"
               md="2"
             >
               <v-text-field
                 v-model="item_edita_2.DATA_NASCIMENTO"
                 label="Data de Nascimento"
                 type="date"
                 :dense = "input_dense"
               >
               </v-text-field>
 
             </v-col>
 
             <v-col
               cols="6"
               md="2"
             >
               <v-text-field
                 v-model="item_edita_2.CPF"
                 label="CPF"
                 type="text"
                 v-mask = "'###.###.###-##'"
                 :dense = "input_dense"
               ></v-text-field>
             </v-col>
 
 
             <v-col
               cols="6"
               md="2"
             >
               <v-text-field
                 v-model="item_edita_2.RG"
                 label="RG"
                 type="text"
                 :dense = "input_dense"
               ></v-text-field>
 
             </v-col>
 
 
             <v-col
               cols="4"
               md="2"
             >
               <v-text-field
                 v-model="item_edita_2.RG_ORGAO"
                 label="Órgão"
                 type="text"
                 :dense = "input_dense"
               ></v-text-field>
             </v-col>
             
             
             <v-col
               cols="8"
               md="2"
               
             >
               <v-select
               v-model="item_edita_2.SEXO"
               label="Sexo"
               :items="['FEMININO', 'MASCULINO']"
               :dense = "input_dense"
               ></v-select>
             </v-col>                  
 
             <v-col
               cols="12"
               md="2"
             >
               <v-select
                 v-model="item_edita_2.ESTADO_CIVIL"
                 label="Estado Cívil"
                 :items="[
                     'MENOR',
                     'SOLTEIRO (A)',
                     'CASADO (A)',
                     'SEPARADO (A)',
                     'DIVORCIADO (A)',
                     'VIUVO (A)'
                     ]"
                 type="text"
                 :dense = "input_dense"
               ></v-select>
             </v-col>
 
           </v-row>
 
           <v-row>
             <!-- NOVALINHA -->
             <v-col
               cols="12"
               md="2"
             >
               <v-text-field
                 v-model="item_edita_2.NATURALIDADE"
                 @input="firstUpper($event, 'item_edita_2','NATURALIDADE')"
                 label="Naturalidade"
                 type="text"
                 :dense = "input_dense"
               ></v-text-field>
             </v-col>
             
             <v-col
               cols="12"
               md="2"
             >
               <v-text-field
                 v-model="item_edita_2.PROFISSAO"
                 @input="firstUpper($event, 'item_edita_2','PROFISSAO')"
                 label="Profissão"
                 type="text"
                 :dense = "input_dense"
               ></v-text-field>
             </v-col>
             
             <v-col
               cols="12"
               md="4"
             >
               <v-text-field
                 v-model="item_edita_2.NOME_MAE"
                 @input="firstUpper($event, 'item_edita_2','NOME_MAE')"
                 label="Nome da Mãe"
                 type="text"
                 :dense = "input_dense"
               ></v-text-field>
             </v-col>
             
             <v-col
               cols="12"
               md="4"
             >
               <v-text-field
                 v-model="item_edita_2.NOME_PAI"
                 @input="firstUpper($event, 'item_edita_2','NOME_PAI')"
                 label="Nome do Pai"
                 type="text"
                 :dense = "input_dense"
               ></v-text-field>
             </v-col>
             
           </v-row>
 
           <v-row>
             <!-- NOVALINHA -->
             <v-col
               cols="6"
               md="2"
             >
               <v-text-field
                 v-model="item_edita_2.CNH"
                 label="CNH"
                 type="text"
                 :dense = "input_dense"
               ></v-text-field>
             </v-col>
             
             <v-col
               cols="6"
               md="2"
             >
               <v-text-field
                 v-model="item_edita_2.LADV"
                 label="LADV"
                 type="text"
                 :dense = "input_dense"
               ></v-text-field>
             </v-col>
             
             <v-col
               cols="12"
               md="4"
             >
               <v-combobox
                 v-model="item_edita_2.PERIODO_AULA"
                 label="Período Aula Teórica"
                 type="text"
                 :items = "items_periodo"
                 :dense = "input_dense"
               ></v-combobox>
             </v-col>

             <v-col
               cols="12"
               md="2"
             >
               <v-text-field
                 v-model="item_edita_2.DATA_CADASTRADO"
                 label="Data do Cadastro"
                 type="date"
                 :dense = "input_dense"
                 hide-details
               >
               </v-text-field>

               
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <h6 v-bind="attrs" v-on="on"> {{item_edita_2.QUEM_CADASTROU}} </h6>
                    </template>
                    <span> Quem Cadastrou </span>
                  </v-tooltip>
             </v-col>

 
 
           </v-row>
 
 
 
           <v-row>
 
             <v-col
               cols="12"
               md="12"
             >
             <v-textarea
               v-model="item_edita_2.OBSERVACAO"
               label="Anotação"
               hint="Máximo de 9999 caracteres"
               @input="firstUpper($event, 'item_edita_2','OBSERVACAO')"
               :dense = "input_dense"
               auto-grow
               rows="15"
               row-height="20"
               background-color="amber lighten-4"
             >
             <!-- background-color="amber lighten-4" -->
             </v-textarea>
             </v-col>
           </v-row>

           <v-row>
            <v-col
               cols="12"
               md="10"
             >
               <v-text-field
                 v-model="item_edita_2.FOTO_URL"
                 label="URL da Foto"
                 type="text"
                 dense
               >
               </v-text-field>
             </v-col>

             <v-col cols="2" class="">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                  color="primary" 
                  @click="clicou_em('upload_url')"
                  class=''
                  v-bind="attrs" v-on="on"
                  >
                    UPLOAD
                  </v-chip>
                </template>
                <span> Upload da Foto via url </span>
              </v-tooltip>
             </v-col>
           </v-row>


           <v-row>
            <br/>
            <br/>
                     <v-textarea
                     v-model="texto_clipboard"
                     label="Colar aqui"
                     dense
                     height="5"
                     class="sem_quebra grey lighten-5"
                     id="pastle_clipboard"
                     v-if="!item_edita_2.NOME"
                     >
                     <!-- tem que estar visivel na hora que for colar -->
                     </v-textarea>
           </v-row>
 
         </v-container>
         </v-form>
       </v-card-text>
 
       
    


      <v-card-actions>
        <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                  <h6 v-bind="attrs" v-on="on"> {{ item_edita_2.QUEM_ALTEROU  }}</h6>
              </template>
              <span> Quem Alterou </span>
            </v-tooltip>
      </v-card-actions>

       <v-card-actions>
      

         <v-btn
           x-small
           color="red"
           text
           @click="excluir_tudo_aluno('CADASTRO'), dialog_novo_servico=false"
           v-if = "readonly==false"
         >
         <!-- @click="remover_tabela('alunos',item_edita_2,item_edita_2.NOME), dialog_cadastro=false" -->
           Excluir
         </v-btn>
 
         <v-spacer></v-spacer>
         <v-btn
           color="red"
           text
           @click="dialog_cadastro=false"
         >
           Cancelar
         </v-btn>
         
         <v-btn
         
           color="primary"
           @click="salvar_tabela('alunos', item_edita_2, 'dialog_cadastro', edita_cadastro)"
           v-if = "readonly==false"
           :disabled="!valido"
         >
           Salvar.
           <!-- SALVAR CADASTRO -->
         </v-btn>

       </v-card-actions>
       
       <!-- <h5> {{ item_edita_2.QUEM_ALTEROU  }} </h5> -->
 
 
     </v-card>
   </v-dialog>
 
 
   <v-row>
     <v-col
     class="no-print"
     cols="12 mt-1 "
     >
 
     <v-card
     class="mx-1 my-1 "
     >
  
         <span v-if="pode_alterar()">
         <v-fab-transition v-if="(tab!='pesquisar')">
             <v-tooltip 
               bottom
             >
               <template v-slot:activator="{ on, attrs }">
                 <v-btn
                   v-bind="attrs"
                   v-on="on"
 
                   class="mt-8"
                   @click="novo_cadastro"
                   color="primary"
                   dark
                   small
                   absolute
                   top
                   right
                   fab
                 >
                 <!-- v-show="!hidden" -->
                   <v-icon>mdi-account-plus</v-icon>
                 </v-btn>
               </template>
               <span> Novo Cadastro </span>
             </v-tooltip>
         </v-fab-transition>
        </span>
  
         <v-tabs
             v-model="tab"
             background-color="#FAFAFA"
             show-arrows    
             height="33"
             color=""
         >
 
             <v-tabs-slider></v-tabs-slider>
 
             <v-tab href="#pesquisar">
                 <v-icon>
                 mdi-account-search 
                 </v-icon>
                    
                 <div>
                   &nbsp Pesquisar
                 </div>
 
             </v-tab>
 
 
             <v-tab href="#cadastro"
             v-if="edita_cadastro.CODIGO > 0"
             >
                     <v-icon>
                     mdi-account 
                     </v-icon>
 
                     <div>
                       &nbsp Cadastro
                     </div>
             </v-tab>
             
             
         </v-tabs>
 
         <v-tabs-items 
         v-model="tab"
         touchless
         >
         <!-- touchless = impede deslizar horizontal -->
          <v-tab-item :key="1" value="pesquisar" class="">
              
          <v-row class="grey lighten-3 ma-1">

          <!-- COLUNA ESQUERDA -->
          <v-col class="pa-1 " cols="12" md="8" lg="9">
          
            <v-row class="">
              <v-col
              class=""
              cols="12"
              md="12"
              >
                <v-data-table
                  dense
                  :headers="headers_cadastro"
                  :items="items_cadastro"
                  
                  :custom-filter="SearchNoVisibles"

                  fixed-header

                  class="elevation-1"
                  :items-per-page="100"
                  :disable-sort="false"
                  :mobile-breakpoint="55"
                  :height="retorna_altura( (isMobile ? 400 : 230))"

                  :footer-props="footerProps"
                  
                >
                <!-- :footer-props="{
                  'items-per-page-options': [10, 20, 30, 50,100,200,-1]
                  }" -->
                <!-- :height="(isMobile) ? '' : (items_cadastro.length>15) ? retorna_altura() : ''" -->
                <!-- :search="search_aluno" -->



                <template v-slot:item.DATA_CADASTRADO="{ item }">
                    <span>
                    {{ getFormatDate(item.DATA_CADASTRADO) }}
                    </span>
                </template>


                <!-- <template v-slot:item.FOTO_URL="{ item }">
                    <a> <v-img
                        alt="user"
                        contain
                        :src="(item.FOTO_URL)? item.FOTO_URL : ''"
                        width="50px"
                        @click="abre_img('...', item.FOTO_URL,'online' )"
                      >
                    </v-img></a>
                  </template> -->

                <template v-slot:item.CODIGO_ALUNO="{ item }">
                    <a class="font-weight-medium"  @click="abrir_cadastro_item(item)">
                      {{ item.CODIGO_ALUNO }} 
                    </a>
                  </template>

                  <template v-slot:item.NOME="{ item }">
                    
                    <!-- <v-chip  color="primary lighten-1" small class="mx-1"
                    @click="abrir_cadastro_item(item)" 
                    >
                      {{ (item.NOME) }} &nbsp {{ item.CODIGO }}
                    </v-chip> -->



                    <v-avatar  class="pb-0 ma-0 mr-1" size="22" v-if="(item.FOTO_URL)">
                      <v-img
                        alt="..."
                        contain
                        :src="item.FOTO_URL"
                        v-if="(item.FOTO_URL.length)"
                        @click="abre_img(item.NOME, item.FOTO_URL, '')"
                        class="hand">
                      </v-img>
                        
                        <!-- @click="dialog_img=true"  -->

                        <v-icon size="22" v-else class="pa-0 ma-0">
                          mdi-account-circle
                        </v-icon>
                    </v-avatar>

                    
                    <a class="font-weight-medium" 
                     @click="clicou_no_nome(item)"
                    >
                    <!-- @click="abrir_cadastro_item(item)" -->
                      {{ item.NOME }} 
                    </a>

                    <span class="count_item fs-10">
                    {{ calcula_idade(getFormatDate(item.DATA_NASCIMENTO), 1) }}
                    </span>
                  </template>

                  <template v-slot:item.STATUS="{ item }">                         
                    <v-chip v-if="item.STATUS" class="" small dark
                    :color="getColorStatus(item.STATUS)"
                    >
                    <!-- :color="(item.STATUS == 'ATIVO') ? '' : (item.STATUS == 'CONCLUIDO')? 'success': 'error'  " -->
                      {{  item.STATUS }}
                    </v-chip>
                    
                  </template>



                <template v-slot:item.PROCESSO="{ item }">
                  <v-chip
                      color="blue"
                      small
                      dark
                      v-if="item.PROCESSO"
                      :href="detran_links('processo',item)"
                      target="_blank"
                    >
                    <!-- @click="get_links('get_processo',null,item)" -->
                    <v-icon
                        size= "14"
                        class="mr-1"
                    >
                        mdi-magnify
                    </v-icon>

                      <span>
                        {{ item.PROCESSO }}
                      </span>

                    </v-chip>
                  </template>

                <template v-slot:item.CELULAR="{ item }">
                  <v-chip
                      color="green"
                      small
                      dark
                      v-if="item.CELULAR"
                      @click="send_whats(item.CELULAR, item.NOME, item.CODIGO_ALUNO)"
                    >
                    <v-icon
                        size= "14"
                        class="mr-1"
                    >
                        mdi-whatsapp
                    </v-icon>

                      <span>
                        {{ item.CELULAR }}
                      </span>

                    </v-chip>
                  </template>

                  <!-- <template v-slot:item.actions="{ item }">
                    <v-icon
                      class="mr-2"
                      @click="editar_tabela(item, 'item_edita_2','dialog_cadastro')"
                      color=""
                      :size = "size_mobile"
                    >
                      mdi-pencil
                    </v-icon>
                  </template> -->

                  <template v-slot:top>
                    <!-- <v-toolbar
                      flat
                      class="red"
                    > -->

                      <template>
                          <v-card
                            elevation="4"
                            class="ma-0 my-1 grey lighten-5"
                            :height="(isMobile) ? 'auto' : '60px'"  
                          >
                          
                        <v-container class="mt-1 ">


                          

                          <v-row class="d-flex align-center justify-start">
                            
                            <v-col
                            :class="[(isMobile) ? 'mt-5 mb-2': 'pb-0  mt-1']"
                            cols="12"
                            md="5"
                            > 
                                <!-- <v-text-field
                                  dense
                                  class=""
                                  v-model="search_aluno"
                                  clearable
                                  append-icon="mdi-magnify"
                                  label="Procurar"                
                                  hide-details
                                  @keyup.esc="search_aluno=''"
                                  @input="search_aluno = search_aluno.toLowerCase()"
                                >
                                </v-text-field> -->

                                <v-text-field
                                dense
                                v-model="nome_digitou"
                                :label="`Nome, IDE, Celular, CPF, Processo: (${items_cadastro.length})`"
                                required
                                @keypress.enter="localizar_paciente($event.target.value, false)"
                                
                                clear-icon="mdi-close"
                                @click:clear="localizar_paciente('todos', false)"

                                append-icon="mdi-magnify "
                                @click:append="localizar_paciente(nome_digitou, false)"
                                clearable         
                                @keyup.esc="nome_digitou=''"
                                hide-details  
                              >
                              <!-- append-outer-icon="mdi-account-group" -->




                                  <template v-slot:append-outer>
                                    <v-tooltip bottom>
                                      <template ate v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            v-bind="attrs"
                                            v-on="on" 
                                            size="33"
                                            class="mt-n2 hand"
                                            color="primary"
                                            @click="localizar_paciente('todos', false)"
                                        >mdi-account-group</v-icon>

                                      </template>
                                      <span> Mostrar Todos do último 1 ano </span>
                                    </v-tooltip>
                                  </template>

                              </v-text-field>

                              <div class="text-center">
                                    <v-progress-linear
                                      value="0"
                                      indeterminate
                                      color=""
                                      v-if="progress_user"
                                    />
                                </div>

                            </v-col>

                            
                            <v-col :class="[(isMobile) ? 'mt-5 mb-2': 'pb-0  mt-1']"
                            cols="6"
                            md="2"
                            > 
                              <v-select
                                v-model="tipo_cliente"
                                :label="'Situação '+ '('+count_alunos+')'"
                                :items="['TODOS','ATIVO','CONCLUIDO','CANCELADO','PRE-CADASTRO','ONLINE','TRANSFERIDO','APROPRIADO']"
                                dense
                                hide-details=""
                                @change="localizar_paciente(nome_digitou, false)"
                                >
                                <!-- @change="open_contas(di,df)" -->
                              </v-select>
                            </v-col>


                            <v-col class="ml-auto" cols="auto" v-if="(!isMobile)">
                              <v-chip
                                class="ma-2"
                                color=""  
                                href="https://youtu.be/yqemFCGRgpw"
                                target="_blank"
                              >
                                <v-icon left color="red">
                                  mdi-youtube
                                </v-icon>
                                Tutorial
                              </v-chip>
                            </v-col>

                    
                            <v-col class=" d-flex justify-end"
                              cols="6"
                              md="1"
                            >

                            <span v-if="pode_alterar() && (carregou_cliente)">
                                  <v-fab-transition>
                                      <v-tooltip 
                                        bottom
                                      >
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-btn
                                            v-bind="attrs" v-on="on"
                          
                                            :class="[(isMobile) ? 'mt-2':'mt-0 mr-2' ]"
                                            @click="gerar_link('gerar')"
                                            color="green"
                                            dark
                                            small
                                            right
                                            fab
                                          >
                                          <!-- v-show="!hidden" -->
                                            <v-icon>
                                              mdi-account-convert
                                              <!-- mdi-home-account -->
                                              <!-- mdi-account-network -->
                                              <!-- mdi-account-star -->
                                              <!-- mdi-account-reactivate  -->
                                              <!-- mdi-account-arrow-up -->
                                            </v-icon>
                                          </v-btn>
                                        </template>
                                        <span> Link para Cadastrar Online </span>
                                      </v-tooltip>
                                  </v-fab-transition>
                              </span>


                              <span v-if="pode_alterar()">
                                <v-fab-transition>
                                    <v-tooltip 
                                      bottom
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          v-bind="attrs" v-on="on"
                        
                                          :class="[(isMobile) ? 'mt-2':'mt-0 ml-2' ]"
                                          @click="novo_cadastro"
                                          color="primary"
                                          dark
                                          small  
                                          top
                                          right
                                          fab
                                          :absolute="(isMobile)"
                                        >
                                        
                                        <!-- v-show="!hidden" -->
                                          <v-icon>mdi-account-plus</v-icon>
                                        </v-btn>
                                      </template>
                                      <span> Novo Cadastro. </span>
                                    </v-tooltip>
                                </v-fab-transition>
                              </span>
                            </v-col>

                          </v-row>
                        </v-container>
                        </v-card>
                      </template>
                  </template>
                </v-data-table> 

              </v-col>
            </v-row>
          </v-col>


          <!-- COLUNA DIREITA -->
          <v-col class="grey lighten-3 px-1 pt-2" cols="12" md="4" lg="3">

            <v-row dense>
              <v-col class="red lighten-5" cols="12">
                <template>
                  <v-card 
                    flat
                    elevation="5"
                    class="grey lighten-3 mb-4"
                    >
                      <v-expansion-panels 
                        :multiple="false"
                        :focusable='false'
                        v-model="value_expansion_2"
                        >


                        <!-- IMPRIMIR -->
                        <v-expansion-panel >
                          <v-expansion-panel-header 
                          class="pa-2 px-4"
                          style="min-height: 28px"
                          @click="expansion_selecionao='IMPRESSAO'"
                          >

                            <div>
                              <v-row>
                                <v-col cols="12" >
                                    <v-icon size="26" class="pr-1" color="primary"> 
                                      <!-- mdi-menu  -->
                                      <!-- mdi-microsoft-xbox-controller-menu  -->
                                      mdi-printer
                                    </v-icon> 
                                    
                                    <span class="fs-14">Imprimir 
                                      <span class="count_item fs-12"> {{tipos_impressao.length}} </span>
                                    </span>

                                      
                                </v-col>
                              </v-row>
                            </div>
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            <!-- class="py-0 ma-0" -->


                            <v-container>
                            <v-row
                              dense
                              class="mt-1 px-2"
                              >
                                <v-col
                                class="px-0 pb-0"
                                cols="7"
                                >
                                  <v-select
                                    dense
                                    v-model="qual_impressao"
                                    :items="tipos_impressao"
                                    label="Tipo"
                                  ></v-select>
                                </v-col>
                                
                                <v-col
                                class="px-0 pl-6 pb-0"
                                cols="5"
                                >


                                <span v-if="(items_alunos_imprimir.length>0)">
                                <v-tooltip  bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        class="mr-3"
                                        size="32"
                                        v-bind="attrs"
                                        v-on="on"
                                        color="primary"
                                        @click="impressao_acao('Imprimir')"
                                    > 
                                    mdi-printer 
                                    </v-icon>

                                    </template>
                                    <span> Imprimir</span>
                                </v-tooltip>

                                <v-tooltip  bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        class="mr-2"
                                        size="32"
                                        v-bind="attrs"
                                        v-on="on"
                                        color="error"
                                        @click="impressao_acao('Limpar Lista')"
                                    > 
                                    mdi-close 
                                    </v-icon>

                                    </template>
                                    <span> Limpar Lista</span>
                                </v-tooltip>
                              </span>

                                </v-col>
                            </v-row>
                          </v-container>


                              <v-container>
                                <v-row class="mb-1">
                                  <v-col class="px-0 py-0 ">

                                    <span v-if="(items_alunos_imprimir.length<1)">
                                      <v-icon>
                                        mdi mdi-arrow-left-bold
                                      </v-icon>
                                      Clique no nome para adicinar!
                                    </span>
                                    

                                  <v-list
                                    dense
                                    style="max-height: 422px"
                                    class="overflow-y-auto px-0 py-0"
                                    >
                                      <v-list-item-group active-class="border">

                                        <v-list-item
                                          v-for="(item, i) in items_alunos_imprimir"
                                          :key="i"
                                          class="px-0 py-0 grey lighten-5"
                                          style="min-height: 20px !important;"
                                          
                                        >

                                          <v-list-item-content
                                          class="py-0"
                                          >
                                              <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                                <v-row>

                                                  <v-col cols="10">
                                                    <span style=" text-align: right; font-size: 9px;" class="grey--text pl-1  "> {{ i + 1 }}º &nbsp </span>


                                                    <v-tooltip bottom>
                                                      <template v-slot:activator="{ on, attrs }">
                                                        <v-icon 
                                                          class="pl-2"
                                                          color="error"
                                                          size="20"
                                                          @click="remover_da_lista(item)"

                                                          v-bind="attrs"
                                                          v-on="on"
                                                        >
                                                          mdi-close
                                                        </v-icon>

                                                      </template>
                                                      <span> Remover </span>
                                                    </v-tooltip>

                                                    <v-tooltip bottom>
                                                      <template v-slot:activator="{ on, attrs }">
                                                        <v-icon 
                                                          class="pl-2"
                                                          color="primary"
                                                          size="20"
                                                          @click="abrir_auto='S',localizar_paciente(item.CODIGO_ALUNO, true)"

                                                          v-bind="attrs"
                                                          v-on="on"
                                                        >
                                                          mdi-account
                                                        </v-icon>

                                                      </template>
                                                      <span> Abrir Cadastro </span>
                                                    </v-tooltip>




                                                    <span>
                                                      <h5 style="display: inline; font-size: 12px;" class="ml-0 pl-1"> 
                                                      {{ item.NOME }}
                                                      </h5>
                                                    </span>

                                                  </v-col>
                                                </v-row>
                                              </div>

                                              <v-divider class="mx-0"/>
                                          </v-list-item-content>
                                        </v-list-item>




                                      </v-list-item-group>
                                    </v-list>

                                  </v-col>
                                </v-row>
                              </v-container>

                          </v-expansion-panel-content>
                        </v-expansion-panel>

                        <!-- ANIVERSARIO -->
                        <v-expansion-panel >
                          <v-expansion-panel-header 
                          class="pa-2 px-4"
                          style="min-height: 28px"
                          @click="abrir_niver(),expansion_selecionao='NIVER'"
                          >

                            <div>
                              <v-row>
                                <v-col cols="12">
                                    <v-icon size="26" class="pr-1" color="primary"> 
                                      mdi-cake-variant-outline 
                                    </v-icon> 
                                    
                                    <span class="fs-14">Aniversários 
                                      <span v-if="items_niver.length>0" class="count_item fs-12">{{ items_niver.length }}</span>
                                    </span>

                                      
                                </v-col>
                              </v-row>
                            </div>
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            <!-- class="py-0 ma-0" -->

                              <v-container>

                                <v-row class="mt-1 px-0">
                                  <v-col class="pa-0  mt-n4 mb-2" cols="12">
                                    <v-chip  class="ml-1 hand"
                                    :color="(zapzap_conectado) ? 'success' : 'primary'"
                                    @click="disparar_whats_niver()"
                                    >
                                      {{(zapzap_conectado)?'Enviar' : 'Conectar'}}
                                      <span class="mt-n2 ml-1"> {{ (niver_model.length) || (items_niver.length) }} </span>

                                      <v-icon right >
                                        mdi-whatsapp
                                      </v-icon>
                                    </v-chip>
                                  </v-col>
                                </v-row>

                                <v-row class="mb-1">
                                  <v-col class="px-0 py-0 red ">

                                  <v-list
                                    dense
                                    style="max-height: 422px"
                                    class="overflow-y-auto px-0 py-0"
                                    >
                                      <v-list-item-group active-class="border"
                                        v-model="niver_model" 
                                        multiple
                                      >

                                      
                                        <v-list-item
                                          v-for="(item, i) in items_niver"
                                          :key="i"
                                          :value="item"
                                          class="px-0 py-0 grey lighten-5"
                                          style="min-height: 20px !important;"
                                          
                                        >
                                       
                                        <template v-slot:default="{ active }">
                                          <v-list-item-content class="py-0">
                                              <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                                <v-row :class="[(item.WHATS_NIVER) ? 'green lighten-5' : '']">

                                                  <v-col cols="1" class="mt-n2">
                                                    <v-checkbox
                                                        :input-value="active"
                                                        color="deep-purple accent-4"
                                                        dense
                                                        hide-details
                                                      ></v-checkbox>
                                                  </v-col>

                                                  <v-col cols="10" class="">


                                                    <span style=" text-align: right; font-size: 9px;" class="grey--text pl-1  "> {{ i + 1 }}º &nbsp </span>

                                                   
                                                    <v-tooltip bottom>
                                                      <template v-slot:activator="{ on, attrs }">
                                                        <v-icon 
                                                          class="pl-2"
                                                          color="green"
                                                          size="20"
                                                          @click="before_enviar_whats(item, 'NIVER', false)"
                                                          :disabled="(!item.CELULAR)"

                                                          v-bind="attrs"
                                                          v-on="on"
                                                        >
                                                          mdi-whatsapp
                                                        </v-icon>



                                                        <span style="display: inline; font-size: 12px;" class="ml-0 pl-0"
                                                          v-if="(item.WHATS_NIVER)"
                                                          > 
                                                              <v-icon small color="green">
                                                                mdi-check-bold 
                                                              </v-icon>
                                                        </span>

                                                      </template>
                                                      <p>  Enviar Parabéns Manualmente </p>
                                                      <span>  {{ item.CELULAR }} </span>
                                                    </v-tooltip>


                                                    <v-tooltip bottom>
                                                      <template v-slot:activator="{ on, attrs }">
                                                        <v-icon 
                                                          class="pl-2"
                                                          color="primary"
                                                          size="20"
                                                          @click="abrir_auto='S',localizar_paciente(item.CODIGO_ALUNO, true)"

                                                          v-bind="attrs"
                                                          v-on="on"
                                                        >
                                                          mdi-account
                                                        </v-icon>

                                                      </template>
                                                      <span> Abrir Cadastro </span>
                                                    </v-tooltip>


                                                    <v-tooltip bottom>
                                                      <template v-slot:activator="{ on, attrs }">
                                                        <span style="font-size: 12px;" class="primary--text bold pl-1"
                                                          v-bind="attrs" v-on="on"
                                                        >
                                                          {{ item.IDADE }} anos
                                                        </span>
                                                      </template>
                                                      <span> {{ item.x_DATA_NASCIMENTO }}  </span>
                                                    </v-tooltip>


                                                    

                                                    <span>
                                                      <h5 style="display: inline; font-size: 12px;" class="ml-0 pl-1"> {{ item.NOME }}</h5>
                                                    </span>

                                                  </v-col>
                                                </v-row>
                                              </div>

                                              <v-divider class="mx-0"/>
                                          </v-list-item-content>
                                        </template>

                                        </v-list-item>

                                      




                                      </v-list-item-group>
                                    </v-list>

                                  </v-col>
                                </v-row>
                              </v-container>

                          </v-expansion-panel-content>
                        </v-expansion-panel>


                        <!-- HISTORICO LOG -->
                        <v-expansion-panel  >
                          <!-- v-if="(obj_user.SUPER == 'S')" -->
                          <v-expansion-panel-header 
                          class="pa-2 px-4"
                          style="min-height: 28px"
                          @click="abrir_logs(in_today),expansion_selecionao='HISTORICO'"
                          >

                            <div>
                              <v-row>
                                <v-col cols="12">
                                    <v-icon size="26" class="pr-1" color="primary"> 
                                      mdi-format-list-bulleted 
                                    </v-icon> 
                                    
                                    <span class="fs-14">Histórico 
                                      <span v-if="items_logs.length>0" class="count_item fs-12">{{ items_logs.length }}</span>
                                    </span>

                                      
                                </v-col>
                              </v-row>


                            </div>
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            <!-- class="py-0 ma-0" -->

                              
                            <v-card class="mx-auto mt-2 " elevation="4">
                              <!-- <v-card-title>Historico</v-card-title> -->

                              <div class="mb-2 my-1 px-0">
                                <v-row
                                  align="center"
                                  class="px-2"
                                >
                                  <v-col v-if="0>1">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">

                                          <a
                                          v-bind="attrs"
                                          v-on="on"
                                          > 
                                            <h3 @click="abrir_logs(in_today)">
                                              Atualizar
                                              <!-- <span v-if="items_logs.length>0" class="count_item fs-14 ml-n1">{{ items_logs.length }}</span> -->
                                            </h3>
                                          </a>
                                        </template>
                                        <span> Atualizar Histórico </span>
                                      </v-tooltip>
                                  </v-col>

                                  <v-col  class="white lighten-4 ma-1 v-text-field_menor"
                                  cols="6"
                                  md="5"
                                  lg="4"
                                  >
                                    <v-text-field v-model="in_today"
                                      label="Data"
                                      hide-details
                                      type="date"
                                      dense
                                      
                                    ></v-text-field>
                                  </v-col>

                                  <v-col>
                                    <v-row class="mt-4 pr-4 ml-n3">
                                    <v-col class="pa-0  mt-n6 mb-1" cols="12">
                                      <v-text-field v-model="search_logs"
                                        dense
                                        clearable
                                        append-icon="mdi-magnify"
                                        label="Procurar"
                                        single-line                
                                        hide-details
                                        @keyup.esc="search_logs='',filtrar_logs('')"
                                        @keypress.enter="filtrar_logs(search_logs)"
                                        @click:clear="filtrar_logs('')"
                                      >
                                      </v-text-field>
                                    </v-col>
                                  </v-row>
                                  </v-col>
                                </v-row>
                              </div>

                              <v-divider class="mx-4 mb-1"></v-divider>

                              <v-card-text class="pt-0 px-2">
                                <v-container>
                                  
                                  
                                  <v-row class="mt-1 px-0 ml-n3" v-if="0>1">
                                    <v-col class="pa-0  mt-n6 mb-1" cols="12">
                                      <v-text-field v-model="search_logs"
                                        dense
                                        clearable
                                        append-icon="mdi-magnify"
                                        label="Procurar"
                                        single-line                
                                        hide-details
                                        @keyup.esc="search_logs='',filtrar_logs('')"
                                        @keypress.enter="filtrar_logs(search_logs)"
                                        @click:clear="filtrar_logs('')"
                                      >
                                      </v-text-field>
                                    </v-col>
                                  </v-row>
                                  
                                  <v-row class="">
                                    <v-col class="px-0 py-0 ">
                                    <v-list
                                      dense
                                      class="overflow-y-auto px-0 py-0"
                                      :height="retorna_altura(328)"
                                      >
                                        <v-list-item-group active-class="border">

                                          <v-list-item
                                            v-for="(item, i) in items_logs"
                                            :key="i"
                                            class="px-0 py-0 grey lighten-4"
                                            style="min-height: 20px !important;"
                                            three-line
                                            
                                          >

                                            <v-list-item-content
                                            class="py-0"
                                            >

                                                <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                                  <v-row>

                                                    <v-col>
                                                      <span>
                                                        <h5 style="display: inline; font-size: 10px;" class="ml-1"> {{ item.HORAS }} </h5>  
                                                        <h5 style="display: inline; font-size: 10px;" class="ml-1"> {{ item.OPERADOR }} </h5>  
                                                      </span>
                                                      <!-- <span style="display: inline; font-size: 8px;">{{ item.QT }}</span> -->
                                                      <v-list-item-subtitle style="display: inline; font-size: 11px;" class="ml-1">
                                                          <!-- {{ item.DESCRICAO }} ;; -->
                                                          <span v-html="item.DESCRICAO" class="minha-classe"></span>
                                                          <!-- <span v-html="'Duplo clique <br/><br/>' + item.NOME"></span> -->

                                                          <span v-if="(item.CODIGO_ALUNO > 0)">
                                                            <v-tooltip bottom>
                                                              <template v-slot:activator="{ on, attrs }">
                                                                <v-icon 
                                                                  class="pl-2"
                                                                  color="primary"
                                                                  size="18"
                                                                  @click="abrir_auto='S',localizar_paciente(item.CODIGO_ALUNO, true)"

                                                                  v-bind="attrs"
                                                                  v-on="on"
                                                                >
                                                                  mdi-account
                                                                </v-icon>

                                                              </template>
                                                              <span> Abrir Cadastro </span>
                                                            </v-tooltip>

                                                          </span>
                                                          <!-- [{{ item.CODIGO_ALUNO }}] -->
                                                    </v-list-item-subtitle>

                                                    </v-col>
                                                    

                                                  </v-row>
                                                </div>

                                                <v-divider class="mx-0"/>
                                            </v-list-item-content>
                                          </v-list-item>




                                        </v-list-item-group>
                                      </v-list>

                                    </v-col>
                                  </v-row>

                                </v-container>
                              </v-card-text>

                            </v-card>
                              

                          </v-expansion-panel-content>
                        </v-expansion-panel>

                        <!-- PROPAGANDA -->
                        <v-expansion-panel >
                          <v-expansion-panel-header 
                          class="pa-2 px-4"
                          style="min-height: 28px"
                          @click="expansion_selecionao='PROPAGANDA'"
                          v-if="(value_expansion_2!=2)"
                          >

                            <div>
                              <v-row>
                                <v-col cols="12" >
                                    <!-- <v-icon size="26" class="pr-1" color="primary"> 
                                      mdi-microsoft-xbox-controller-menu 
                                    </v-icon>  -->
                                    
                                    <span class="fs-14">Módulos </span>

                                      
                                </v-col>
                              </v-row>
                            </div>
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            <!-- class="py-0 ma-0" -->


                            <v-container>
                            <v-row
                              dense
                              class=""
                              >
                                
                                <v-col
                                class="px-0 pl-0 pb-0"
                                cols="12"
                                >

                                <v-img
                                    alt="user"
                                    src="https://dsi.app.br/logos/propaganda/alfaGrafico.png"
                                    class="hand"
                                    @click="propaganda_alerta('agendamento_online')"
                                  >
                                </v-img>

                                </v-col>
                            </v-row>
                          </v-container>


                          </v-expansion-panel-content>
                        </v-expansion-panel>

                      </v-expansion-panels>
                  </v-card>
                </template>
              </v-col>
            </v-row>

          </v-col>

          </v-row>

          </v-tab-item>
 
          <v-tab-item :key="2" value="cadastro">

            <!-- CADASTRO -->
            <v-card
              flat
              class="mt-1"
              elevation="4"
              v-if="edita_cadastro.CODIGO > 0"
            >
          
            

              <v-card-title  :class="[tem_pendencias ? 'orange lighten-3' : '',  'black--text py-0']">
              <!-- <v-card-title  :class="[tem_pendencias ? 'red lighten-4' : '',  'black--text']"> -->

              <v-chip-group class="" column>
                <v-icon
                  class=""
                  color="primary"
                  @click="dialog_editar = true"
                  size="35"
                  v-if="(0>1)"
                >
                <!-- @click="adicionar_exames(items_clicou)" -->
                <!-- @click="editItem(items_clicou, false)" -->
                  mdi-dots-vertical
                  <!-- mdi-menu -->
                </v-icon>

                <v-avatar  class="pb-0 mr-2">
                  
                  <img
                    alt="user"
                    :src="edita_cadastro.FOTO_URL"
                    v-if="(edita_cadastro.FOTO_URL)"
                    @click="dialog_img=true"
                  >
                  <v-icon x-large v-else class="mt-n1">
                    mdi-account-circle
                  </v-icon>
                
                </v-avatar>
                

                <v-chip
                    color="primary lighten-1"
                    class="text-h6" 
                    @click="[(pode_alterar()) ?  editar_tabela(edita_cadastro, 'item_edita_2','dialog_cadastro') : '']"
                >
                  {{  edita_cadastro.NOME }}
                
                  &nbsp

                  <v-icon
                    class=""
                    color="primary lighten-4"
                    size="18"
                    v-if="pode_alterar()"
                  >
                    mdi-pencil
                  </v-icon>

                </v-chip>

                &nbsp
                <span class="text-subtitle-2 blue--text mt-2 mr-2"> {{ edita_cadastro.CODIGO_ALUNO}}</span>
                

                <v-chip v-if="edita_cadastro.STATUS" class=""
                :color="getColorStatus(edita_cadastro.STATUS)" dark small
                >
                  {{  edita_cadastro.STATUS }}
                </v-chip>

                <v-tooltip  bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip 
                    v-if="(edita_cadastro.PROCESSO) && (mostrar_tipo_cliente('CFC'))" 
                    class="" color="primary" small
                    :href="detran_links('processo',edita_cadastro)"
                    target="_blank"
                    v-bind="attrs" v-on="on"
                    >

                      <v-icon size= "14" class="mr-1"> mdi-magnify </v-icon>

                      {{  edita_cadastro.PROCESSO }}
                    </v-chip>
                  </template>
                  <span> Consultar Processo </span>
                </v-tooltip>

                <v-chip color="grey lighten-3" small>
                <!-- {{  items_clicou.IDADE }} -->
                {{ calcula_idade(getFormatDate(edita_cadastro.DATA_NASCIMENTO),0) }}
                </v-chip>


                <v-tooltip  bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                        color="green lighten-0" dark small
                        @click="send_whats(edita_cadastro.CELULAR, edita_cadastro.NOME, edita_cadastro.CODIGO_ALUNO)"
                        v-if="edita_cadastro.CELULAR"
                        v-bind="attrs" v-on="on"
                    >
                        <v-icon             
                            size="18"
                            class="pr-1"
                            color="white"
                        >
                            mdi-whatsapp
                        </v-icon> 
                    {{edita_cadastro.CELULAR}} 
                    </v-chip>
                  </template>
                  <span> Enviar Mensagem </span>
                </v-tooltip>

                

                <v-tooltip  bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                        color="green lighten-0" dark small
                        @click="send_whats(edita_cadastro.CELULAR_2, edita_cadastro.NOME)"
                        v-if="edita_cadastro.CELULAR_2"
                        v-bind="attrs" v-on="on"
                    >
                        <v-icon             
                            class="mr-1"
                            size="18"
                            color="white"
                            >
                        >
                            mdi-whatsapp
                        </v-icon> 
                    {{edita_cadastro.CELULAR_2}} 
                    </v-chip>
                  </template>
                  <span> Enviar Mensagem </span>
                </v-tooltip>


                <!-- <v-chip  v-if="edita_cadastro.RG" color="grey lighten-3">
                  RG: {{  edita_cadastro.RG }}
                </v-chip> -->

                <v-tooltip  bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                        color="primary" small
                        @click="get_links('cpf','', edita_cadastro)"
                        target="_blank"
                        v-if="edita_cadastro.CPF"
                        v-bind="attrs" v-on="on"
                    >
                      CPF: {{  edita_cadastro.CPF }}
                    </v-chip>
                  </template>
                  <span> Consultar CPF </span>
                </v-tooltip>


                <!-- <v-chip
                  color="grey lighten-3" small
                  v-if="edita_cadastro.DATA_NASCIMENTO"
                >
                Nasc.: {{  getFormatDate(edita_cadastro.DATA_NASCIMENTO) }}
                </v-chip> -->


                <!-- <v-tooltip  bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                        color="green lighten-1" dark small
                        v-if="edita_cadastro.ENDERECO"
                        @click="maps(edita_cadastro.ENDERECO+' '+edita_cadastro.CIDADE+' '+edita_cadastro.UF)"
                        v-bind="attrs" v-on="on"
                    >
                        <v-icon             
                            class="mr-2"
                            size="22"
                            color="white"
                        >
                            mdi-map-marker-radius 
                        </v-icon> 
                        {{edita_cadastro.ENDERECO+', '+edita_cadastro.CIDADE}} 
                    </v-chip> 
                  </template>
                  <span> Abrir Maps </span>
                </v-tooltip> -->

                <v-tooltip  bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                        color="green lighten-1" dark small
                        v-if="edita_cadastro.CIDADE"
                        @click="maps(edita_cadastro.ENDERECO+' '+edita_cadastro.CIDADE+' '+edita_cadastro.UF)"
                        v-bind="attrs" v-on="on"
                    >
                        <v-icon             
                            class="mr-2"
                            size="22"
                            color="white"
                        >
                            <!-- mdi-google-maps  -->
                            mdi-map-marker-radius 
                        </v-icon> 
                        {{edita_cadastro.CIDADE}} 
                    </v-chip> 
                  </template>
                  <span> {{edita_cadastro.ENDERECO+', '+edita_cadastro.CIDADE}}  </span>
                </v-tooltip>

              </v-chip-group>
              </v-card-title>
            </v-card>

            <v-card
              class=" mt-2"
              @mouseover="refresh_page"
            >
              <v-tabs
                  v-model="tab_2"
                  background-color="#FAFAFA"
                  show-arrows    
                  height="33"
                  color=""
              >
                <v-tabs-slider></v-tabs-slider>

                <v-tab  href="#servicos">
                  <v-icon size="18">
                    mdi-format-list-bulleted-square 
                  </v-icon>

                  <span>
                    &nbsp Serviços
                  </span>

                  <span class="count_item ml-1">
                    {{ items_servicos_alunos.length }}
                  </span>
                  
                </v-tab>

                    
                <v-tab href="#TABtabelas" v-if="2>1">
                      <!-- v-if="getConselho=='CRO'" -->
                    
                  <v-icon size="18">
                    <!-- mdi-list-box   -->
                    <!-- mdi-list-box-outline  -->
                    mdi-text-box
                  </v-icon>

                  <div v-if="isMobile==false">
                  <span>
                    &nbsp Atendimento
                  </span>

                  <span class="count_item">
                    {{ count_Reg }}
                  </span>

                  
                  </div>
                </v-tab>
              </v-tabs>

              <v-tabs-items 
                v-model="tab_2"
                touchless
              >
                <v-tab-item :key="1" value="servicos" class="mt-1">


                  <v-card
                    class="grey lighten-5 mt-1 mb-1 pa-0"
                    :height="(items_servicos_alunos.length==0) ? '55' : '0'"
                    elevation="8"
                  >

                  <span v-if="pode_alterar()">
                      <v-fab-transition v-if="(tab!='pesquisar')">
                          <v-tooltip 
                            bottom
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
              
                                class="mt-7 ml-n2"
                                @click="novo_servico()"
                                color="primary"
                                dark
                                small
                                absolute
                                top
                                left
                                fab
                              >
                              <!-- v-show="!hidden" -->
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </template>
                            <span> Novo Serviço </span>
                          </v-tooltip>
                      </v-fab-transition>
                      </span>

      

                  
                    <v-btn
                      color="primary"
                      class="ml-2 mt-2"
                      x-small
                      @click="novo_servico()"
                      v-if="0>1"
                    >
                    <!-- v-if="pode_alterar()" -->
                        <v-icon
                          small
                          class="mr-2"
                        >
                          mdi-plus
                        </v-icon>
                        novo.
                        <!-- NOVO SERVICO -->
                    </v-btn>

                  </v-card>



                  <!-- SERVICOS -->
                  <v-card 
                  flat
                  elevation="5"
                  class="mt-1 grey lighten-3"
                  >
                    <v-expansion-panels 
                    :inset = "isMobile==false"
                    :multiple="false"
                    :focusable='false'
                    v-model="value_expansion"
                    >

                      <v-expansion-panel
                        class="pa-0"
                        v-for="(item,  i ) in items_servicos_alunos"
                        :key="i"
                      >
                      <!-- {{ filtra_sum_parcelas(item.CODIGO) }} -->

                        <!-- PAINEL DADOS SERVICO -->
                        <v-expansion-panel-header 
                        :color="getColorSituacao(item.SITUACAO)"
                        >


                        <!-- VERSAO MOBILE INICIO -->
                          <div class="" v-if="isMobile"
                          :style="{'background-color': getColorSituacao(item.SITUACAO)}"
                          >
                          <v-row dense>
                            <v-col cols="12">



                              <v-chip
                                small
                              >
                                <v-icon small>
                                mdi-calendar-month 
                                </v-icon>
                                &nbsp
                                {{ getFormatDate(item.DATA) }}
                              </v-chip>

                              <!-- &nbsp
                              {{ item.CODIGO }} -->


                              
                              &nbsp
                              <v-chip
                                small
                                :color="(item.SITUACAO=='ATIVO') ? 'primary' : ''"
                                :href="detran_links('processo',item)"
                                target="_blank"
                                

                                v-bind="attrs"
                                v-on="on"
                                @click.stop=""
                            >
                              
                                <v-icon
                                  class=""
                                  :color="(item.SITUACAO=='ATIVO') ? 'white' : ''"
                                  size="18"
                                >
                                  mdi-magnify 
                                </v-icon>
                                &nbsp
                                {{  item.PROCESSO }}

                              </v-chip>


                              &nbsp
                              &nbsp
                              <v-icon
                                @click="alterar_servico(item)"
                                @click.stop=""
                                color="primary"
                                class="mr-2"
                                v-if="pode_alterar()"
                              >mdi-pencil
                              </v-icon>


                              

                            </v-col>

                            <v-col cols="12">



                              
                              <v-chip small>
                                  {{ item.DESCRICAO }}
                                </v-chip>
                            </v-col>
                          </v-row>
                          </div>
                          <!-- VERSAO MOBILE FIM -->

                          

                          <!-- VERSAO PC -->
                          <div
                            v-else
                            :style= "{ color: getColorTextoSituacao(item.SITUACAO), fontSize: 14 + 'px', font: 'arial' }"
                            >

                            <!-- <v-divider class=""></v-divider> -->

                            <v-row class="">

                            <v-card elevation="0" class=" mt-0 "
                            :style="{'background-color': getColorSituacao(item.SITUACAO)}"
                            >
                              <v-col class="red" v-if="0>1">

                              <!-- @click.stop=""  parar o <v-expansion-panel-header -->
                                <v-card  width="" class="text-center">
                                <v-menu offset-y>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      x-small
                                      width="90"
                                      :color="getColorSituacao(item.SITUACAO)"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                    {{ item.SITUACAO }}
                                    </v-btn>
                                  

                                  </template>

                                  <v-list>
                                    <v-list-item
                                      v-for="(esse, index) in items_situacao"
                                      :key="index"
                                      link
                                      dense
                                      @click="muda_situacao(esse,item)"
                                      append-icon="mdi-magnify"
                                      v-if="pode_alterar()"
                                    >

                                    <!-- <template v-slot:append> -->
                                      <!-- <v-icon :icon="item.icon"></v-icon> -->
                                      <!-- <v-icon>mdi-account-plus</v-icon> -->
                                    <!-- </template> -->

                                    <v-list-item-title>{{ esse }}</v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </v-card>


                              </v-col>
                            </v-card> 
                            
                            
                            <!-- uso esses cols somente para dar espaço esquerdo...arrumar isso no futuro -->
                            <v-col cols="auto" class=""></v-col>
                            <v-col cols="auto" class=""></v-col>

                            <v-tooltip  bottom>
                          <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                              
                                class="pl-2 mt-1"

                              >
                              <v-icon
                                color="primary"
                                @click="alterar_servico(item)"
                                @click.stop=""
                                v-if="pode_alterar()"
                              >mdi-pencil</v-icon>
                              </v-btn>
                          </template>
                          <span> Alterar Serviço </span>
                        </v-tooltip>

                            <v-card elevation="0" class="mt-0" 
                            :style="{'background-color': getColorSituacao(item.SITUACAO)}"
                            >
                              <v-col class="mt-1 ">
                                <!-- <span class="grey--text">
                                  {{ item.CODIGO }} &nbsp 
                                </span> -->

                                <v-icon small color="primary">
                                    mdi-calendar-month  
                                </v-icon>

                                <span>
                                  {{ getFormatDate(item.DATA) }}
                                </span>
                              </v-col>
                            </v-card>  

                            <v-card elevation="0"  width="140"  class=" mt-0"
                            :style="{'background-color': getColorSituacao(item.SITUACAO)}"
                            >
                              <v-col class="">


                              <v-tooltip 
                                    bottom
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-chip
                                          small
                                          :color="(item.SITUACAO=='ATIVO') ? 'primary' : ''"
                                          :href="detran_links('processo',item)"
                                          target="_blank"
                                          v-if="item.PROCESSO"
                                          

                                          v-bind="attrs"
                                          v-on="on"
                                          @click.stop=""
                                      >
                                        
                                        <v-icon
                                          class=""
                                          :color="(item.SITUACAO=='ATIVO') ? 'white' : ''"
                                          size="18"
                                        >
                                          mdi-magnify 
                                        </v-icon>
                                        &nbsp
                                        {{  item.PROCESSO }}

                                      </v-chip>
                                    </template>
                                    <span> Consultar processo </span>
                                  </v-tooltip> 

                              </v-col>
                            </v-card>

                            <v-card elevation="0" width="120" class="mt-0"
                            :style="{'background-color': getColorSituacao(item.SITUACAO)}"
                            >
                              <v-col class="">
                                <h4
                                class="text-subtitle-2"
                                :style= "[item.SITUACAO === 'ATIVO' ? {'display': 'inline','color': 'grey'} : {'display': 'inline','color': 'grey'}]">
                                <!-- :style= "{ color: getColorTextoSituacao(item.SITUACAO), fontSize: 14 + 'px', font: serif }"> -->
                                R$ {{ item.double_TOTAL }}
                                </h4>
                              </v-col>
                            </v-card>

                            <v-card elevation="0" width="" class="mt-0"
                            :style="{'background-color': getColorSituacao(item.SITUACAO)}"
                            >
                              <v-col>
                                <v-chip small>
                                  {{ item.DESCRICAO }}
                                </v-chip>

                                &nbsp
                                <v-chip small v-if="item.CATEGORIA">
                                  {{ item.CATEGORIA }}
                                </v-chip>
                                

                                <!-- {{ item.DESCRICAO }} -->

                                <!-- <h4
                                  class="text-subtitle-2"
                                  style="display: inline"
                                  >  {{ item.DESCRICAO }}
                                </h4> -->
                              </v-col>
                            </v-card>



                            <v-spacer></v-spacer>


                            <!-- PAINEL SOMATORIAS LADO DIREITO -->
                            <!-- <v-card elevation="0" width="360" class="mt-3 red pa-5"
                              v-if="(retorna_campos(item.X_SOMAS, 'COUNT_PARCELAS',false) > 0) && (item.SITUACAO!=='AATIVO') && (!isMobile)"  
                              :style="{'background-color': getColorSituacao(item.SITUACAO)}"
                            > -->

                            <v-col class=" pa-0 mr-5" cols="auto">
                              <v-row>
                                <v-col class="pa-0 py-1">
                                  <v-chip-group 
                                    column
                                    class="pl-0"
                                   >
                                    
                                    <v-chip class="multiline-chip" dark label large  :style="{ width: '100px' }" color="blue">
                                      <div class="chip-content">
                                        <span class="fs-12"> 
                                          Total 

                                          <span class="ml-1 fs-12 higher">
                                            {{ retorna_campos(item.X_SOMAS, 'COUNT_PARCELAS',false) }}
                                          </span>
                                        </span>
                                        
                                         <h5 class="">
                                          R$ {{ retorna_campos(item.X_SOMAS, 'SUM_TOTAL',true) }}
                                         </h5>
                                      </div>
                                    </v-chip>
                                    
                                    <v-chip class="multiline-chip" dark label large  :style="{ width: '100px' }" color="success">
                                      <div class="chip-content">
                                        <span class="fs-12" v-if="retorna_campos(item.X_SOMAS, 'C_EFETUADO',false)>0"> 
                                          Efetuado 

                                          <span class="ml-1 fs-12 higher">
                                            {{ retorna_campos(item.X_SOMAS, 'C_EFETUADO',false) }}
                                          </span>
                                        </span>
                                        
                                         <h5 class="">
                                          R$ {{ retorna_campos(item.X_SOMAS, 'EFETUADO',true) }}
                                         </h5>
                                      </div>
                                    </v-chip>
                                    
                                    <v-chip class="multiline-chip" dark label large  :style="{ width: '100px' }" color="orange">
                                      <div class="chip-content">
                                        <span class="fs-12" v-if="retorna_campos(item.X_SOMAS, 'C_ATRASADO',false)>0"> 
                                          Atrasado 

                                          <span class="ml-1 fs-12 higher">
                                            {{ retorna_campos(item.X_SOMAS, 'C_ATRASADO',false) }}
                                          </span>
                                        </span>
                                        
                                         <h5 class="">
                                          R$ {{ retorna_campos(item.X_SOMAS, 'ATRASADO',true) }}
                                         </h5>
                                      </div>
                                    </v-chip>
                                    
                                    <v-chip class="multiline-chip" dark label large  :style="{ width: '100px' }" color="red" v-if="!checkbox_so_entradas">
                                      <div class="chip-content">
                                        <span class="fs-12 bold" v-if="retorna_campos(item.X_SOMAS, 'C_SAIDAS',false)>0"> 
                                          (Saidas) 

                                          <span class="ml-1 fs-12 higher">
                                            {{ retorna_campos(item.X_SOMAS, 'C_SAIDAS',false) }}
                                          </span>
                                        </span>
                                        
                                         <h5 class="">
                                          R$ {{ retorna_campos(item.X_SOMAS, 'SAIDAS',true) }}
                                         </h5>
                                      </div>
                                    </v-chip>
    

                                  </v-chip-group>
                                </v-col>
                              </v-row>
                            </v-col>
                            
                            
                            <!-- </v-card> -->
                            </v-row>
                          </div>

                        </v-expansion-panel-header>

                        <v-expansion-panel-content class="">
                          
                          <v-divider class="mx-2 pt-1"></v-divider>

                        <!-- PAINEL SERVICOS BOTOES-->
                        <v-card
                          class="grey lighten-2 mt-3 mb-4 pa-0 mx-1"
                          elevation="0"
                          
                        >
                        
                          <v-container fluid class="pt-0" >
                            <v-row class="pr-2">


                                <v-btn
                                  :color="item.JA_ASSINADO == 'S' ? '': 'warning'"
                                  class="ml-2 mt-2"
                                  x-small
                                  v-if="0>1"
                                  
                                >
                                    <v-icon
                                      small
                                      class="mr-2"
                                    >
                                      mdi-text-box 
                                    </v-icon>
                                      contrato
                                </v-btn>

                                <!-- <span>
                                ETAPA ATUAL..vai salvando aqui conforme interage com o sistema
                                </span> -->
                                
                                

                            </v-row>
                          </v-container>

                        </v-card>


                          <v-card class="mx-auto mt-2">
                            <!-- IMPORTANDO COMPONENTE PARCELAS -->
                            <parcelasVue
                            :pro_ide           = 'item.CODIGO_ALUNO'
                            :pro_cod_servico   = 'item.CODIGO'
                            :pro_dados_aluno   = 'edita_cadastro'
                            :pro_dados_servico = 'item'
                            @emit_executa="emit_executa"
                            >
                            </parcelasVue>
                            <!-- IMPORTANDO COMPONENTE PARCELAS -->

                          </v-card>

                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-card>


                </v-tab-item>

                <v-tab-item :key="2" value="TABtabelas">
                  <v-card
                      elevation="4"
                      class="mt-1 mb-1 pa-1"
                  >

                    <v-container>
                      <v-row>
                          <v-col
                            cols="12"
                            md="2"
                            class=""
                          >
                            <v-card
                                class="mx-auto"
                            >
                                <v-list         
                                  dense
                                  nav
                                >
                                    <v-list-item-group
                                    v-model="selectedItem"
                                    color="primary"
                                    active-class="blue--text"
                                    >

                                        <v-divider
                                            class="mx-0"
                                        ></v-divider>

                                        <!-- passo 2  mostro os items da lista -->
                                        <v-list-item
                                            v-for="(item, i) in lista_tabelas"
                                            :key="i"
                                            @click="card_titulo=item.text, tabela(item.click,'')"
                                            class="pa-0 mr-1 "
                                        >
                                            <v-list-item-icon class="pa-0 mr-2">
                                            <v-icon v-text="item.icon" :color="(item.color) ? item.color: ''"></v-icon>
                                            </v-list-item-icon>

                                            <v-list-item-content>
                                              <!-- <v-list-item-title v-text="item.text"> -->
                                              <v-list-item-title>
                                                {{ item.text }}

                                                <span v-if="item.click == 'PT'" class="count_item"> {{ count_PT }} </span>
                                                <span v-else-if="item.click == 'PC'" class="count_item"> {{ count_PC }} </span>
                                                <span v-else-if="item.click == 'web_registros'" class="count_item"> {{ count_Reg }} </span>
                                                <!-- <span class="count_item"> {{ item.count }} </span> -->
                                                <span v-else class="count_item"> {{ item.count }} </span>

                                              </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-divider
                                            class="mx-4"
                                        ></v-divider>

                                    </v-list-item-group>
                                </v-list>
                            </v-card>    


                          </v-col> 


                          <v-col
                            class="grey lighten-5"
                            cols="12"
                            md="10"
                          >
                            <v-card
                              class="mx-auto"
                            >
                            <!-- v-if="items_lista.length > 0" -->

                                <v-card-title
                                class="pa-1 pl-2"
                                v-if="sub_codigo>0"
                                >
                                  <!-- <h4 class="primary--text" @click="ultimo_click()" >{{ sub_descricao }} </h4> -->
                                  <v-chip
                                  color="primary"
                                  @click="ultimo_click()"
                                  >
                                    {{ sub_descricao }}
                                  </v-chip>
                                  &nbsp
                                  <h5 class="mb-2 grey--text">{{ sub_codigo }} </h5>

                                  &nbsp

                                  <v-chip
                                  color="primary"
                                  @click="ultimo_click()"
                                  >
                                    VOLTAR
                                  </v-chip>

                                </v-card-title>

                                <v-card-title
                                  v-if="card_titulo"
                                  class="py-0"
                                >
                                    <span class="text-h5"> {{ card_titulo }} </span>
                                    <span class="red--text mb-2 ml-1"> {{ items_lista.length }} </span>


                                    &nbsp 
                                    <v-col
                                    cols="5"
                                    md="2"
                                    >

                                        <v-text-field
                                        dense
                                        v-model="search"
                                        clearable
                                        append-icon="mdi-magnify"
                                        label="Procurar"
                                        single-line                
                                        hide-details
                                        @keyup.esc="keyUpp('esc')"
                                    
                                        >
                                        </v-text-field>

                                    </v-col>

                                    <v-spacer></v-spacer>

                                    <v-btn
                                        color="primary"
                                        small
                                        @click="imprimir_conteudo(items_header,items_lista, card_titulo)"
                                        class="mr-5"
                                    >
                                        Imprimir
                                    </v-btn>

                                    <v-btn
                                        color="primary"
                                        small
                                        @click="clicou_em_novo('item_edita','dialog_tabela')"
                                    >
                                        Novo
                                    </v-btn>
                                
                                </v-card-title>

                                <v-card-subtitle>

                                </v-card-subtitle>

                                <v-card-text class="pa-1"
                                    v-if="card_titulo"
                                >
                                <div>
                                    <v-row class="">
                                        <v-col :cols="col_five">
                                            <v-card>
                                            <!-- passo 5 monto a lista -->
                                                <v-data-table
                                                v-model="selected"
                                                single-select
                                                item-key="CODIGO"
                                                @click:row="seleciona_linha"

                                                :headers="items_header"
                                                :items="items_lista"
                                                class="custom-data-table"
                                                :items-per-page="500"
                                                disable-sort
                                                :mobile-breakpoint="55"
                                                hide-default-footer
                                                :search="search"
                                                dense
                                                >

                                                    <template slot="no-data">
                                                    <div></div>
                                                    </template>

                                                    <template v-slot:item.TIPO="{ item }">
                                                       <span class="bold"> {{item.TIPO}} </span>
                                                    </template>

                                                    <template v-slot:item.NOME_TEXTO="{ item }">
                                                        <!-- <v-chip
                                                        color="primary"
                                                        small
                                                        class="mt-1"
                                                        >
                                                        
                                                        {{ item.NOME_TEXTO }}
                                                        </v-chip> -->
                                                        <a class="bold"> {{ item.NOME_TEXTO }} </a>
                                                        <span class="fs-10">{{ (item.CAPTION_1) ? ' - '+item.CAPTION_1 : '' }}</span>
                                                        <span class="fs-10">{{ (item.CAPTION_2) ? ' - '+item.CAPTION_2 : '' }}</span>
                                                    </template>


                                                    <template v-slot:item.DATA="{ item }">
                                                      <span> {{ getFormatDate(item.DATA,'br') }} </span>
                                                      <!-- {{ item.DATA }} -->
                                                    </template>
                                                    


                                                    <template v-slot:item.actions="{ item }">
                                                        <v-icon
                                                            class="mr-2"
                                                            @click="editar_tabela(item, 'item_edita', 'dialog_tabela')"
                                                            color="blue"
                                                            :size = "size_mobile"
                                                        >
                                                        mdi-pencil
                                                        </v-icon>
                                                    </template>



                                                    <!-- //passo 6 personalizo as acoes dos botoes na lista  INICIO-->
                                                    <template v-slot:item.actions_clock="{ item }">
                                                      <v-tooltip 
                                                            bottom
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                            <v-icon
                                                                class="mr-2"
                                                                :size = "size_mobile"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                color="blue"
                                                                @click="tabela('ANTIGO_HORARIO','SUB',item), card_titulo='Horários'"
                                                            > 
                                                            <!-- :color="item.CONTRATO ? 'blue': 'grey'" -->
                                                            mdi-clock-time-four-outline 
                                                            </v-icon>

                                                            </template>
                                                            <span> Horários</span>
                                                        </v-tooltip>
                                                    </template>


                                                    
                                                    <template v-slot:item.actions_proced="{ item }">
                                                      <v-tooltip 
                                                            bottom
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                            <v-icon
                                                                class="mr-2"
                                                                :size = "size_mobile"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                color="blue"
                                                                @click="tabela('VALORES_AMB','SUB',item), card_titulo='Procedimentos'"
                                                            > 
                                                            mdi-format-list-checkbox 
                                                            </v-icon>

                                                            </template>
                                                            <span> Procedimentos</span>
                                                        </v-tooltip>
                                                    </template>


                                                    <!-- //passo 6 personalizo as acoes dos botoes na lista  FIM -->

                                                </v-data-table>
                                            </v-card>

                                        </v-col>

                                        <v-col v-if="(col_five<12)" :cols="(12-col_five)" class="">


                                          <v-row class="" dense>
                                            <v-col class="">

                                                <v-row justify="center">
                                                  <v-col cols="12">
                                                    <v-card class="overflow-y-auto" :height=retorna_altura(330) elevation="8">
                                                      <v-card-text>
                                                        

                                                        <v-img
                                                          alt="user"
                                                          :src="obj_clicado.PATH_PDF"
                                                          height="30%"
                                                          v-if="(obj_clicado.PATH_PDF)"
                                                        >
                                                        </v-img>

                                                        <v-divider class="mx-10 my-4"/>

                                                        <!-- CAMPO ANOTAÇÕES OU OBSERVAÇÃO -->
                                                        <v-textarea
                                                          v-model="obj_clicado.TEXTO_REGISTRO"
                                                          label="TEXTO"
                                                          v-if="obj_clicado.TIPO == 'TEXTO'"
                                                          persistent-hint="false"
                                                          dense
                                                          
                                                          readonly
                                                          auto-grow
                                                          rows="18"
                                                          class="pt-2"
                                                          solo
                                                          
                                                        >
                                                        <!-- rows="12" -->

                                                          <!-- <template v-slot:label>
                                                            <span class=""> {{(vai_imprimir) ? '' : item.text }} </span>
                                                          </template>   -->
                                                        </v-textarea>

                                                        <p v-else v-html="obj_clicado.TEXTO_REGISTRO" ></p>

                                                      </v-card-text>
                                                    </v-card>
                                                  </v-col>
                                                </v-row>

                                            </v-col>
                                          </v-row>  
                                        </v-col>
                                    </v-row>

                                </div>
                                </v-card-text>
                            </v-card>
                          </v-col>     
                      </v-row> 
                    </v-container>  

                  </v-card>
                </v-tab-item>

              </v-tabs-items>

            </v-card>

          </v-tab-item>
 
         </v-tabs-items>
     </v-card>   
     </v-col>
   </v-row>
 
 
  </div>
 </template>
 
 <script>
 
 import axios from "axios";
 
 // import resultado_exameVue from './resultado_exame.vue';
 import DSibasico from '../assets/js/DSi_basico'//chamo funcao do arquivo
 import { send_whats, primeiro_nome, copiarParaClipboard } from '../assets/js/DSi_basico'
 import {get_data_hora, removerCampos} from '../assets/js/DSi_basico'
 import DSisql from '../assets/js/DSi_sql'//chamo funcao do arquivo
 import { negritoO } from '../assets/js/DSi_sql'
 import parcelasVue from './Parcelas';
 import { logado } from '../assets/js/DSi_rotinas'
 import {faz_backup, registra_log} from '../assets/js/DSi_login'
 import { send_whats_api, status_whats } from '../assets/js/DSi_whats'
 
 
 export default {
 
     components:{
       parcelasVue
     },
 
    data: () => ({


        niver_model:[],

        espaco : `⠀`,
       
        dialog_img_mais:'',
        dialog_img_titulo:'',
        dialog_img_url:'',
        dialog_img_2: false,

        dialog_img: false,

        items_etapa: [,
          // "*** (ETAPA 1) ***",
          // "DOCUMENTAÇÃO",
          // "1ª TAXA",
          // "FOTO / BIOMETRIA",
          // "EX. VISTA",
          // "EX. PSICO",
          // "2ª TAXA",
          // "*** (ETAPA 2) ***",
          // "AULAS TEÓRICAS",
          // "EX. TEÓRICO",
          // "*** (ETAPA 3) ***",
          // "AULAS NO SIMULADOR",
          // "TAXA LADV",
          // "AULAS PRÁTICAS A",
          // "AULAS PRÁTICAS B",
          // "AULAS PRÁTICAS C",
          // "AULAS PRÁTICAS D",
          // "AULAS PRÁTICAS E",
          // "* (ETAPA 3 EXTRAS) *",
          // "AULA EXTRA A",
          // "AULA EXTRA B",
          // "AULA EXTRA C",
          // "AULA EXTRA D",
          // "AULA EXTRA E",
          // "*** (ETAPA 4) ***",
          // "EX. PRÁTICO A",
          // "EX. PRÁTICO B",
          // "EX. PRÁTICO C",
          // "EX. PRÁTICO D",
          // "EX. PRÁTICO E",
          // "*** (ETAPA 5) ***",
          "PRAT-APTO 1ª",
          "PRAT-APTO 2ª",
          "PRAT-APTO 3ª",
          "PRAT-INAPTO",
        ],


        carregou_cliente: false,

        checkbox_so_entradas: false,

        config_pix : {},
        dialog_ok: false,
        dialog_ok_tipo: '',

        zapzap_mostrar: false,
        zapzap_conectado: false,

        obj_dados_empresa: null,
        obj_dados_cliente:null,

        items_alunos_imprimir :[],
        items_niver :[],
        items_logs:[],
        items_logs_clone:[],
        search_logs :'',
        in_today: '1984-04-20',

        itemsPerPageOptions: [10, 20, 30, 50, 100, 200, -1],

        tipos_impressao: ['Processos para Apropriação','Malote'],
        qual_impressao:'',
        expansion_selecionao:'',


        // ------------ TABELAS INICIO ---------------
        obj_clicado : {},

        col_menu : 0,
        col_five : 12,
        vai_imprimir: false,

        selectedItem: -1,
        card_titulo:'',
        dialog_tabela: false,
        tabela_selecionada:'',
        search: '',

        lista_tabelas:[],
        selected: [],
        items_lista:[],
        items_header: [],
        items_editaveis: [],
        items_count:[],
        item_edita: {},

        sub_codigo:0,
        sub_descricao:'',


        count_PT:0,
        count_PC:0,
        count_Reg:0,
        count_tabelas:0,

        valido: false,        

        w_data: '99px',
        w_fone: '125px',
        w_cpf: '120px',
        w_nome: '200px',
        // ------------ TABELAS FIM ---------------

        nome_digitou:'',
        
        dialog_share: false,
        link_share:'www.google.com',

        debugar: true,

        can_show: false,

        obj_user: {},

        verificou_DSi: false,

        search_aluno: '',
        tipo_cliente:'TODOS',
        count_alunos:0,


        dialog_editar: false,


        pegou:'',

        tem_pendencias: false,
        sum_pendencias: 0,
        count_pendencias: 0,

        items_empresa:[],

        files_upload:[],
 
        texto_clipboard:'',
        items_clipboard:[],

        info_aluno:{},
 
        tab:'pesquisar', //cadastro pesquisar
        tab_2:'',
 
        items_situacao:[
          'ATIVO',
          'CONCLUIDO',
          'CANCELADO',
          'PRE-CADASTRO',
          'ONLINE',
          'TRANSFERIDO',
          'APROPRIADO',
        ],
        
        items_periodo:[
         'MANHA',
         'TARDE',
         'NOITE',
         'MANHA / TARDE',
         'MANHA / NOITE',
         'TARDE / NOITE'
        ],
 
        isMobile: false, 
        size_mobile: 22,
 
        form_servico: false,
 
        value_expansion   : null, //0 ou null ou [ 1, 0 ]
        value_expansion_2 : 2, //0 ou null ou [ 1, 0 ]
 
        nome_digitou:'',
 
        progress_user: false,
 
        snackbar: false,
        snack_text: 'My timeout is set to 2000.',
        snack_color: "success",
        snack_timeout: 2000,
 
        input_dense: true,
 
        dialog_cadastro: false,
        dialog_novo_servico: false,
        readonly: false,
 
        item_edita_2: {},
 
        cor_texto_servico:'grey',
 
        combobox_servico:{},
 
        edita_servico:{
          CODIGO:0, 
          double_TOTAL: 0,
          DATA:'',
          CODIGO_SERVICO: 0
        },
        items_tabela_servico: [],
 
 
        items_servicos_alunos:[],
        items_sum_parcelas:[],
        items_sum_parcelas_f:[],
        
        edita_cadastro:{}, 
        items_cadastro: [],
        headers_cadastro: [
         { text: 'IDE', value: 'CODIGO_ALUNO' },
         // { text: 'CODIGO', value: 'CODIGO' },
         { text: 'Nome', value: 'NOME',width:'350px'},
         { text: 'Situação', value: 'STATUS',width:'90px'},
         { text: 'Processo', value: 'PROCESSO', width:'120px' },
        //  { text: 'Idade', value: 'IDADE', width:'90px' },
         { text: 'Cadastrado', value: 'DATA_CADASTRADO',width:'70px' },
         
         { text: 'Celular', value: 'CELULAR',width:'140px'},
         { text: 'CPF', value: 'CPF',width:'130px' },
         { text: 'RG', value: 'RG' },
         { text: 'Cidade', value: 'CIDADE',width:'140px' },
       ],
 
        hoje :'',
        hoje_week:'',
        hora :'',
        data_hora :'',
        today :'',
        quem_alterou:'',
        
        noRules:[v => true],
 
        emailRules: [
         // v => !!v || 'necessário',
         v => /.+@.+\..+/.test(v) || 'E-mail inválido!',
        ],
        
        notnullRules: [
         v => !!v || 'necessário'
        ],
 
        minimoRules: [
         v => !!v || 'necessário',
         v =>(v && v.length >= 5) || 'necessário'
        ],

        acimaZero: [
        v => !!v || 'necessário',
        v =>( (parseInt(v) > 0) ) || 'necessário'
        ],
 
    }),
 
    computed: {

      footerProps() {
        return {
          'items-per-page-options': this.itemsPerPageOptions,
        };
      },

       formTitle () {
        return this.item_edita_2.CODIGO > 0 ? ('Editar '+this.item_edita_2.CODIGO_ALUNO) : ('Novo Cadastro ') //this.item_edita_2.id 
       },
    },
 
    created (){
       this.onCreated()
    },
 
    watch:{

      'item_edita.TIPO'(val){
        this.campos_adicionais(this.item_edita)
      },

      in_today(a){
        this.abrir_logs(a)
      },

      value_expansion_2(a){
        if (a == undefined){
          this.expansion_selecionao = null //limpo o campo assim ele nao fica tratando ou executando a toa
        }
      }
    },
 
    methods:{


      abre_img(titulo, url_img, mais){
        this.dialog_img_2      = true
        this.dialog_img_titulo = titulo
        this.dialog_img_url    = url_img
        this.dialog_img_mais   = mais
      },

      async botao_conectar(alertar, conectar){
            
        // verifica se conectado
        this.zapzap_conectado = await status_whats(alertar, conectar, 'connected')

        // caso nao conectado..abre dialog ok
        if (!this.zapzap_conectado){
          this.dialog_ok      = true
          this.dialog_ok_tipo = 'conectar_zap'
        }

        return this.zapzap_conectado

      },


      async dialog_ok_clicou(tipo){

        if (tipo == 'conectar_zap'){
          //  somente verifico se conectado
            this.zapzap_conectado = await status_whats(false,false, 'connected')
        }

        else if (tipo == 'get_impressao'){
            this.tabela(this.tabela_selecionada)
            this.dialog_ok = false
        }

      },

      gerar_link(tipo){

        if (!this.carregou_cliente){
          return false
        }

        let url  = sessionStorage['url']
        let ide = 0
        try {
          ide  = this.obj_dados_cliente.CODIGO * 777888
        }
        catch (error) { return false}


        let link = `https://dsi.app.br/f/c/#/12052009/${ide}`

        if (url =='/api/'){
            link = `#/12052009/${ide}` //localhost
        }

        if (tipo == 'abrir'){
            window.open(link, '_blank'); 
        }
        else if (tipo == 'copiar'){
          copiarParaClipboard(link)
          this.alerta('Link copiado para a área de transferência','black', 4000)
        }
        else if (tipo == 'whats'){
          link = `https://${(this.isMobile) ? 'api':'web'}.whatsapp.com/send/?text=${encodeURIComponent(link)}`
          console.log('link:', link);

          window.open(link, '_blank'); 
        }
        else if (tipo == 'gerar'){
           this.link_share = link
           this.dialog_share = true
        }
        
      },

      async disparar_whats_niver(){


        if (!confirm(`Enviar mensagens automaticamente para esta lista?`)){
          return false
        }


        let count_ok   = 0
        let meu_array
        if (this.niver_model.length === 0){
          // alert('vazio pega lista padao')
          meu_array = this.items_niver.map((x) => x); //clonando array copiar array
        }
        else{
          // alert('selecionou pega somente os selecionados')
          meu_array = this.niver_model.map((x) => x); //clonando array copiar array
        }

        
        
        let retorno   = ''


        this.zapzap_conectado = await status_whats(false, true, 'connected')
        // caso nao conectado..abre dialog ok
        if (!this.zapzap_conectado){

          if (this.zapzap_mostrar){
            this.dialog_ok      = true
            this.dialog_ok_tipo = 'conectar_zap'
          }
          else{
            alert('Módulo WhatsApp não Ativado!')
          }

          return false
        }


        // let conexao = await  status_whats()
        // console.log('conexao:', conexao);
        // if (!conexao.connected){
        //   alert('Módulo WhatsApp Não Conectado!')
        //   return false
        // }

        // console.log('items_niver:', meu_array);
        // return false

        // let msg 
        // let msg_original = `Olá *@nome*, gostaríamos de lhe desejar um 🎉 *Feliz Aniversário* 🎊 e que você aproveite muito bem este dia tão especial! 🥳\n`+
        //                     `São os votos do *@empresa*`

        for (let i = 0; i < meu_array?.length; i++) {

            if (meu_array[i].CELULAR){
              
              
              retorno = await this.before_enviar_whats(meu_array[i], 'NIVER', true)
              // retorno = await send_whats_api('NIVER', meu_array[i].CELULAR, msg, false);
              console.log('retorno:', retorno);

              if (retorno.id){
                count_ok++
                this.alerta(count_ok+ ` enviados` , "success");//so retorna ok ou error
              }

            }
            
        }

        await this.abrir_niver()
        await this.abrir_logs(this.in_today)//atualiza historico

        setTimeout(function(){
          if (count_ok>0){
            this.alerta(`${(count_ok)} mensagens enviadas em ${(count_ok)} segundos 😉` , "primary lighten-1", 5000);//so retorna ok ou error
          }
        }.bind(this), 999);

      },

      limpa_arrays_alunos(){
        //limpa array
        this.items_lista       = [] //recebe os dados do sql
        this.items_header      = [] //campos que tem que mostrar no header
        this.items_editaveis   = [] //campos editaveis mas nao tem que mostrar no header

        this.obj_clicado       = {}
      },


      imprimirAtestado(tipo){

        sessionStorage['printTipo']     = tipo
        sessionStorage['print:dados_cadastro']  =  JSON.stringify(this.edita_cadastro)

        sessionStorage['print:get_tipo']       = tipo

        // LIMPANDO CAMPOS
        sessionStorage['print:get_codigo']     = ''
        sessionStorage['print:get_descricao']  = ''
        sessionStorage['print:get_nome_aluno'] = ''
        

        let route = this.$router.resolve({path: '/print'});
        window.open(route.href, '_blank');
      },


      async url_download_server(url_upload, file_name){

        let url  = sessionStorage['url']
        
        let path_base  =  'cfc_'+(sessionStorage['lg:IDE']) 
        file_name = path_base +'/'+ file_name  


        const res = await axios.post(url+'url_download.php', {
            acao      :  'url_download',
            file_name :  file_name,
            url       :  url_upload
        })

        console.log('retorno:', res.data);
        if (res.data.resultado){
            this.alerta(res.data.mensagem,'green')
            let url_retorno = res.data.url_retorno
            url_retorno = url + url_retorno

            // atualizo o url no banco de dados
            this.$set(this.item_edita_2, 'FOTO_URL' ,  url_retorno)

            // alert(url_retorno);
        }
        else{
            this.alerta(res.data.mensagem,'red')
        }

      },

      async clicou_em(acao){


        if (acao == 'upload_url'){
            // console.log('clicou_em:', this.item_edita_2);
            
            let pasta = 'CADASTRO/'
            let nome  = `${this.item_edita_2.CODIGO_ALUNO}.png`
            await this.url_download_server(this.item_edita_2.FOTO_URL, pasta + nome)
        }

        else if (acao == 'get_impressao'){

          let ss = this.item_edita.TIPO

          if (ss == 'OUTROS'){ss = 'Outros'}//Atestado
          else if (ss == 'TERMOS'){ss = 'Termos'}
          
          this.imprimirAtestado(ss) 
          this.dialog_tabela = false
          
          this.dialog_ok = true //ao clicar em ok ele atualiza a tabela
          this.dialog_ok_tipo = 'get_impressao'

        }

      },

      async campos_adicionais(item){

        // console.log('campos_adicionais');
        // return 'exit'

        if (this.tabela_selecionada=='web_registros'){
            
            removerCampos(this, 'items_editaveis', ['CAPTION_1','CAPTION_2', 'PATH_PDF','TEXTO_REGISTRO','get_impressao','NOME_TEXTO','PATH_ASSINATURA','VISTO'])
            
            if (['IMAGEM','PDF'].includes(item.TIPO)){

                this.items_editaveis.push(
                  { text: 'Descrição', value: 'NOME_TEXTO', tag:'v-text', type:'text', cols:'7',rule:'S', upper:'S',class:'no-print' },
                  // { text: 'PATH_PDF', value: 'PATH_PDF', tag:'v-text', type:'text', cols:'12',rule:'N', if_campo:'ACEITOU', if_value:'S', upper:'N' },
                  { text: 'PATH_PDF', value: 'PATH_PDF', tag:'v-img', width:'70%', cols:'12',rule:'N', if_campo:'ACEITOU', if_value:'S', upper:'N' },
                )
            }
            
            else if (['REGISTRO'].includes(item.TIPO)){

                this.items_editaveis.push(

                  // { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'30px'},
                  // { text: 'DATA', value: 'DATA', tag:'v-text', type:'date', cols:'2', width: '80px',rule:'S' },
                  // { text: 'Tipo', value: 'TIPO', type:'text', tag:'v-select', tag_items:['EVENTO','IMPRESSÃO'],cols:'2',rule:'S', class : 'no-print'  },      

                  { text: 'Descrição', value: 'NOME_TEXTO',
                      type:'text',
                      tag:'v-select',
                      tag_items: this.items_etapa,
                      cols:'3',rule:'S' ,
                      click:'',
                      class:''
                  },

                  { text: 'NºAulas Extras ou Motivo', value: 'CAPTION_1', tag:'v-text', type:'text', cols:'2',rule:'N', upper:'S',class:'no-print' },
                  { text: 'Instrutor', value: 'CAPTION_2', tag:'v-text', type:'text', cols:'3',rule:'N', upper:'S',class:'no-print' },


                  // { text: 'CONCLUIDO', text_2:'PENDENTE', value: 'VISTO', tag:'v-checkbox', cols:'1',rule:'S', upper:'N', class_col:'',click:'verifica_vezes', style_tag: {'margin-left': '-4px'} },


                  // { text: 'CONTEÚDO', value: 'TEXTO_REGISTRO',tag:'v-textarea', type:'text', cols:'12', rows:'19',rule:'S' }
                )
            }
            
            else if (['EVENTO'].includes(item.TIPO)){

                this.items_editaveis.push(

                  { text: 'Descrição', value: 'NOME_TEXTO', tag:'v-text', type:'text', cols:'8',rule:'S', upper:'S',class:'no-print' },
                  // { text: 'CONCLUIDO', text_2:'PENDENTE', value: 'VISTO', tag:'v-checkbox', cols:'1',rule:'S', upper:'N', class_col:'',click:'verifica_vezes', style_tag: {'margin-left': '-4px'} },
                  // { text: 'CONTEÚDO', value: 'TEXTO_REGISTRO',tag:'v-textarea', type:'text', cols:'12', rows:'19',rule:'S' }
                )
            }
            
            else if (['TEXTO'].includes(item.TIPO)){

                this.items_editaveis.push(
                  { text: 'Descrição', value: 'NOME_TEXTO', tag:'v-text', type:'text', cols:'8',rule:'S', upper:'S',class:'no-print' },
                  { text: 'CONTEÚDO', value: 'TEXTO_REGISTRO',tag:'v-textarea', type:'text', cols:'12', rows:'19',rule:'S' }
                )
            }
            
            else if (item.TIPO){  
            // if (['TERMOS'].includes(item.TIPO)){

              if (this.item_edita.TEXTO_REGISTRO){
                this.items_editaveis.push(
                  { text: 'Descrição', value: 'NOME_TEXTO', tag:'v-text', type:'text', cols:'8',rule:'S', upper:'S',class:'no-print' },
                  { text: 'CONTEÚDO', value: 'TEXTO_REGISTRO',tag:'p', type:'text', class:'mx-15', cols:'12', rows:'19',rule:'N' },
                  // { text: 'CONTEÚDO', value: 'TEXTO_REGISTRO',tag:'v-editor', type:'text', cols:'12', rows:'19',rule:'N' },
                  // { text: 'PATH_ASSINATURA', value: 'PATH_ASSINATURA', tag:'v-text', type:'text', cols:'12',rule:'N', if_campo:'ACEITOU', if_value:'S', upper:'N' },
                  { text: 'PATH_ASSINATURA', value: 'PATH_ASSINATURA', tag:'v-img', width:'50%', cols:'12',rule:'N', if_campo:'ACEITOU', if_value:'S', upper:'N' },
                )
              }
              else{

                // this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "NOME_TEXTO") , 1);//removo a coluna senha

                this.items_editaveis.push(
                  { text: 'ABRIR IMPRESSÃO', value: 'get_impressao', tag:'v-chip', cols:'2', color:'primary', click:'get_impressao'}
                )
              }
            }

            
        }
      },

      async simula_click(){

        // quando altera um texto ou imagem.ele atualiza simulando o click no item
        let CODIGO 
        try{
          CODIGO = this.selected[0].CODIGO
        }
        catch (error) { return false}

        if (CODIGO){       
          let achar = this.items_lista.filter(x => (x.CODIGO === CODIGO));//clono somente os codigos da lista atualizada
          achar = achar[0]
          if (achar){
            this.obj_clicado = Object.assign({}, achar)
          }
          //  console.log('achar:', achar);
        }
      },

      remover_da_lista(item){
        // console.log('remover_da_lista:', item);

        if (confirm(`Remover (${item.NOME}) desta lista?`)){
          this.items_alunos_imprimir.splice(this.items_alunos_imprimir.indexOf(item), 1)
          sessionStorage['items_alunos_imprimir']  =  JSON.stringify(this.items_alunos_imprimir)
        }

      },

      async clicou_no_nome(item){
        
        // aqui adiciona o nome na lista de imrpessao
        // if ((this.expansion_selecionao == 'IMPRESSAO') && (this.qual_impressao)) {
        if (this.expansion_selecionao == 'IMPRESSAO') {

          if (confirm(`Adicionar para a Lista de (${this.qual_impressao})?`)){

            let ss = {}
            this.$set(ss, 'CODIGO',       this.items_alunos_imprimir.length+1)
            this.$set(ss, 'CODIGO_ALUNO', item.CODIGO_ALUNO)
            this.$set(ss, 'NOME',         item.NOME)
            this.$set(ss, 'PROCESSO',     item.PROCESSO)

            this.items_alunos_imprimir.push(ss) //preenchendo array com o objeto clonado
            // this.items_alunos_imprimir.push(item) //preenchendo array com o objeto clonado

            sessionStorage['items_alunos_imprimir']  =  JSON.stringify(this.items_alunos_imprimir)
          }
        }
        // caso contrario somente entra no cadastro
        else{
          this.abrir_cadastro_item(item)
        }


      },

      impressao_acao(acao){


        if (acao == 'Imprimir'){

            if (!this.qual_impressao){
              alert('Selecione o Tipo')
              return false
            }

            sessionStorage['recibo_tipo'] = this.qual_impressao

            let route = this.$router.resolve({path: '/print_c'});
            window.open(route.href, '_blank');
        }

        else if (acao == 'Limpar Lista'){
          if (confirm(`Limpar lista de nomes?`)){
             this.items_alunos_imprimir = []
             sessionStorage['items_alunos_imprimir']  =  JSON.stringify(this.items_alunos_imprimir)
          }
        }
      },

      async data_hora_agora(){
        // define_data_hora(){
         DSibasico.define_data_hora(this)//passo o this para que la ele defina as variaveis daqui
      },

      dicas(acao){

        let link
        if (acao == 'copiar_mozilla'){
          alert('No Firefox é necessário habilitar o Clipboard para copiar!\nSiga os passos:\n\n1º- Abra as Configurações do Firefox\n2º- Na barra de navegação digite: about:config\n3º- Pesquise por:  Clipboard.readText  e defina como true\n4º- Aperte F5 na Página do App para Atualizar')

          // link = `about:preferences`
          // window.open(link,'_blank'); //windows.open deu erro..usei href mesmo
          
        }
      },

      clicou_em_novo(a,b){
        //  nova_tabela('item_edita','dialog_tabela')
        let tabela = this.tabela_selecionada

        if (tabela == 'WEB_REGISTROS!'){
          
        }
        else{//padrao
          this.nova_tabela(a,b)
        }
      },

      seleciona_linha(row) {
        this.selected= [{"CODIGO" : row.CODIGO}] //via v-modal no data-table

        //clonando objeto
        this.obj_clicado = Object.assign({}, row)
     },


      permite_items(){
        //passo 1 monto os items e suas tabelas e permissoes

        let s = []


        // s.push( { text: 'Convênios', icon: 'mdi-home-city ', click:'CONVENIO' } )

        this.lista_tabelas = [
          // { text: 'Plano Tratam.', count: 0, icon: 'mdi-clipboard-list-outline ', click:'whats_respostas' },
          { text: 'Registros', count: 0 , icon: 'mdi-text-box ', click:'web_registros', color:'success' },
        ]

        // this.return_count('web_registros', 'count_Reg')
        

        // this.lista_tabelas = s
      },

      async return_count(tabela, onde){

        let sql = ''
        if (tabela == 'web_registros'){
          sql = `select  '${tabela}' as tipo, count(codigo) as count_codigo from ${tabela} where codigo_cliente = ${this.edita_cadastro.CODIGO_ALUNO}`
        }

        // console.log( tabela+' : '+sql);
        await this.crud_abrir_tabela(sql,'items_count')

        await definir(this, onde)

        async function definir(that,onde){
          that[onde] = await that.items_count[0].count_codigo

          that.count_tabelas += parseInt(that[onde])
        }
      },


      getVmask(qual){
            
        if (qual == 'cel'){
            return "(##) #####-####"
        }
        else if (qual == 'fone'){
            return "(##) ####-####"
        }
        else if (qual == 'cnpj'){
            return "##.###.###/####-##"
        }
        else if (qual == 'cpf'){
            return "###.###.###-##"
        }
        else if (qual == 'cep'){
            return "#####-###"
        }
        else{
            return ""
        }
      },


      atualiza_expira(value){

        // console.log('value:', value);
        let r = DSibasico.incDay(this.today, 90,'us')

        if (value){
          this.$set(this.edita_servico, 'DATA_EXPIRA', r)
        }
        else{
          this.$set(this.edita_servico, 'DATA_EXPIRA', '')
        }

      },

      mostrar_tipo_cliente(tipo){
        let tipo_cliente 
        try{ tipo_cliente = this.obj_dados_cliente.TIPO_CLIENTE }
        catch (error) { return false }

        if (!tipo_cliente){
          return false
        }


        if (tipo_cliente.includes(tipo)){
          // console.log('achou = ', tipo);
          return true
        }
        else{
          return false
        }

      },

      filtrar_logs(nome){

        // const resultado = this.items_niver.filter(item => {
        // return item.toLowerCase().includes(nome.toLowerCase());
        // })

        this.items_logs = this.items_logs_clone.map((x) => x); //clonando array copiar array          

        if (nome){
          const resultado = this.items_logs.filter(elemento => elemento.DESCRICAO.toLowerCase().includes(nome.toLowerCase()));
          this.items_logs = resultado.map((x) => x); //clonando array copiar array          
        }
        else{
          // this.abrir_logs(this.in_today)
        }
      },


      async abrir_logs(data){

        let todos = ``
        if (this.obj_user.SUPER!='S'){
            todos = ` and CODIGO_OPERADOR = ${this.obj_user.CODIGO} `
        }

        let sql = `select * from registro_log where data = '${data}' ${todos} order by codigo desc`
        // console.log('sql:', sql);
        
          // await this.crud_abrir_tabela(sql, 'items_lista')
        if (await DSisql.crud_abrir_tabela(this, sql, 'items_logs')){
            this.items_logs_clone = this.items_logs.map((x) => x); //clonando array copiar array 
            // console.log('this.items_logs_clone:', this.items_logs_clone);
        }
      },

      async before_enviar_whats(item, tipo, auto){
        

        let empresa = sessionStorage['lg:EMPRESA']
        let quebra  = (auto) ? '\n' : '%0A'
        let quebra2 = (auto) ? '\n\n' : '%0A%0A'
        let msg  
        let retorno
        let sql
 
        if (tipo == 'NIVER'){
           let nome = await primeiro_nome(item.NOME)

           nome = await DSibasico.primeira_maiuscula(nome)

           msg = `Olá *${nome}*, gostaríamos de lhe desejar um 🎉 *Feliz Aniversário* 🎊 e que você aproveite muito bem este dia tão especial! 🥳`+ 
                  `${quebra+quebra}São os votos da *${empresa}*`
 
 
          //  msg = `🎉 *Feliz Aniversário, ${nome}* 🎊`+ quebra2+
 
          //        `Gostaríamos de lhe desejar um ano repleto de saúde, alegrias e conquistas.`+quebra2+
 
          //        `*Aproveite muito bem o seu dia!*`+quebra2+
 
          //        `Grande Abraço, `+quebra+ 
          //        `*${empresa}*`
 

             //envia automatico pelo modulo whatsapp  
             if (auto){
                 retorno = await send_whats_api(tipo, item.CELULAR, msg, false);
             }
             else{    
                retorno = this.enviar_whats_manual(item, msg)              
             }
 
             await registra_log('WHATSAPP', `ENVIOU WHATS [${(auto) ? 'auto':'manual'}] ${tipo} para ${item.NOME}`, item.CODIGO_ALUNO)

             if (tipo == 'NIVER'){
               sql = `update alunos set WHATS_NIVER = '${get_data_hora('ano_atual')}' where CODIGO_ALUNO = ${item.CODIGO_ALUNO}`
               await this.crud_sql(sql)
             }
 
             if (!auto){
                await this.abrir_logs(this.in_today)//atualiza historico
             }


             return retorno
        }
      },
 
      enviar_whats_manual(item, msg){
 
        send_whats(item.CELULAR, item.NOME, item.CODIGO_ALUNO, msg)
        return true
      },

      async abrir_niver(){

        if (this.value_expansion_2==''){ 
            return false//so executa se estiver aberto
        }


        let sql = 
        `
          SELECT
              ' hoje' AS TIPO,
              EXTRACT(MONTH FROM ALUNOS.DATA_NASCIMENTO) AS MES,
              EXTRACT(DAY FROM ALUNOS.DATA_NASCIMENTO) AS DIA,
              ALUNOS.DATA_NASCIMENTO,
              ALUNOS.DATA_CADASTRADO,
              ALUNOS.CODIGO_ALUNO,
              ALUNOS.NOME, 
              ALUNOS.CELULAR,
              ALUNOS.WHATS_NIVER,
              TIMESTAMPDIFF(YEAR, ALUNOS.DATA_NASCIMENTO, CURRENT_DATE) AS IDADE
          FROM
              ALUNOS
          WHERE
              (ALUNOS.DATA_CADASTRADO >= DATE_SUB(CURRENT_DATE, INTERVAL 96 MONTH)) AND
              (EXTRACT(DAY FROM ALUNOS.DATA_NASCIMENTO) = DAY(CURRENT_DATE)) AND
              (EXTRACT(MONTH FROM ALUNOS.DATA_NASCIMENTO) = MONTH(CURRENT_DATE))
          ORDER BY
              NOME

        `
        // console.log('estatisticas_formas:', sql);
        await DSisql.crud_abrir_tabela(this, sql, 'items_niver')

      },

      async auto_abrir_cadastro(){

        let ide;

        try {
          ide = JSON.parse(sessionStorage['cadastro_abrir_ide']) || "";
        } catch (error) {
          ide = ""; // Set a default value or handle the error in a way that makes sense for your application
        }

        if (ide){
          this.abrir_auto = 'S'
          this.localizar_paciente(ide, true)
        }else{
          this.localizar_paciente('todos', false)//talvez pegar os ultimos 100
          // this.localizar_paciente('stop', false)
        }

      },

      async formata_datas(entra,tipo){
        if (tipo == 1){

          if (entra?.includes('/')){
            var partes = entra.split('/');

            // Reorganizar as partes para o formato yyyy-mm-dd
            var fechaTransformada = partes[2] + '-' + partes[1] + '-' + partes[0];

            return fechaTransformada;
          }
          else{
            return entra
          }
        }
        else if (tipo == 2){
          //celular
          // alert(entra?.length)
          if (entra?.length == 9){
              // 999685172
              entra = entra.substring(0,5) +'-'+entra.substring(5,10)
              return entra
          }
          else if (entra?.length == 11){//com ddd
              // 44999685172
              entra = entra.substring(2,7) +'-'+entra.substring(7,11)//pego sem o ddd
              return entra
          }
          else if (entra?.length == 12){//sem digito 9
              // 554499685172
              entra =  '9'+entra.substring(4,8) +'-'+entra.substring(8,13)//pego sem o ddd
              return entra
          }
          else if (entra?.length == 13){//com ddi
              // 5544999685172
              entra = entra.substring(4,9) +'-'+entra.substring(9,13)//pego sem o ddd
              return entra
          }
          else{
            return entra
          }
        }

      },


      pode_alterar(){
            
        if ( (this.obj_user.SUPER=='S') || (this.obj_user.CADASTRO_U=='S')){
            return true
        }
        else{
            return false
        }

        return 'exit'
            
      },

      retorna_altura(menos){
          // let r = '100vh'  
          // let r = '720'
          // let r = (window.innerHeight - 80 - 140)
          let r = (window.innerHeight - menos)
          let height = window.innerHeight;

          return r
      },


      atu_page_F5(){
        console.log('F5');
        location.reload();//F5 na pagina
        console.log('F55');
      },


      async onCreated(){

        if (await logado(this)){
          
          await this.showDrawer(true)

          // OUT
          await DSisql.crud_empresa()
          await faz_backup()
          //OUT

          await this.ler_sessions()
          this.ler_sessions_delay()

          await this.auto_abrir_cadastro()

          this.define_data_hora();

          this.monta()



          this.in_today = get_data_hora('today')

          await this.abrir_logs(this.in_today)

          await this.abrir_niver()

          this.permite_items()

        }

      },

      async ler_sessions(){
          //busco objeto no sessionStorage

          try { this.obj_dados_empresa = JSON.parse(sessionStorage['obj_dados_empresa']) }
          catch (error) { console.log('erro:', error); }

          this.obj_user = JSON.parse(sessionStorage['obj_user'])
          try {this.obj_dados_cliente = JSON.parse(sessionStorage['obj_dados_cliente'])}
          catch (error) { }


          try { this.items_alunos_imprimir = JSON.parse(sessionStorage['items_alunos_imprimir']) }
          catch (error) { }

          try { this.config_pix = JSON.parse(sessionStorage['config_pix']) }
          catch (e) {}

          try{
            this.zapzap_mostrar   = JSON.parse(sessionStorage['zapzap_mostrar']);
            this.zapzap_conectado = JSON.parse(sessionStorage['zapzap_conectado']);
          }
          catch (e) {
            console.log('NAO TEM ZAPZAP:', e);
          }
      },
      
      ler_sessions_delay(){

        //esse timer pq no app.vue ele demora um pouco para pegar os dados do cliente vindas do dsi_45 
        setTimeout(function(){
          try { 
            this.obj_dados_cliente = JSON.parse(sessionStorage['obj_dados_cliente']) 
            this.carregou_cliente  = true
          }
          catch (error) { return false }


          status_whats(false, false, false)//somente verifica se tem modulo whatsapp ou nao
          try{
            this.zapzap_mostrar   = JSON.parse(sessionStorage['zapzap_mostrar']);
            this.zapzap_conectado = JSON.parse(sessionStorage['zapzap_conectado']);
          }
          catch (e) {
            console.log('NAO TEM ZAPZAP:', e);
          }

        }.bind(this), 7000);
      },

      async showDrawer(value){
        this.$emit('executar', value)//mostrar ou nao paineis
        this.can_show = true
        this.isMobile = window.innerWidth < 600
      },


      SearchNoVisibles (value, search, item) {
         return Object.values(item).some(v=>v&&v.toString().toLowerCase().includes(search))
      },


      atualizar_contrato(item){

        // console.log(item);
        // console.log(this.items_tabela_servico);

        let codigo_servico = item.CODIGO

        // filtro pelo codigo
        let h = this.items_tabela_servico.filter( x => x['CODIGO'] ==  codigo_servico);

        // associo o contrato
        this.$set(this.edita_servico, 'CONTRATO',  h[0].CONTRATO)

        this.alerta('Contrato Atualizado, clique em Salvar','blue')

      },


      calcula_idade(data,campos){
        let s = DSibasico.calcula_idade(data, campos)

        if (s.years <18){
          this.is_menor = true
        }else{
          this.is_menor = false
        }

        return s.texto
      },



      mudou_situacao(situacao){
        
        if (situacao !='ATIVO'){
          let d = this.edita_servico.DATA_CONCLUIDO
          if ((d == '0000-00-00') || (d == undefined)){
            this.$set(this.edita_servico, 'DATA_CONCLUIDO', this.today)
          }
        }
        
      },


      aviso(texto){
         setTimeout(function(){
            alert(texto)
         }.bind(this), 1000);
      },

      async get_site(){
         
        // testar mais pra frente isso.. talvez enivar os dados do forma via post php..capturar o retorno..
          let link = ''
          let PROCESSO = "922557592"
            //  link = 'https://www.habilitacao.detran.pr.gov.br/detran-habilitacao/consultaProcesso.do?action=carregarPesquisaProcessoPorNumProcesso&numProcesso='+PROCESSO
             link = "consulta.html"
            
      },

      async consulta_cep(cep, tipo){

        if (cep?.length>=9){

          let link = "https://viacep.com.br/ws/"+cep+"/json/"
          const res = await axios.get(link);
          // console.log(res.data);


          if (tipo == 'NOT_ENDERECO'){
            this.$set(this.item_edita_2, 'BAIRRO', res.data.bairro.toUpperCase())
            this.$set(this.item_edita_2, 'CIDADE', res.data.localidade.toUpperCase())
            this.$set(this.item_edita_2, 'UF', res.data.uf.toUpperCase())
          }
          else{
            this.$set(this.item_edita_2, 'ENDERECO', res.data.logradouro.toUpperCase())
            this.$set(this.item_edita_2, 'BAIRRO', res.data.bairro.toUpperCase())
            this.$set(this.item_edita_2, 'CIDADE', res.data.localidade.toUpperCase())
            this.$set(this.item_edita_2, 'UF', res.data.uf.toUpperCase())
          }
        }
      } , 
 
     async copiar_dados_aluno(tipo){

       try {
          await this.colar_clipboard('texto_clipboard')    
          console.log('OK');
       } 
       catch (e) {
          console.log('ERRO:'+ e);
          this.aviso('No Firefox é necessário habilitar o Clipboard para copiar!\nSiga os passos:\n\n1º- Abra as Configurações do Firefox\n2º- Na barra de navegação digite: about:config\n3º- Pesquise por:  Clipboard.readText  e defina como true\n4º- Reinicie o App')
          //ONDE ACHEI SOBRE SOLUCAO DESTE ERRO:  https://stackoverflow.com/questions/71710391/firefox-paste-text-from-clipboard-into-input
       }

       if (tipo == 'WEB_PR'){
          await this.tratar_clipboard()
       }
       else if (tipo == 'PDF_PR'){
          await this.tratar_clipboard_pdf()
       }
       
     },
 
     async colar_clipboard(para){
 
       await navigator.clipboard.readText().
          then(
           (clipText) => this[para] = clipText
           // (clipText) => this.texto_clipboard = clipText
           // (clipText) => document.getElementById("pastle_clipboard").value = clipText //tb funciona mas tem que definir o id
           // (clipText) => document.getElementById("pastle_clipboard").innerText = clipText
          )
     },
 
     async tratar_clipboard(){
       
       let gettexto = this.texto_clipboard
 
       let texto = gettexto
       texto = texto.replaceAll(/\t/g,       '#') //removo tabulacao
       texto = texto.replaceAll(' #',       '#')  //removo espaco com tabulacao
       // texto = texto.replaceAll(/( )+/g, '#')
 
 
       this.texto_clipboard = texto
 
     
       // Create one dimensional array
       var CAMPOS = new Array(30);
 
         
       // Loop to create 2D array using 1D array 
       for (var i = 0; i < CAMPOS.length; i++) {
         CAMPOS[i] = [];
       }
 
       CAMPOS[0][0] = '|'; //coringa no texto a copiar
       CAMPOS[0][1] = '|'; //coringa no texto a copiar
         
       
       CAMPOS[1][0] = 'Num Processo #'; //coringa no texto a copiar
       CAMPOS[1][1] = '|';//deixar o ,2 vazio quando nao quiser salvar //FIELDNAME da Tabela
 
       CAMPOS[2][0] = 'Nome#';
       CAMPOS[2][1] = 'NOME';
       
       CAMPOS[3][0] = 'Data Abertura#';
       CAMPOS[3][1] = 'DATA_PROCESSO';
       
       CAMPOS[4][0] = 'Motivo#';
       CAMPOS[4][1] = '|';
       
       CAMPOS[5][0] = 'Nome Mãe#';
       CAMPOS[5][1] = 'NOME_MAE';
 
       CAMPOS[6][0]= '|';
       CAMPOS[6][1]= '|';
 
       CAMPOS[7][0]= 'Situação#';
       CAMPOS[7][1]= '|';
 
       CAMPOS[8][0]= 'Data Nascimento#';
       CAMPOS[8][1]= 'DATA_NASCIMENTO';
 
       CAMPOS[9][0]= 'Sexo#';
       CAMPOS[9][1]= 'SEXO';
 
       CAMPOS[10][0]= 'CPF#';
       CAMPOS[10][1]= 'CPF';
  
       CAMPOS[11][0]= 'Num. Reg. CNH ';
       CAMPOS[11][1]= 'CNHALUNO';
  
       CAMPOS[12][0]= 'Nacionalidade#';
       CAMPOS[12][1]= 'NACIONALIDADE';
  
       CAMPOS[13][0]= 'Naturalidade#';
       CAMPOS[13][1]= 'NATURALIDADE';
 
       CAMPOS[14][0]= 'Tipo Docto#';
       CAMPOS[14][1]= '|';
 
       CAMPOS[15][0]= 'Num. Docto#';
       CAMPOS[15][1]= 'RG';
  
       CAMPOS[16][0]= 'Órgão / UF#';
       CAMPOS[16][1]= 'RG_ORGAO';
  
       CAMPOS[17][0]= 'Nome Pai#';
       CAMPOS[17][1]= 'NOME_PAI';
 
       // console.log(CAMPOS);
       // alert(CAMPOS[1][1])
 
 
       let indice = 0
 
       let h =  texto.split('\n'); //transformo o texto em linhas
       // console.log('h:');
       // console.log(h);
       // console.log(h.length);
 
       //percorre as linhas
       for (let i = 0; i < h.length; i++) {
 
         // console.log('linha: '+ i);
         // console.log(h[i]);
 
         //percorro o array dos campos
         for (let c = 0; c <= 20 ; c++) {
           
           //indice define a posicao do caracter coringa +1 . EX: 1#2#3 O 3 ESTARA NO INDICE (2+1)=3
           if ([7,9,11,13,15,17].includes(c)){ indice = 4 }          
           else if ([16].includes(c)){ indice = 7 }
           else {indice = 1}
 
 
           // if ('####Nome####'.includes('Nome#')){
           if (h[i].includes(CAMPOS[c][0])){
 
             let quebro =  h[i].split('#'); //transformo o texto em linhas
             // console.log(quebro);
 
             let PEGOU = quebro[indice].toUpperCase()
 
             // console.log('C:'+c + ' INDICE:'+indice+ ' - CAMPOS:' + CAMPOS[c][0]+ ' = '+CAMPOS[c][1] +' = '+PEGOU);
 
 
             // tratando alguns campos
             if ( ( CAMPOS[c][1] == 'DATA_PROCESSO') || ( CAMPOS[c][1] == 'DATA_NASCIMENTO')){
                 PEGOU = PEGOU.slice(6, 10) +'-'+ PEGOU.slice(3, 5) +'-'+ PEGOU.slice(0, 2) ; //formato us yyyy-mm-dd
             }
 
             if ((CAMPOS[c][1] !=='|') && (CAMPOS[c][1] !=='')){
                 this.item_edita_2[CAMPOS[c][1]] = PEGOU
                 // this.edita_cadastro[CAMPOS[c][1]] = PEGOU
             }
 
           }
         }
       }
 
       console.log(this.edita_cadastro);
 
     },


     async tratar_clipboard_pdf(){

       let CATEGORIA =''
       let PODE = false
       let linha = 0 
       let aux = ''


       let pega = this.texto_clipboard
       let h =  pega.split('\n'); //transformo o texto em linhas

       let texto = ''

       for (let i = 0; i < h.length; i++) {
        
          texto = h[i];


          if (PODE) {

              linha = linha + 1

              // console.log('linha: '+ linha + ' = '+texto);

              if (linha == 1){
                this.$set(this.item_edita_2, 'NOME', texto.trim())
              }
              else if (linha == 2){

                aux = texto.substr(0,texto.indexOf('e')) //copiar ate
                aux = aux.trim()
                this.$set(this.item_edita_2, 'NOME_PAI', aux )
                
                aux = texto.substr(texto.indexOf('e')+1,80) //copiar a partir de
                aux = aux.trim()
                this.$set(this.item_edita_2, 'NOME_MAE', aux )

              }
              else if (linha == 3){
                this.$set(this.item_edita_2, 'CPF', texto.trim())
              }
              else if (linha == 4){

                if (texto.includes('CARTEIRA IDENTIDADE')){
                  
                  aux = texto.substr(20 ,texto.indexOf('-')-21) //copiar ate
                  aux = aux.trim()
                  this.$set(this.item_edita_2, 'RG', aux )


                  aux = texto.substr(texto.indexOf('-')+1, 8) //copiar a partir de
                  aux = aux.trim()
                  this.$set(this.item_edita_2, 'RG_ORGAO', aux )

                  aux = texto.substr(texto.indexOf(aux)+ aux.length, 20) //copiar a partir de
                  aux = aux.trim()
                  this.$set(this.item_edita_2, 'CNH', aux )
                }
                
              }
              else if (linha == 5){


                if (texto.includes('Mas.')){
                  this.$set(this.item_edita_2, 'SEXO', 'MASCULINO')
                }
                else if (texto.includes('Fem.')){
                  this.$set(this.item_edita_2, 'SEXO', 'FEMININO')
                }

                aux = texto.substr(texto.indexOf('.')+1, 12) //copiar a partir de
                aux = aux.trim() //28/11/2022
                let aux_nasc = aux
                aux = aux.slice(6, 10) +'-'+ aux.slice(3, 5) +'-'+ aux.slice(0, 2) ; //formato us yyyy-mm-dd
                this.$set(this.item_edita_2, 'DATA_NASCIMENTO', aux )


                aux = texto.substr(texto.lastIndexOf(' ')+1, 40) //copiar a partir da ultima posicao
                aux = aux.trim()
                this.$set(this.item_edita_2, 'NACIONALIDADE', aux )

                aux = texto.slice(texto.indexOf(aux_nasc)+aux_nasc.length, texto.lastIndexOf(' '))
                aux = aux.trim()
                this.$set(this.item_edita_2, 'NATURALIDADE', aux )

              }
              else if (linha == 6){

                aux = texto.substr(texto.lastIndexOf(' '), 20)
                aux = aux.trim()
                aux = aux.replaceAll('.', '') //removo tabulacao
                this.$set(this.item_edita_2, 'CEP', aux )

                this.consulta_cep(aux,'')

                await endereco(this, texto)

                async function endereco(that,texto){

                  // pego o endereco..pois as vezes nao pega do consulta CEP
                  setTimeout(function(){
                    aux = texto.substr(0, texto.lastIndexOf(' '))
                    aux = aux.trim()
                    that.$set(that.item_edita_2, 'ENDERECO', aux )
                  }.bind(this), 999);
                }


              }
              else if (linha == 7){

                aux = texto.substr( texto.indexOf('/')+2,2)
                aux = aux.trim()
                this.$set(this.item_edita_2, 'UF', aux )

                aux = texto.substr(0, texto.indexOf('/')-2,)
                aux = aux.trim()
                this.$set(this.item_edita_2, 'CIDADE', aux )

              }
              else if (linha == 8){

                aux = texto.substr(texto.lastIndexOf('('), 20)
                aux = aux.trim()
                aux = aux.replaceAll(' ', '') //removo tabulacao
                this.$set(this.item_edita_2, 'CELULAR', aux )

              }
              else if (linha >= 9){

                // console.log(texto);
                if (texto.includes('Email:')){
                    aux = texto.replaceAll('Email:', '') //removo tabulacao
                    aux = aux.trim()
                    this.$set(this.item_edita_2, 'EMAIL', aux )
                }

              }

            

          }


          else if (texto.includes('/')){

            aux = texto.substring(0,10) //28/11/2022

            // verifico se é uma data
            if ((aux[2] == '/') && (aux[5] == '/')){
                console.log('é uma data '+ aux);
                PODE = true
                linha = 0
            }

          }

       }

     },
 
     monta(){
       this.texto_clipboard = ''
       
     },
 
 
 
     aiai(event){
       console.log('value:');
       console.log(event);
       console.log(event.target);
 
       // $event.target.value = event.target.value.toUpperCase()
 
       // $event.target.value.toUpperCase()
     },
 
     emit_executa(acao,a,b,c){
        // alert(acao)
        if (acao == 'update_somas'){
           this.sum_parcelas(this.edita_cadastro.CODIGO_ALUNO)

           this.checkbox_so_entradas = a
        }
     },
 
     async sum_parcelas(ide,servico){
 
         // console.log('abrir_parcelas:');
         let sqlservico = ''    
         if (servico>0){
             sqlservico = ` and (CODIGO_SERVICO = ${servico})`
         }
 
         // let sql = 'select * from conta where CODIGO_ALUNO = '+ide+ sqlservico+' order by pagarem, codigo'
         let sql = `
 
          SELECT
            CONTA.CODIGO_SERVICO,
            SUM(CASE
                WHEN CONTA.PAGO = 'S' AND CONTA.TIPO <> 'S' THEN CONTA.double_TOTAL
                ELSE 0
            END) AS EFETUADO,
            COUNT(CASE
                WHEN CONTA.PAGO = 'S' AND CONTA.TIPO <> 'S' THEN CONTA.CODIGO
            END) AS C_EFETUADO,
            
            SUM(CASE
                WHEN  CONTA.TIPO = 'S' THEN CONTA.double_TOTAL
                ELSE 0
            END) AS SAIDAS,
            COUNT(CASE
                WHEN  CONTA.TIPO = 'S' THEN CONTA.CODIGO
            END) AS C_SAIDAS,

            SUM(CASE
                WHEN CONTA.PAGO = 'N' AND CONTA.TIPO <> 'S' AND CONTA.DATA_VENCIMENTO > CURDATE() THEN CONTA.double_TOTAL
                ELSE 0
            END) AS A_RECEBER,
            COUNT(CASE
                WHEN CONTA.PAGO = 'N' AND CONTA.TIPO <> 'S' AND CONTA.DATA_VENCIMENTO > CURDATE() THEN CONTA.CODIGO
            END) AS C_A_RECEBER,

            SUM(CASE
                WHEN CONTA.PAGO = 'N' AND CONTA.TIPO <> 'S' AND CONTA.DATA_VENCIMENTO < CURDATE() THEN CONTA.double_TOTAL
                ELSE 0
            END) AS ATRASADO,
            COUNT(CASE
                WHEN CONTA.PAGO = 'N' AND CONTA.TIPO <> 'S' AND CONTA.DATA_VENCIMENTO < CURDATE() THEN CONTA.CODIGO
            END) AS C_ATRASADO,

            SUM(CASE
                WHEN CONTA.TIPO <> 'S' THEN CONTA.double_TOTAL
                ELSE 0
            END) AS SUM_TOTAL,
            COUNT(CASE
                WHEN CONTA.TIPO <> 'S' THEN CONTA.CODIGO
            END) AS COUNT_PARCELAS

           FROM
             CONTA
           WHERE
             (CONTA.CODIGO_ALUNO = ${ide})
             ${sqlservico}
 
           GROUP BY
           CONTA.CODIGO_SERVICO;        
 
         ` 
         // sum_contas
        //  console.log(sql);
         await this.crud_abrir_tabela(sql, 'items_sum_parcelas' )
 
        //  console.log('items_sum_parcelas:', this.items_sum_parcelas);
        //  console.log(this.items_sum_parcelas);
 
 
         await this.filtra_sum_parcelas(ide,'154')
 
 
     },
 
 
     async filtra_sum_parcelas(){
      
       console.log('filtra_sum_parcelas:');
       let h = this.items_servicos_alunos //pega os serviços
       let r = this.items_sum_parcelas   // pega os SUM das parcelas

 
       this.count_pendencias = 0
       this.sum_pendencias   = 0
       this.tem_pendencias   =  false
       //percorro o serviços.. filtrando os SUM
       
       for (let i = 0; i < h.length; i++) {

         var r_filtrado = r.filter(function(obj) { return (obj.CODIGO_SERVICO == h[i].CODIGO) });
         
         let sfields = h[i]
         this.$set(sfields, 'X_SOMAS',r_filtrado);//insiro no objeto os valores
         this.items_servicos_alunos.splice(i,1,sfields);//substitui o objeto no indice i

        //  console.log('r:', r[i]);

         if (r[i]){
          if (r[i].C_ATRASADO > 0){

            this.count_pendencias = (parseFloat(this.count_pendencias) +  parseFloat(r[i].C_ATRASADO))//somas total valor atrasados    
            this.sum_pendencias   = (parseFloat(this.sum_pendencias)   +  parseFloat(r[i].ATRASADO))//somas total valor atrasados    
            this.sum_pendencias   = DSibasico.floatTostring2(this.sum_pendencias)
            this.tem_pendencias   =  true
          }
        }
 
       }
 

       if (this.tem_pendencias){
          this.alerta(`${this.count_pendencias} Parcelas Atrasadas! (R$ ${this.sum_pendencias})`,'orange', 4000)
       }
     },
 
     retorna_campos(entra,campo,current){
       // console.log(entra);
       let B = 0
 
       try {
         B = entra[0][campo]
         if (current){
           // B = B.toLocaleString('pt-br',{ minimumFractionDigits: 2, maximumFractionDigits: 2 });//funciona pra string
           B = Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(B); //funciona para numeros
         }
       } catch (error) {
         B = 0
       }
 
       return B
     },
 
 
     async excluir_tudo_aluno(qual, servico){
       

       console.log('excluir_tudo_aluno:', servico);

       let ide  = this.edita_cadastro.CODIGO_ALUNO 
       let nome = this.edita_cadastro.NOME 

       let sql_cadastro = ''
       let sql_servico  = ''
       let sql_conta    = ''
       let sql_geral    = ''
       let msg_exclusao = ''
       let texto_log    = ''


       if (qual == 'CADASTRO'){
           sql_cadastro = `delete from alunos    where CODIGO_ALUNO   = ${ide}; \n`
           sql_servico  = `delete from servicos  where CODIGO_ALUNO   = ${ide}; \n`
           sql_conta    = `delete from conta     where CODIGO_ALUNO   = ${ide}; \n`

           sql_geral    = sql_cadastro + sql_servico + sql_conta

           msg_exclusao = `Deseja realmente Excluir o Cadastro de ${nome} ?`

           texto_log    = negritoO('EXCLUIU CADASTRO', 'red') + ` de ${nome}: ${ide}`
       }
       else if (qual == 'SERVICO'){

          sql_servico  = `delete from servicos  where CODIGO         = ${servico.CODIGO}; \n`
          sql_conta    = `delete from conta     where CODIGO_SERVICO = ${servico.CODIGO}; \n`

          sql_geral    =  sql_servico + sql_conta

          msg_exclusao = `Deseja realmente Excluir o Serviço ${servico.CODIGO} ?`

          texto_log    = negritoO('EXCLUIU SERVICO', 'red') +` - ${servico.DESCRICAO} R$${servico.double_TOTAL} de ${nome}: ${ide}`
       }


       if (confirm(msg_exclusao)){

        // console.log(sql_geral);
        await this.crud_sql(sql_geral)
        await registra_log('CADASTRO', texto_log, ide)
 
        if (qual == 'CADASTRO'){
          location.reload();//F5 na pagina
        }
        else{

          //abre o cadastro do aluno novamente 
          this.abrir_auto = 'S'
          await this.localizar_paciente(ide, true) //refresh
          this.nome_digitou = ' '
        }

       }


     },
 
     //---------------importando funcoes basicas ----------------------- INICIO
 
     moeda(event){
         return DSibasico.moeda(event)
     },
 
     define_data_hora(){
         DSibasico.define_data_hora(this)//passo o this para que la ele defina as variaveis daqui
     },
 
     allUpper(event, obj_name, field){
         this[obj_name][field] = DSibasico.allUpper(event)
     },
 
     firstUpper(event, obj_name, field){
         this[obj_name][field] = DSibasico.firstUpper(event)
     },
 
     send_whats(cel,nome, ide){
         DSibasico.send_whats(cel, nome, ide)
     },
 
     getFormatDate(data){
         return DSibasico.getFormatDate(data)
     },
 
     alerta(text,color, time){
         DSibasico.alerta(this, text, color, time)
     },
 
     maps(item){
         DSibasico.maps(item)
     },
 
 
     //---------------importando funcoes basicas ----------------------- FIM
 
 
 
 
 
    //---------------importando funcoes sql ----------------------- INICIO
 
     //FICA NO BOTAO SALVAR.. VERIFICA SE UPDATE OU CREATE
     async salvar_tabela(tabela, obj_edita, dialog_name, obj_before){

         
         await this.before_update(tabela) 

         let before  = Object.assign({}, obj_before);//faço uma copia do original
         return await  DSisql.salvar_tabela(this,tabela, obj_edita, dialog_name, before)
     },
 
     async tabela_update(tabela, obj_edita, obj_before){
         return await DSisql.tabela_update(this, tabela, obj_edita, obj_before)
     },
 
     async tabela_create(tabela, obj_edita){
         return await DSisql.tabela_create(this, tabela, obj_edita)
     },
 
     //EXECUTAR UM SQL TIPO UPDATE
     async crud_sql (sql) {
         return await DSisql.crud_sql(this, sql)
     },
 
     //ABRE OS DADOS DA TABELA
     async crud_abrir_tabela(sql, items_name){
         return await DSisql.crud_abrir_tabela(this, sql, items_name)
     }, 
 
     async editar_tabela(item, obj_edita_name, dialog_name) {
        
        this.campos_adicionais(this.item_edita)

        await DSisql.editar_tabela(this, item, obj_edita_name, dialog_name)
     },
     
     remover_tabela(tabela, obj_edita, titulo){
         DSisql.remover_tabela(this, tabela, obj_edita, titulo)
     },

    
     nova_tabela(obj_edita_name,dialog_name){

      DSisql.nova_tabela(this, obj_edita_name,dialog_name)

      //define alguns valores default
      this.define_defaults(this.tabela_selecionada)
    },
 
    //---------------importando funcoes sql ----------------------- FIM
     
     debugando(){
        if (this.debugar){
           this.tab   = 'cadastro'
           this.tag_2 = 'agendamentos'
           this.dialog_novo_servico = false
 
           this.edita_cadastro = {
             CODIGO:1,
             CODIGO_ALUNO:1,
             NOME: 'SILVANA DE SOUZA SILVA'
           }
        }
     },
 
 
      set_sessionStorage($name, $value){
       sessionStorage[$name] = $value;
      },
 
      read_sessionStorage($name){
         if (sessionStorage[$name]) {
            return sessionStorage[$name]
         } 
      },
 
 
       detran_links(qual,item){


           // console.log('detra_links:');
           // console.log(item);
          let PROCESSO = item.PROCESSO 
          let link = ''
          if (qual == 'processo'){

            //  if ( copiarParaClipboard(PROCESSO)){
            //     this.alerta('Processo Copiado para a àrea de trabalho!','success' )
            //  } 

             link = 'https://www.habilitacao.detran.pr.gov.br/detran-habilitacao/consultaProcesso.do?action=carregarPesquisaProcessoPorNumProcesso&numProcesso='+PROCESSO
          } 
          else if (qual == 'processo_pdf'){
            link = 'https://www.habilitacao.detran.pr.gov.br/detran-habilitacao/reemissaoSSHGRD.do?action=iniciarProcesso&numProcesso='+PROCESSO
          } 

         //  window.open(link,'_blank'); //windows.open deu erro..usei href mesmo
         return link
       },
       
       async get_links(qual,key, item){
 
          let link = ''
          if (qual == 'cpf'){

             if ( copiarParaClipboard(item.CPF)){
                this.alerta('CPF Copiado para a àrea de trabalho!','success' )
             } 

             link = 'https://servicos.receita.fazenda.gov.br/Servicos/CPF/ConsultaSituacao/ConsultaPublica.asp'
          }  
          else if (qual == 'get_processo'){

            let meu_processo = await DSibasico.somenteNumeros(item.PROCESSO)
            if ( copiarParaClipboard(meu_processo)){
                this.alerta('Processo Copiado para a àrea de trabalho!','success' )
             } 
          }

          setTimeout(function(){
              window.open(link,'_blank');    
          }.bind(this), 1500);

          
         // return link
       },
 
       getColorStatus (status) {
         
           if (['ATIVO','APROPRIADO'].includes(status)){
              return 'success'
           }
           else if (['PRE-CADASTRO'].includes(status)){
              return 'orange'
           }
           else if (['ONLINE'].includes(status)){
              return 'orange'
           }
           else if (['CONCLUIDO'].includes(status)){
              return 'primary'
           }
           else if (['CANCELADO','TRANSFERIDO'].includes(status)){
              return 'error'
           }
           else{
            return 'grey'
           }
         
       },
 
       getColorSituacao (v,sub) {
         if ((sub =='')||(sub == null)){
           if (v == 'CANCELADO') return '#FFEBEE'
           if (v == 'CONCLUIDO') return '#E8F5E9'
           // if (v == 'CONCLUIDO') return '#E8F5E9'
         }
         else {
           return 'white'
         }
       },
 
      getColorTextoSituacao (v,sub) {
         if ((sub =='')||(sub == null)){
           if (v == 'ATIVO') return 'black'
           else return 'grey'
         }
         else {
           return 'black'
         }
       },
 
 
       getBooleanTexto(texto){
         if (texto=='S'){
           return true
         }
         else{
           return false
         }
       },
 
       refresh_page(){
           // @mouseover="refresh_page" uso isso para atualizar a tabela depois de abrir uma nova janela e fazer alterações la
 
           // console.log('refresh_page:');
           if (sessionStorage['refresh_cadastro'] == 'S'){

               sessionStorage['refresh_cadastro'] = 'N'
 
               this.alerta('Atualizando dados..','green')  
 
               //refresh de tabelas
               this.abrir_servicos_cadastro(this.edita_cadastro.CODIGO_ALUNO)
           }
       },
 
       pode(event){
             event.stopPropagation() //para e nao muda o status
             event.preventDefault()
             this.alerta('Contrato já foi assinado, não pode ser alterado!', 'red')
       },
 
     //BASICO-------------------
 
    
 
     //SQL-----------------
 
 
     muda_situacao(situacao, item){
       // console.log('muda_situacao:');
       // console.log(item);
       let sql_2 = ''
       if (situacao !== 'ATIVO'){
          sql_2 = `, data_concluido = '${this.today}'`
       }
       
      
       let sql = `update servicos set SITUACAO = '${situacao}' ${sql_2} where codigo = ${item.CODIGO}`
       
       this.crud_sql(sql)
       
 
       
       setTimeout(function(){
         this.abrir_servicos_cadastro(this.edita_cadastro.CODIGO_ALUNO)
       }.bind(this), 0);//usar esse .bind(this) para chamar funcoes com o setTimeout 
       
     },
 
     async abrir_servicos_cadastro(ide){
 
        let sql = 'select * from servicos where codigo_aluno = '+ide+' order by data desc , codigo desc'
       //  console.log(sql);
        
        await this.crud_abrir_tabela(sql, 'items_servicos_alunos' )
        
        //ABRE AS SOMAS DAS PARCELAS
        await this.sum_parcelas(ide)//pego tudo depois filtro
 
       //  setTimeout(function(){
       //     //ABRIR SUM VALORES 
       //     this.sum_parcelas(ide)//pego tudo depois filtro
       //  }.bind(this), 555);
 
      //  if (this.isMobile){
      //   this.value_expansion = null //abre sempre no ultimo..mais recente
      //  }else{
      //   this.value_expansion = 0 //abre o ultimo
      //  }
       
     
     },
 


    async tabela_2(tabela,acao){
        // tipo close open.. atualiza os dados
 
         this.tabela_selecionada = tabela
         // this.card_titulo = item.text
 
         if (tabela=='alunos'){
            if (acao == 'create'){
              //pesquisa com o novo cadastro
               this.abrir_auto = 'S'
               this.nome_digitou = this.item_edita_2.NOME 
               this.localizar_paciente(this.nome_digitou, false)
            }
            else if (acao == 'update'){
               let index = this.items_cadastro.indexOf(this.edita_cadastro)
               Object.assign(this.items_cadastro[index], this.item_edita_2)
            }
            else if (acao=='delete'){
               let index = this.items_cadastro.indexOf(this.edita_cadastro)
               this.items_cadastro.splice(index, 1)
 
               this.edita_cadastro = {}
            }
         }
 
 
         if (tabela=='servicos'){
            
            // this.value_expansion = null//fecha o expansion-panels
            if (acao == 'create'){
               this.abrir_servicos_cadastro(this.edita_cadastro.CODIGO_ALUNO)//atualiza servicos
            }
            else if (acao == 'update'){
               this.abrir_servicos_cadastro(this.edita_cadastro.CODIGO_ALUNO)//atualiza servicos
            }
            else if (acao=='delete'){
               this.abrir_servicos_cadastro(this.edita_cadastro.CODIGO_ALUNO)//atualiza servicos
            }
 
         }
    },

    async tabela(tabela, acao, item){
      //passo 3 executo a acao quando clicar nos items e monto os campos editaveis e os campos do data-table

        if (!item){
          sessionStorage['tabela_clicou']   = tabela
          sessionStorage['tabela_titulo']   = this.card_titulo
        }

        this.search = ''//limpa filtro

        this.tabela_selecionada = tabela

      //   console.log('item:');
      //   console.log(item);

        //limpa array
        this.items_lista       = [] //recebe os dados do sql
        this.items_header      = [] //campos que tem que mostrar no header
        this.items_editaveis   = [] //campos editaveis mas nao tem que mostrar no header


        this.col_five = 12

        if (tabela=='web_registros'){

          if (!this.isMobile){this.col_five =  5}//somente se nao for mobile

          this.items_header = [

              { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'30px'},
              // { text: 'CODIGO', value: 'CODIGO', tag:'v-text', type:'number', cols:'0' },
              { text: 'DATA', value: 'DATA', tag:'v-text', type:'date', cols:'2', width: '80px',rule:'S' },
              { text: 'Tipo', value: 'TIPO', type:'text', tag:'v-select', tag_items:['REGISTRO','EVENTO','IMPRESSÃO'],cols:'2',rule:'S', class : 'no-print'  },      
              { text: 'Descrição', value: 'NOME_TEXTO', tag:'v-text', type:'text', cols:'7',rule:'S', upper:'S' },
              
          ]

          // clono os items
          this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

          let sql = `SELECT * FROM ${tabela} where CODIGO_CLIENTE = ${this.edita_cadastro.CODIGO_ALUNO}  order by CODIGO desc`

          //  console.log('SQL:',sql);
          await this.crud_abrir_tabela(sql, 'items_lista')

          await this.simula_click()

        }

        else if (tabela == 'whats_respostas'){
          
          this.items_header = [

          
            { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width: this.w_actions},
            { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },              
            { text: 'CLIENTE', value: 'CODIGO_CLIENTE', tag:'v-text', type:'text', cols:'2', rule:'N' },      
            { text: 'TOKEN', value: 'TOKEN', tag:'v-text', type:'text', cols:'3', rule:'N',upper:'N' },      
            { text: 'COD_MENU', value: 'COD_MENU', tag:'v-text', type:'text', cols:'2', rule:'N' },
            { text: 'CODIGO_FLUXO', value: 'CODIGO_FLUXO', tag:'v-text', type:'text', cols:'2', rule:'N' },
            { text: 'Tipo', value: 'TIPO', tag:'v-text', type:'text', cols:'2', rule:'N' },             
            { text: 'Texto da Mensagem', value: 'TEXTO',tag:'v-textarea', type:'text', cols:'12',rule:'N', width:'333px' },  

            { text: 'Intervalo', value: 'INTERVALO_TIPO',type:'text',tag:'v-select',tag_items:['DIAS','HORAS','MINUTOS'],cols:'2', rule:'S', hint:'Horas: Máximo de 23 Minutos: Máximo de 59'}, 
            { text: 'De', value: 'INTERVALO', tag:'v-text', type:'number', cols:'1', rule:'S' },             

            { text: 'DAS', value: 'HORA_DAS', tag:'v-text', type:'time', cols:'2', rule:'S' },      
            { text: 'ATÉ', value: 'HORA_ATE', tag:'v-text', type:'time', cols:'2', rule:'S' },   

            { text: 'Texto de Ausência', value: 'TEXTO_AUSENTE',tag:'v-textarea', type:'text', cols:'12',rule:'N', width:'333px' },  
            
            


            { text: 'URL_FILE', value: 'URL_FILE', tag:'v-text', type:'text', cols:'12', rule:'N', upper:'N' },      
                
            ]

            // clono os items
            this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

            let sql = `SELECT *  FROM `+tabela + ` order by codigo desc` 

            console.log(tabela+': '+sql);
            await this.crud_abrir_tabela(sql, 'items_lista')

        }

    },

    define_defaults(tabela){

      // --------------------DEFINE DATA E HORA-----------------------
      let hoje = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });  
      // console.log('hoje:'+ hoje);

      let today = hoje.slice(6, 10) +'-'+ hoje.slice(3, 5) +'-'+ hoje.slice(0, 2) ; //formato us yyyy-mm-dd
      // console.log('today:'+ today);

      let hora = new Date().toLocaleTimeString('pt-Br',{ hour12: false, timeZone: 'America/Sao_Paulo' });
      let hora_curta = hora.slice(0, 5)


      // passo 7 defino valores default para alguns campos de determinada tabela
      if (tabela=='PT'){
        this.$set(this.item_edita, 'DATA', today)
        this.$set(this.item_edita, 'CODIGO_PACIENTE', this.getCodigoPaciente)
        this.$set(this.item_edita, 'CODIGO_MEDICO',   this.getCodigoDoc)
        this.$set(this.item_edita, 'CODIGO_DIAG',     0)
        this.$set(this.item_edita, 'TIPO',           '1')
      }
      else if (tabela=='web_registros'){
        this.$set(this.item_edita, 'DATA', today)
        this.$set(this.item_edita, 'CODIGO_CLIENTE', this.edita_cadastro.CODIGO_ALUNO)
        this.$set(this.item_edita, 'TEXTO_REGISTRO',  null)
        this.$set(this.item_edita, 'VISTO'         ,  '')
      }

    },

    meu_keyup(event,campo,upper){
  
      //identifica se o campo é double e muda o display
      if ((campo.includes('double_') || campo.includes('VALOR')) ){
        this.item_edita[campo] =  this.moeda(event)
        // @keyup="editedItem.double_credito = moeda($event)"
      }
      else if (campo.includes('CEP')) {

        this.consulta_cep(this.item_edita[campo])    
      }
      else{
        if ((campo.includes('EMAIL')==false) &&
          (campo.includes('SENHA')==false) &&
          (campo.includes('SENHA_WEB')==false)){

            if (upper !=='N'){
              this.allUpper2(event, campo)
            }
            
        }
      }
    },


    allUpper2(event, field){
        let a = event.target.value;//captura o valor do event
        if (a!==''){  
            a = a.toUpperCase();
            this.item_edita[field] = a;
        } 
    },


    async before_update(tabela){

      // passo 8 antes de salvar verifico alguns campos necessarios em determinada tabela


      // VERIFICA CAMPOS NUMERICOS QUE NAO PODEM IR VAZIOS

      if (this.tabela_selecionada == 'WEB_REGISTROS'){
          this.$set(this.item_edita, 'TEXTO_REGISTRO', this.editor.getHTML())
      }

      let VERIFICAR = [
         'CADASTRO_ORC|CODIGO_CLIENTE|0',
      ]
    
      let CAMPOS      = []
      let CAMPO_VER   = ''
      let CAMPO_VALOR = ''
      for (let i = 0; i < VERIFICAR.length; i++) {
          
          CAMPOS      = VERIFICAR[i].split("|");//divide
          CAMPO_VER   = CAMPOS[1]
          CAMPO_VALOR = this.item_edita[CAMPO_VER]

          if (tabela==CAMPOS[0]){//verifica se é a tabela selecionada
            if (!CAMPO_VALOR){//verifica se vazio
              this.$set(this.item_edita, CAMPOS[1], CAMPOS[2] ) 
            }
          }
      }

      // valores default before update
      if (tabela == 'alunos'){

          this.data_hora_agora()
          this.$set(this.item_edita_2, 'QUEM_ALTEROU', this.quem_alterou )

      }
    },
 
 
 
       seleciona_servico(item){
         // console.log('seleciona_servico:');
         // console.log(this.edita_servico);
         // console.log(item);
 
         if(item){
 
             // if (item.double_TOTAL !== null){
                 
             // }
            //  console.log('seleciona_servco:');

             this.$set(this.edita_servico, 'double_TOTAL',    item.double_TOTAL)
             this.$set(this.edita_servico, 'DESCRICAO',       item.DESCRICAO)
             this.$set(this.edita_servico, 'CODIGO_SERVICO',  item.CODIGO)
             this.$set(this.edita_servico, 'CATEGORIA',       item.CATEGORIA)
 
             if (this.edita_servico.JA_ASSINADO!=='S'){
                 //caso nao tenha assinado, pega o modelo de contrato
                 this.$set(this.edita_servico, 'JA_ASSINADO',  'N')
                 this.$set(this.edita_servico, 'CONTRATO',  item.CONTRATO)
             }
             
           }
       },
 
       novo_servico(){
         this.dialog_novo_servico = true
 
         // this.edita_servico = {}
         // console.log('this.edita_servico:');
         // console.log(this.edita_servico);
         //limpando campos  
         for (var member in this.edita_servico)  this.edita_servico[member]= ''; //limpando os campos do objeto 
         
         this.combobox_servico = {}//limpo objeto
 
         // let sql = 'select * from tabela_servicos order by nivel'
         let sql = "select * from tabela_servicos where nivel =  'PRIMARIO' order by nivel"
         this.crud_abrir_tabela(sql, 'items_tabela_servico' )
 
         //valores default
         this.$set(this.edita_servico, 'DATA', this.today)
         this.$set(this.edita_servico, 'SITUACAO', 'ATIVO')
         this.$set(this.edita_servico, 'PROCESSO', this.edita_cadastro.PROCESSO)
         this.$set(this.edita_servico, 'DATA_PROCESSO', this.edita_cadastro.DATA_PROCESSO)
         this.$set(this.edita_servico, 'CODIGO_ALUNO', this.edita_cadastro.CODIGO_ALUNO)

         this.$set(this.edita_servico, 'X_NOME', this.edita_cadastro.NOME)//envio o nome via campo calculado
       },
 
       alterar_servico(item){
         // console.log('alterar_servico:');
         // console.log(item);
 
         this.dialog_novo_servico = true
 
         let sql = 'select * from tabela_servicos'
         this.crud_abrir_tabela(sql, 'items_tabela_servico' )
 
         this.editar_tabela(item, 'edita_servico', 'dialog_novo_servico')
 
         // console.log('combobox_servico:');
         // console.log(this.combobox_servico);
         
         this.combobox_servico = {}//limpo objeto antes
         this.combobox_servico.CODIGO    = item.CODIGO_SERVICO
         this.combobox_servico.DESCRICAO = item.DESCRICAO
       },
 
 
        async abrir_cadastro_item(item){ //abre cadastro do aluno e seus servicos
 

         this.limpa_arrays_alunos() 

          // console.log(item);
         this.tem_pendencias = false
         this.sum_pendencias = 0

         this.edita_cadastro = item
         this.tab = 'cadastro'
         this.tab_2 = 'agendamentos'

         this.return_count('web_registros', 'count_Reg')


         if (this.edita_cadastro.DATA_NASCIMENTO){
           this.$set(this.edita_cadastro, 'x_IDADE', this.calcula_idade(this.getFormatDate(item.DATA_NASCIMENTO), 1))
         }
 
         //abre items relacionados ao cadastro
         this.abrir_servicos_cadastro(item.CODIGO_ALUNO)
         // this.lista_agendamentos(item.CODIGOCLIENTE)
         // this.crud_historico(item.CODIGOCLIENTE)
         // this.crud_receitas(item.CODIGOCLIENTE)
         // this.crud_exames(item.CODIGOCLIENTE)

         let url =  sessionStorage['url']
         if (url !='/api/'){ //so copia no cliente
            let meu_processo = await DSibasico.somenteNumeros(item.PROCESSO)
            if ( copiarParaClipboard(meu_processo)){
                // this.alerta('Processo Copiado para a àrea de trabalho!','success' )
             } 
         }
       },

 
       novo_cadastro(){


         logado(this)//ver se expirou o login

         this.data_hora_agora()

         this.tipo_cliente = 'TODOS'
         //limpa campos do array
         for (var member in this.item_edita_2)  this.item_edita_2[member]= ''; 
 
         //default  
         this.$set(this.item_edita_2, 'STATUS', 'ATIVO')
         this.$set(this.item_edita_2, 'ATIVO', 'S')//QUANDO EXCLUIR ELE FICA INATIVO
         this.$set(this.item_edita_2, 'DATA_CADASTRADO', this.today)
         this.$set(this.item_edita_2, 'DATA_PROCESSO', this.today)
         this.$set(this.item_edita_2, 'QUEM_CADASTROU', this.quem_alterou)
 
         // this.$set(this.item_edita_2, 'CODIGO_ALUNO', '(SELECT max(alunos.CODIGO_ALUNO+1)FROM alunos)');//FIREBIRD
        //  this.$set(this.item_edita_2, 'CODIGO_ALUNO', 'select (max(CODIGO_ALUNO)+1) into @var from alunos;');//PHPMYADMIN
         this.$set(this.item_edita_2, 'CODIGO_ALUNO', 'select (IFNULL(MAX(CODIGO_ALUNO), 0)+1) into @var from alunos;');//PHPMYADMIN se for nulo retorna 0
 
         // habilito isso para testar
         // this.$set(this.item_edita_2, 'NOME', 'DSI TESTE '+this.data_hora)
         // this.$set(this.item_edita_2, 'CATEGORIA', 'AB')
         // this.$set(this.item_edita_2, 'PROCESSO', '9123456789')
         // this.$set(this.item_edita_2, 'CELULAR', '44999685172')
 
         
 
         this.dialog_cadastro = true
         this.readonly        = false
       },
       
 
     async localizar_paciente(event, pelo_ide){
 

        // if (event){
        //   event = event.replaceAll("'",'');
        // }

        if (event == 'stop'){
          return false
        }

        // limpa filtros basicos
        sessionStorage['cadastro_abrir_ide'] = ''

    
         this.progress_user = true
 
         if (event == undefined){
           event = ''
         }
 
         //salvo na session
         this.set_sessionStorage('nome_digitou',this.nome_digitou)
 
       if (event == undefined){
         this.nome_digitou = ''
       }
 
       if (event == ''){
         event = '....'
       }
 

       let arrayNomes = []

      if (isNaN(event)) {
        // alert('texto')
        arrayNomes = event.trim().split(' ');//retiro espaços vazios e separo a string
        var nome = event
        var ide  = null

        nome = nome.toLocaleLowerCase()
      }else{
        // alert('eh numero')
        var nome = null
        var ide = event

        if (!pelo_ide){//se nao for pra pegar exatamento pelo ide, entao pega pelo que achar
          nome = event
        }
      }
   



      let sql_nome = ''
      for(let i = 0; i< arrayNomes.length; i++){
        if (i>0){
          sql_nome += ` AND `
        }
        sql_nome += `(ALUNOS.NOME like '%${arrayNomes[i]}%')`     
      }

      if (sql_nome){
        sql_nome = `((${sql_nome})OR`
      }
      else{
        sql_nome = `(`
      }
        
      let ccelular = await this.formata_datas(nome,2)


      let status = ``
      if (this.tipo_cliente !='TODOS'){
        status = ` (ALUNOS.STATUS = '${this.tipo_cliente}') AND `
      }

      let where = `
      WHERE
      ${status}
      ((ALUNOS.CODIGO_ALUNO = ${ide})OR
      ${sql_nome}
      (ALUNOS.CELULAR  like '%${ccelular}%')OR
      (ALUNOS.PROCESSO like '%${nome}%')OR
      (ALUNOS.ENDERECO like '%${nome}%')OR
      (ALUNOS.CIDADE   like '%${nome}%')OR
      (ALUNOS.NOME_MAE like '%${nome}%')OR
      (ALUNOS.NOME_PAI like '%${nome}%')OR
      (ALUNOS.CPF      like '%${nome}%')))
      `
 

      // alert(nome)
      if ((nome=='todos')||(nome == '....')){
          if (this.tipo_cliente !='TODOS'){
            where = ` where STATUS = '${this.tipo_cliente}' `
            // where = ` where STATUS = '${this.tipo_cliente}' and  DATA_CADASTRADO > DATE_SUB(CURDATE(), INTERVAL 2 YEAR)`
          }
          else{
            // where = ` where  DATA_CADASTRADO > DATE_SUB(CURDATE(), INTERVAL 1 YEAR)`
            where = ` WHERE (SELECT COUNT(*) FROM ALUNOS) > 3000 AND DATA_CADASTRADO > DATE_SUB(CURDATE(), INTERVAL 1 YEAR)
                      OR
                      (SELECT COUNT(*) FROM ALUNOS) <= 3000`
          }
      }

        var meuSQL = 
        `
        SELECT
          *
        FROM
          ALUNOS
        ${where}
        ORDER BY
          ALUNOS.ATIVO,
          ALUNOS.CODIGO_ALUNO DESC
        `
 
      //  console.log(meuSQL);
 
                 
      await DSisql.crud_abrir_tabela(this, meuSQL,'items_cadastro')

      this.count_alunos = this.items_cadastro?.length

      this.progress_user = false


 
                
        await executa(this, this.count_alunos)

          async function executa(that, count){


               if(count == 0){
                that.alerta('Nada encontrado!','orange')
               }
               else{
 
                 if ( (that.abrir_auto == 'S') ){ //somente ser for pesquisado pelo IDE = direto
                  that.abrir_cadastro_item(that.items_cadastro[0])
                 }
               }
 
               that.progress_user = false
 
               //voltando o comando abrir para NAO
               that.set_sessionStorage('nome_digitou_abrir', 'N')
               that.abrir_auto = 'N'
 
          }        
         
     },
 
     //SQL-----------------
 
      
 
    },
 
 }
 </script>
 
 <style scoped>
  

  .container{
     max-width: 100%;
   }
 
   .v-tab{
   font-size: 9pt; /* usando o sass-variables */
  }
 
 .count_item {
   position: relative !important;
   top: -3px;
   font-size: 80%;
   vertical-align: super;
   color: red;
 }
 
 .count_somas{
  margin-top: -33px;
  font-size:12px; 
  color: grey;
  margin-left: 55px;
 }
 
 .somas{
    margin-top: -12px;
    margin-bottom:-10px
 }
 
 .label_somas{
   margin-top: -8px;
   /* margin-left: 18px; */
 }
 
 .sem_quebra ::v-deep textarea {  
 /* tem que usar o ::v-deep quando usa scope */
 
  /* tiro a quebra de linha do textarea que recebe o clipboard */
  /* line-height: 20px;  */
   height: 400px;
   white-space: pre;
   overflow-wrap: normal;
   overflow-x: scroll;
   background-color: red;
 }
 
 ::v-deep .v-expansion-panel-content__wrap {
   padding: 0 2px 2px !important;
 }
 
 /* tamanho da fonte do data-table */
.v-data-table >>> th {
  font-size: 12px !important;
}
.v-data-table >>> td {
  font-size: 14px !important;
}
/* tamanho da fonte do data-table */

.v-list-item {
  padding: 0 9px;
}

.v-text-field_menor >>> input {
    max-width: 83% !important;
}

/* .v-data-table >>> tr>td{
  font-size:12px !important;
  height: 25px !important;
} */

.custom-data-table ::v-deep tr>td {
  font-size: 12px !important;
  height: 25px !important;
}


/* V-CHIP DAS SOMATORIAS */
.multiline-chip {
  /* Ajuste a altura conforme necessário para acomodar duas linhas */
  height: auto !important;
  /* Para garantir que o conteúdo não fique muito apertado */
  padding: 4px 10px !important;
}

.chip-content {
  /* Isso ajuda a manter o texto alinhado corretamente */
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  text-align: left !important;
}

.higher {
  position: relative !important;
  top: -3px !important; /* Ajuste este valor conforme necessário */
}
/* V-CHIP DAS SOMATORIAS */


.negrito {
    font-weight: bolder !important;
    color: red !important;
    /* Outros estilos desejados */
}





 </style>